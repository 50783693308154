import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import axios from "axios";

const api_domain = jwtServiceConfig.apiUrl;
const cdnUrl = jwtServiceConfig.cdnUrl;

export const getTournament = createAsyncThunk("sportsApp/tournament", async (data) => {
  const { type, langId } = data;
  const response = await axios.get(`${api_domain}tournaments/${langId}/${type}`);
  const res = response.data.tournaments_info;
  return res === undefined ? null : res;
});

export const getDictionaryBySport = createAsyncThunk("sportsApp/getDictionaryBySport", async (data) => {
  const { sport, langId, type } = data;
  const response = await axios.get(`${api_domain}dictionary/${type}/${langId}/${sport}`);
  const res = response.data.dictionary_list;
  return res === undefined ? null : res;
});

export const getGroupIdDictionary = createAsyncThunk("sportsApp/getGroupIdDictionary", async (data) => {
  const { groupId, langId } = data;
  const response = await axios.get(`${api_domain}dictionary/groupId/${groupId}/languageId/${langId}`);
  const res = response.data.dictionary_list;
  return res === undefined ? null : res;
});

function getSportsList(apiResponse) {
  const sportsList = [];
  const tournamentsInfo = apiResponse;

  for (const sportKey in tournamentsInfo) {
    if (tournamentsInfo.hasOwnProperty(sportKey)) {
      const sport = tournamentsInfo[sportKey];
      const { id, name, flag, order, countries } = sport;

      const events_number = countries.reduce((prev, curr) => {
        const count = curr.tournaments.reduce((prev, curr) => {
          return prev + curr.events_number;
        }, 0);
        return prev + count;
      }, 0);

      sportsList.push({
        id,
        name,
        order,
        events_number,
        icon: `${cdnUrl}/flags/${flag}`,
      });
    }
  }

  return sportsList.sort((a, b) => a.order - b.order);
}

function getLeagueList(data) {
  const leagues = [];
  for (const sportKey in data) {
    const sport = data[sportKey];
    const { countries } = sport;
    for (const country of countries) {
      const { tournaments } = country;
      for (const tournament of tournaments) {
        if (!leagues.some((lea) => lea.id === tournament.id && lea.country_id === country.id)) {
          leagues.push({
            ...tournament,
            sport_id: sport.id,
            sport_name: sport.name,
            sport_flag: sport.flag,
            country_id: country.id,
            country_name: country.name,
            country_flag: country.flag,
          });
        }
      }
    }
  }
  return leagues;
}

const tournamentAdapter = createEntityAdapter();

export const { selectAll: selectAllTournamentList } = tournamentAdapter.getSelectors(
  (state) => state.sportsApp.tournamentList
);

const tournamentSlice = createSlice({
  name: "sportsApp/tournament",
  initialState: tournamentAdapter.getInitialState({
    tournamentList: [],
    sportsList: [],
    leagueList: [],
    status: "idle",
    error: null,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTournament.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTournament.fulfilled, (state, action) => {
        state.status = "fulfilled";
        if (!action.payload) {
          tournamentAdapter.setAll(state.tournamentList, []);
          state.tournamentList = [];
          state.sportsList = [];
        } else {
          state.tournamentList = action.payload;
          state.sportsList = getSportsList(action.payload);
          state.leagueList = getLeagueList(action.payload);
        }
      })
      .addCase(getTournament.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectTournamentList = ({ sportsApp }) => sportsApp.tournament.tournamentList;
export const selectSportsList = ({ sportsApp }) => sportsApp.tournament.sportsList;
export const selectLeagueList = ({ sportsApp }) => sportsApp.tournament.leagueList;
export const selectSport = ({ sportsApp }, sport_name) => sportsApp.tournament.tournamentList[sport_name];
export const selectTournamentStatus = ({ sportsApp }) => sportsApp.tournament.status;
export default tournamentSlice.reducer;
