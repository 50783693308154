import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography, styled, useTheme } from "@mui/material";
import { SearchBox } from "app/shared-components/SearchBox";
import { VerticalDrawer } from "./components/VerticalDrawler";
import { HorizontalScroll } from "app/shared-components/HorizontalScroll";
import { CasinoCard } from "./components/CasinoCard";
import { SliderCard } from "../sports/components/SliderCard";
import { SearchModal } from "../sports/components/SearchModal";
import { TotalSelect } from "./components/TotalSelect";
import { useNavigate } from "react-router-dom";
import { TableBodyData, recommendedItems } from "./data";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useDispatch, useSelector } from "react-redux";
import { getTournament, selectSportsList } from "../store/tournamentSlice";
import { getselectedSport, toggleLive } from "../store/uiSlice";
import { useEffect } from "react";
import TableHeadSorting from "app/theme-layouts/shared-components/TableHeadSorting";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DashboardBanner from "./components/DashboardBanner";
import FooterLayout1 from "app/theme-layouts/layout1/components/FooterLayout1";
import RightSidebar from "../../shared-components/RightSidebar";
import withReducer from "app/store/withReducer";
import reducer from "../store";
import i18next from "i18next";
import { useIsLive } from "src/app/hooks/useIsLive";
import { getPopularGames, selectGameData } from "../casino/store/gameSlice";
import { BettingsBox } from "./components/BettingsBox";
import { getTopLeagues } from "../store/topLeaguesSlice";
import { flagPath } from "src/app/utils";
import { getBoostedOdds, selectBoostedOdds } from "../store/boostedOdds";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import CasinoIcon from '@mui/icons-material/Casino';
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useSystemType } from "src/app/hooks/useSystemType";
import { BETSHOP_SYSTEM } from "src/app/constants";

const StyledLeagueButton = styled('button')(({ selected, theme }) => ({
  boxShadow: "0px 0px 6px 0px #E0E0E080",
  border: "1px solid #E0E0E080",
  color: selected ? theme.palette.success.contrastText : theme.palette.text.primary,
  backgroundColor: selected ? theme.palette.success.main : theme.palette.background.default,
}));

const football_id = 79;
const basketball_id = 85;
const tennis_id = 84;

const popularSportIds = [ football_id, basketball_id, tennis_id ];

export const LeagueButton = ({ label, icon, selected, onClick }) => {
  return (
    <StyledLeagueButton
      selected={selected}
      className="text-[16px] px-[8px] md:px-[16px] py-[4px] font-500 flex items-center gap-[8px] rounded-[4px]"
      onClick={onClick}
    >
      <Typography className="whitespace-nowrap text-[inherit]">{label}</Typography>
      <img
        src={icon}
        alt="icon"
        className="w-[18px] h-[18px] min-w-[18px] min-h-[18px] object-cover sport-item-icon"
      />
    </StyledLeagueButton>
  )
}

const StyledCategoryButton = styled(Box)(({ bgcolorvalue, bordercolorvalue, selected }) => ({
  backgroundColor: bgcolorvalue,
  border: selected ? `1px solid ${bordercolorvalue}` : undefined,
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingInline: "8px",
  paddingBlock: "8px",
  gap: "5.4px",
  borderRadius: "8px",
  "& > span": {
    textTransform: "uppercase",
    fontFamily: "styrene",
    fontSize: "12px",
    color: "#505155",
  },
}));

const CategoryButton = (props) => {
  const { image, title, bgcolorvalue, bordercolorvalue, textcolor, selected } = props;
  const theme = useTheme();
  
  return (
    <StyledCategoryButton
      bgcolorvalue={bgcolorvalue} bordercolorvalue={bordercolorvalue} selected={selected}
      color={theme.palette.text.primary}
    >
      {/* <img src={image} alt="" /> */}
      {image}
      <Typography color={textcolor}>{title}</Typography>
    </StyledCategoryButton>
  );
};

const WhiteBoard = ({ title, children, moreButtonHidden = false }) => {
  const theme = useTheme();
  
  return (
    <Box
      className="p-[16px] md:p-[24px] flex flex-col rounded-[8px] shadow-md"
      aria-label="WhiteBoard"
      bgcolor={theme.palette.background.paper}
    >
      <Box className="flex justify-between items-center">
        <Typography className="text-[20px] font-bold">{title}</Typography>
        <button
          className="text-[#ec622b] uppercase p-[4px] rounded-md font-500 text-[14px] hover:bg-[#eee] active:bg-[#ddd]"
          style={{ display: moreButtonHidden ? "none" : "block" }}
        >
          {i18next.t("MORE")}
        </button>
      </Box>
      <Box className="mt-[20px]">{children}</Box>
    </Box>
  );
};

function Dashboard() {
  const { t } = useTranslation("dashboardPage");
  const [value, setValue] = useState("1");
  const [searchVisible, setSearchVisible] = useState(false);
  const navigate = useNavigate();
  const isMd = useScreenMd();
  const selectedSport = useSelector(getselectedSport);
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [tournamentLoading, setTournamentLoading] = useState(true);
  const sportList = useSelector(selectSportsList);
  const { isLive } = useIsLive();
  const { gamesStatus } = useSelector(selectGameData);
  const [popularGames, setPopularGames] = useState([]);
  const [popularLeagues, setPopularLeagues] = useState([]);
  const systemType = useSystemType();
  const boostedOdds = useSelector(selectBoostedOdds);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  console.log('Dashboard page re-rendering', Date.now());

  const theme = useTheme();

  const headRows = [
    {
      id: "game",
      align: "left",
      disablePadding: false,
      label: i18next.t("GAME"),
    },
    {
      id: "user",
      align: "right",
      disablePadding: false,
      label: i18next.t("USER"),
      sort: true,
    },
    {
      id: "time",
      align: "right",
      disablePadding: false,
      label: i18next.t("TIME"),
      sort: true,
    },
    {
      id: "get_Amount",
      align: "right",
      disablePadding: false,
      label: i18next.t("BET_AMOUNT"),
      sort: true,
    },
    {
      id: "multiplier",
      align: "right",
      disablePadding: false,
      label: i18next.t("MULTIPLIER"),
      sort: true,
    },
    {
      id: "payout",
      align: "left",
      disablePadding: false,
      label: i18next.t("PAYOUT"),
      sort: true,
    },
  ];

  useEffect(() => {
    setPopularLeagues([]);
    for (const sportId of popularSportIds) {
      dispatch(getTopLeagues({ languageId: 'en', sport_id: sportId, betType: 'unplayed' })).then(res => {
        setPopularLeagues(prev => [...prev, ...(res.payload || [])])
      })
    }
  }, []);

  useEffect(() => {
    dispatch(getPopularGames()).then(res => setPopularGames(res.payload));
    dispatch(getBoostedOdds());
  }, []);
  
  useEffect(() => {
    dispatch(getTournament({ langId: currentLanguageId, type: "unplayed" }));
  }, [currentLanguageId])

  const handleSearchClick = () => {
    setSearchVisible(true);
  };


  useEffect(() => {
    if (isLive) {
      dispatch(toggleLive());
    }
    dispatch(getTournament({ langId: "en", type: isLive ? "Live" : "unplayed" }));
  }, [dispatch, isLive]);

  useEffect(() => {
    setTournamentLoading(true);
    // dispatch(getPrematch(selectedSport.id)).then((res) => {
    // dispatch(getPrematch({ sport_id: 7, type: "Live" })).then((res) => {
    //   setEvents(res.payload ?? []);
    //   // let countries = selectedSport ? tournaments[selectedSport.name].countries : [];
    //   let countries = tournaments["Soccer"] ? tournaments["Soccer"].countries : [];
    //   if (selectedLeagues.length > 0 || true) {
    //     let leagues = [];
    //     if (countries && countries.length > 0) {
    //       countries?.forEach((country) => {
    //         if (countries.tournament && countries.tournament.length > 0) {
    //           country.tournaments?.forEach((tournament) => {
    //             leagues.push(tournament);
    //             // if (selectedLeagueIds.includes(tournament.id)) {
    //             //   leagues.push(tournament);
    //             // }
    //           });
    //         }
    //       });
    //     }
    //     setSelectedLeagues(leagues);
    //   } else {
    //     let tournamentNames = new Set();
    //     res.payload.forEach((event) => {
    //       tournamentNames.add(event.sportEvent.tournament_name);
    //     });

    //     let uniqueTournaments = [];
    //     countries.forEach((country) => {
    //       country.tournaments.forEach((tournament) => {
    //         if (tournamentNames.has(tournament.name)) {
    //           uniqueTournaments.push(tournament);
    //         }
    //       });
    //     });

    //     setSelectedLeagues(uniqueTournaments);
    //   }

    //   setTournamentLoading(false);
    // });
  }, [selectedSport]);

  // console.log('@@@', boostedOdds);

  return (
    <>
      <SearchModal visible={searchVisible} onClose={() => setSearchVisible(false)} />

      <Box aria-label="dashboard" className="flex gap-[12px] md:pl-0 md:pr-[12px]">
        <Box aria-label="leftSideBar" className="min-w-[244px] hidden md:block">
          <Box className="flex flex-col gap-[18px]">
            <Box
              className="pt-[16px] pb-[12px] px-[10px] rounded-br-[12px]"
              bgcolor={theme.palette.background.paper}
            >
              <Box className="flex gap-[9px] justify-between">
                <CategoryButton
                  bgcolorvalue={theme.palette.success.main}
                  // bordercolorvalue={theme.palette.secondary.dark}
                  textcolor={theme.palette.success.contrastText}
                  selected={true}
                  // image="assets/images/navItems/sports.svg"
                  image={<SportsSoccerIcon fontSize="small" htmlColor={theme.palette.success.contrastText} />}
                  title={i18next.t("Sports")}
                />
                <CategoryButton
                  bgcolorvalue={theme.palette.warning.main}
                  // bordercolorvalue="#38AD9C"
                  textcolor={theme.palette.warning.contrastText}
                  selected={false}
                  // image="assets/images/navItems/live-casino.svg"
                  image={<CasinoIcon fontSize="small" htmlColor={theme.palette.warning.contrastText} />}
                  title={i18next.t("Casino")}
                />
              </Box>
              <Box className="mt-[10px]">
                <SearchBox boxProps={{ onClick: handleSearchClick }} />
              </Box>
            </Box>
            <Box className="max-h-[calc(var(--app-height)-280px)] overflow-y-auto pb-[20px] px-[8px]">
              <Box className="rounded-[12px] flex flex-col shadow-lg">
                <VerticalDrawer
                  title={i18next.t("Popular")}
                  items={
                    [...popularLeagues]
                      .sort((a, b) => a.tournament_order - b.tournament_order)
                      .map(league => ({
                        name: league.tournament_name,
                        icon: flagPath(league.tournamet_flag || league.country_flag),
                        path: `/sports/leagues/${league.sport_id}?list=${league.country_id}-${league.id}`
                      }))
                  }
                  />
                <VerticalDrawer
                  title={i18next.t("All_Sports")}
                  items={sportList.map((s) => ({ ...s, path: `/sports/${s.name.removeSpaces()}` }))}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          aria-label="mainContent"
          className=" flex flex-col gap-[15px] max-h-[calc(var(--app-height)-134px)] overflow-y-auto md:pr-[8px]"
        >
          <Box className="mt-[16px] px-[4px]">
            <DashboardBanner />
          </Box>
          <Box className="px-[4px]">
            <WhiteBoard title={i18next.t("Recommended_For_You")}>
              <Box className="max-w-full">
                <HorizontalScroll>
                  <Box className="flex gap-[12px] justify-start">
                    {gamesStatus === "fulfilled" &&
                      popularGames.map((game) => (
                        <CasinoCard
                          key={game.id}
                          title={game.name}
                          subtitle={""}
                          image={game.image_square}
                          people={game.plays}
                        />
                      ))}
                  </Box>
                </HorizontalScroll>
              </Box>
            </WhiteBoard>
          </Box>
          <Box className="px-[4px]">
            <WhiteBoard title={i18next.t("Boosted_Odds")} moreButtonHidden>
              <Box className="max-w-full">
                <HorizontalScroll>

                  <Box className="flex gap-[12px] justify-start">
                    {[...boostedOdds].sort((a, b) => a.sportEvent?.start_at < b.sportEvent?.start_at ? -1 : 1).map((data, index) => (
                      <Box key={index} className="ml-[4px]">
                        <SliderCard event={data} />
                      </Box>
                    ))}
                  </Box>
                </HorizontalScroll>
              </Box>
            </WhiteBoard>
          </Box>
          <Box className="px-[4px]">
            <BettingsBox title={i18next.t("Live_Bettings")} betType="Live" onMore={() => navigate("/sports/top?live=true")} />
          </Box>
          <Box className="px-[4px]">
            <BettingsBox title={i18next.t("Sports_Betting")} betType="unplayed" onMore={() => navigate("/sports/top")} />
          </Box>
          {systemType !== BETSHOP_SYSTEM && (
            <Stack
              gap="16px"
            >
              <Box className="px-[4px]">
                <WhiteBoard title={i18next.t("Games")}>
                  <Box className="max-w-full">
                    <HorizontalScroll>
                      <Box className="flex gap-[12px] justify-start">
                        {recommendedItems.map((item, index) => (
                          <CasinoCard key={index} item={item} />
                        ))}
                      </Box>
                    </HorizontalScroll>
                  </Box>
                </WhiteBoard>
              </Box>

              <Box className="px-[4px]">
                <TotalSelect />
              </Box>

              <Box className="max-w-[100vw] px-[4px]">
                <Box className="max-w-full overflow-auto">
                  <Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
                    <TableHeadSorting sorting={true} rows={headRows} />
                    <TableBody>
                      {_.orderBy(
                        TableBodyData
                        // [
                        //     (o) => {
                        //         return o[order.id];
                        //     },
                        // ],
                        // [order.direction]
                      ).map((row, index) => {
                        return (
                          <TableRow key={index} className="h-72 cursor-pointer" hover tabIndex={-1}>
                            <TableCell align="right">{row.column1}</TableCell>
                            <TableCell align="right">{row.column2}</TableCell>
                            <TableCell align="right">{row?.column3}</TableCell>
                            <TableCell align="right">{row?.column4}</TableCell>
                            <TableCell align="right">{row?.column5}</TableCell>
                            <TableCell align="left">{row?.column6}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Stack>
          )}
          <FooterLayout1 />
        </Box>

        {isMd && <RightSidebar />}
      </Box>
    </>
  );
}

export default withReducer("sportsApp", reducer)(Dashboard);
