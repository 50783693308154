import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  clearGameGroups,
  getCasinoTypes,
  getGameGroupsForVendorGroups,
  getGamesForCasinoGameType,
  getVendorsForCasinoType,
  getVendorsForCasinoTypes,
  selectGameData,
} from "./store/gameSlice";
import { useEffect } from "react";
import { useMemo } from "react";
import { useRef } from "react";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import Skeleton from "react-loading-skeleton";
import FooterLayout1 from "app/theme-layouts/layout1/components/FooterLayout1";
import DashboardBanner from "../dashboard/components/DashboardBanner";
import { CasinoSearchBox } from "./components/CasinoSearchBox";
import { CasinoGroup } from "./components/CasinoGroup";
import { LoadingBox } from "app/shared-components/LoadingBox";
import { ShowAllBox } from "./components/ShowAllBox";
import { FavoriteBox } from "./components/FavoriteBox";
import { useCustomAuth } from "src/app/hooks/useAuth";
import { useInView } from "react-intersection-observer";
import { useScreenMd } from "src/app/hooks/useScreens";
import i18next from "i18next";

const defaultCasinoTypes = [
  { id: "all", name: "All Games", translate: "All_Games", icon: "assets/images/pages/casino/all.svg" },
  {
    id: "popular",
    name: "Popular",
    translate: "Popular",
    icon: "assets/images/pages/casino/popular.svg",
  },
  {
    id: "favorites",
    name: "My Favorites",
    translate: "My_Favorites",
    icon: "assets/images/pages/casino/favorite.svg",
  },
];

const SideButton = ({ icon, label, selected, onClick }) => {
  return (
    <button
      className="rounded-[8px] bg-[#E3E9ED] data-[selected]:bg-[#D0D1D1] border border-[#D0D1D1] flex items-center gap-[16px] py-[8px] pl-[32px] pr-[38px] w-full hover:bg-[#c0c1c1]"
      data-selected={selected || null}
      onClick={onClick}
    >
      {icon && <img src={icon} alt={label} />}
      <Typography className="text-[14px] leading-none capitalize">{label.replace(/\-/g, " ")}</Typography>
    </button>
  );
};

const Casino = ({ rootPath }) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const location = useLocation();
  const auth = useCustomAuth();

  const { casinoTypeId, vendorId } = useParams();

  const {
    casinoTypes: allCasinoTypes,
    casinoTypesStatus,
    vendorsStatus,
    // vendors,
    games,
    // vendorGroups,
    // gameGroups,
    gamesStatus,
  } = useSelector(selectGameData);

  const isMd = useScreenMd();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [vendorGroups, setVendorGroups] = useState([]);
  const [gameGroups, setGameGroups] = useState([]);

  const loadedCasinosRef = useRef([]);
  const [pageOfContent, setPageOfContent] = useState(1);
  const pageOfContentRef = useRef(0);
  const countPerPage = 2;

  const contentRef = useRef(null);

  const vendorGroupsRef = useRef(0);
  const gamesStatusRef = useRef(gamesStatus);

  gamesStatusRef.current = gamesStatus;
  
  const rootPathRef = useRef(rootPath);
  rootPathRef.current = rootPath;

  const { ref: endOfContentRef, inView } = useInView();

  const type =
    rootPath === "casino"
      ? "Casino"
      : rootPath === "live-casino"
      ? "Live Casino"
      : rootPath === "virtual-games"
      ? "Virtual"
      : "";
  const typeRef = useRef(type);
  typeRef.current = type;

  const casinoTypes = useMemo(() => {
    return allCasinoTypes.filter((casino) => casino.type === type);
  }, [allCasinoTypes, type]);

  useEffect(() => {
    dispatch(getCasinoTypes());
  }, []);

  useEffect(() => {
    dispatch(clearGameGroups());
    setPageOfContent(1);
  }, [rootPath]);

  useEffect(() => {
    // if (
    //   casinoTypeId === "all" &&
    //   (loadedCasinosRef.current.length === 0 ||
    //     casinoTypes.length === 0 ||
    //     loadedCasinosRef.current[0].id !== casinoTypes[0].id)
    // ) {
    if (loadedCasinosRef.current.length === 0 ||
      casinoTypes.length === 0 ||
      loadedCasinosRef.current[0].id !== casinoTypes[0].id
    ) {
      dispatch(getVendorsForCasinoTypes({ rootPath: rootPathRef.current, casinoTypes })).then(res => {
        const rootPath = res.payload.rootPath;
        if (rootPath !== rootPathRef.current) return;
        const vendors = res.payload.vendors;
        const vendorGroups = res.payload.vendorGroups;
        setVendors(vendors);
        setVendorGroups(vendorGroups);
      });
      loadedCasinosRef.current = casinoTypes;
    }
  }, [casinoTypeId, casinoTypes]);

  useEffect(() => {
    if (casinoTypeId && casinoTypeId !== "all") {
      dispatch(getVendorsForCasinoType(casinoTypeId));
      dispatch(getGamesForCasinoGameType(casinoTypeId));
    }
  }, [casinoTypeId]);

  useEffect(() => {
    if (vendorGroupsRef.current === 0) {
      dispatch(clearGameGroups());
      vendorGroupsRef.current = 1;
      return;
    }

    if (vendorGroups.length > 0 /*  && pageOfContentRef.current !== pageOfContent */) {
      dispatch(
        getGameGroupsForVendorGroups({
          rootPath: rootPathRef.current,
          vendorGroups: vendorGroups.slice(countPerPage * (pageOfContent - 1), countPerPage * pageOfContent)
        })
      ).then((res) => {
        const groups = res.payload.gameGroups.filter(group => group.casinoType.type === typeRef.current);
        setGameGroups((g) => [...g, ...(groups || [])]);
      });
      pageOfContentRef.current = pageOfContent;

      vendorGroupsRef.current === 0;
    }
  }, [vendorGroups, pageOfContent]);

  useEffect(() => {
    if (inView) {
      gamesStatusRef.current = "loading";
      setPageOfContent((p) => p + 1);
    }
  }, [inView]);

  const handleCasinoTypeClick = (casinoType) => {
    navigator(`/${rootPath}/${casinoType.id}`);
    if (!isMd) setSidebarVisible(false);
  };

  const handleProviderClick = (provider) => {
    navigator(`/${rootPath}/vendor/${provider.vendor_id}`);
    if (!isMd) setSidebarVisible(false);
  };

  const handleAllClick = (casinoId) => {
    navigator(`/${rootPath}/detail/${casinoId}`);
  };

  const handleCasinoDemoClick = (casinoId) => {
    navigator(`/${rootPath}/demo/${casinoId}`);
  }

  const handleCasinoPlayClick = (casinoId) => {
    navigator(`/${rootPath}/game/${casinoId}`);
  }

  const sidebar = () => {
    return (
      <Box
        aria-label="sidebar"
        className={`md:block w-[300px] min-w-[300px] max-h-[inherit] ${!isMd ? "casino-sidebar-sm" : ""}`}
        data-visible={sidebarVisible || null}
      >
        <Box
          aria-label="sidebar"
          className="pt-[16px] px-[8px] flex flex-col gap-[8px] min-w-max max-h-[inherit] overflow-auto pb-[20px]"
        >
          {defaultCasinoTypes
            .filter((t) => t.id !== "favorites" || auth)
            .map((type) => {
              return (
                <SideButton
                  key={type.id}
                  label={i18next.t(type.translate)}
                  icon={type.icon}
                  selected={
                    type.id.toString() === casinoTypeId ||
                    (type.id === "popular" && location.pathname.includes("/popular")) ||
                    (type.id === "favorites" && location.pathname.includes("/favorites"))
                  }
                  onClick={() => handleCasinoTypeClick(type)}
                />
              );
            })}
          {casinoTypesStatus !== "fulfilled" &&
            Array(10)
              .fill("")
              .map((_, index) => <Skeleton key={index} enableAnimation className="rounded-[8px] py-[8px]" />)}
          {casinoTypesStatus === "fulfilled" &&
            casinoTypes.map((type) => (
              <SideButton
                key={type.id}
                label={type.name}
                icon={"assets/images/pages/casino/fast.svg"}
                selected={type.id.toString() === casinoTypeId}
                onClick={() => handleCasinoTypeClick(type)}
              />
            ))}
          <Typography className="text-[16px] font-500 mt-[20px] mb-[12px]">{i18next.t("Vendors")}</Typography>
          {vendorsStatus !== "fulfilled" &&
            Array(20)
              .fill("")
              .map((_, index) => <Skeleton key={index} enableAnimation className="rounded-[8px] py-[8px]" />)}
          {vendorsStatus === "fulfilled" &&
            [...vendors].map((p) => (
              <SideButton
                key={p.id}
                label={p.vendor_name}
                selected={p.vendor_id.toString() === vendorId}
                onClick={() => handleProviderClick(p)}
              />
            ))}
        </Box>
      </Box>
    );
  };

  const sidebarDatas = [...defaultCasinoTypes, ...casinoTypes, ...vendors];
  const sidebarData = sidebarDatas.find(
    (t) =>
      (casinoTypeId && t.id && t.id.toString() === casinoTypeId) ||
      (vendorId && t.vendor_id && t.vendor_id.toString() === vendorId)
  );

  const mainContent = () => {
    return (
      <Box aria-label="content" className="flex flex-col w-full max-h-[inherit] overflow-auto" ref={contentRef}>
        <Box className="w-full px-[8px] mb-[8px]">
          <Box className="w-full min-h-[16px]"></Box>
          <DashboardBanner />
          <CasinoSearchBox
            item={sidebarData || defaultCasinoTypes[0]}
            rootPath={rootPath}
            onRemove={() => {
              navigator(`/${rootPath}/all`);
            }}
          />
        </Box>
        <Box className="md:hidden sticky top-0 flex w-full justify-center py-[16px] bg-green-200 z-[300]">
          <button
            className="py-[8px] px-[16px] rounded-[4px] bg-green text-white"
            onClick={() => setSidebarVisible(true)}
          >
            Categories & Vendors
          </button>
        </Box>
        {!location.pathname.includes("/detail") &&
          !location.pathname.includes("/vendor") &&
          !location.pathname.includes("/favorites") &&
          !location.pathname.includes("/popular") && (
            <>
              <Box className="w-full flex flex-col gap-[28px] mt-[28px] px-[8px]">
                {casinoTypeId === "all" &&
                  gameGroups.map(({ casinoType, games }) => (
                    <CasinoGroup
                      key={casinoType.id}
                      title={casinoType.name}
                      items={games}
                      onAllClick={() => handleAllClick(casinoType.id)}
                      rootPath={rootPath}
                    />
                  ))}
                {casinoTypeId !== "all" && games.length > 0 && (
                  <CasinoGroup
                    title={casinoTypes.find((c) => c.id.toString() === casinoTypeId)?.name || "Games"}
                    items={games}
                    onAllClick={() => handleAllClick(casinoTypeId)}
                    rootPath={rootPath}
                  />
                )}
              </Box>
            </>
          )}
        {location.pathname.includes("/detail") && (
          <ShowAllBox key={casinoTypeId} rootPath={rootPath} origin="game" currentPage={pageOfContent} />
        )}
        {location.pathname.includes("/vendor") && (
          <ShowAllBox key={vendorId} rootPath={rootPath} origin="vendor" currentPage={pageOfContent} />
        )}
        {location.pathname.includes("/favorites") && (
          <FavoriteBox key={rootPath} rootPath={rootPath} origin="favorites" currentPage={pageOfContent} />
        )}
        {location.pathname.includes("/popular") && (
          <ShowAllBox key={rootPath} rootPath={rootPath} origin="popular" currentPage={pageOfContent} />
        )}

        {gamesStatus !== "loading" && gameGroups.length > 0 && gameGroups.length < vendorGroups.length && (
          <Box aria-label="end-of-content" ref={endOfContentRef}></Box>
        )}
        {casinoTypeId === "all" &&
          (casinoTypesStatus !== "fulfilled" || vendorsStatus !== "fulfilled" || gamesStatus !== "fulfilled") && (
            <Box className="mt-[36px]">
              <LoadingBox />
            </Box>
          )}
        <FooterLayout1 />
      </Box>
    );
  };

  return (
    <Box aria-label="casino" className="flex max-h-[calc(var(--app-height)-69px)] md:max-h-[calc(var(--app-height)-134px)]">
      {sidebar()}
      {mainContent()}
      <Box
        className="fixed left-0 top-0 w-screen h-[calc(var(--app-height))] hidden opacity-0 bg-[#000000a0] data-[visible]:block data-[visible]:opacity-100 z-[1001]"
        data-visible={sidebarVisible || null}
        onClick={() => setSidebarVisible(false)}
      ></Box>
    </Box>
  );
};

export default withReducer("casinoApp", reducer)(Casino);
