import React, { useState, useEffect } from "react";
import { getWithdrawBankTransfer, getBankList } from "../main/store/uiSlice";
import { useDispatch } from "react-redux";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { SelectGroup } from "../main/settings/component/InputGroups";

const BankTransferForm = () => {
  const [bank, setBank] = useState({ id: 9, name: "Akbank" });
  const [iban, setIban] = useState("");
  const [identity, setIdentity] = useState("");
  const [dob, setDob] = useState("");
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();
  const [bankList, setBankList] = useState([
    { id: 9, name: "Akbank" },
    { id: 13, name: "Denizbank" },
    { id: 1, name: "Garanti Bankası" },
    { id: 4, name: "ING Bank" },
  ]);

  //   useEffect(() => {
  //     const fetchBankList = async () => {
  //       const response = await dispatch(getBankList());
  //       const bankData = await response.payload;
  //       setBankList(bankData);
  //     };

  //     fetchBankList();
  //   }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      getWithdrawBankTransfer({
        sid: YOUR_MERCHANT_SID,
        key: YOUR_MERCHANT_KEY,
        user_id: "USER_ID",
        username: "USER_USERNAME",
        fullname: "USER_FULLNAME",
        trx: "TRANSACTION_ID",
        amount: amount,
        iban: iban,
        bank: bank,
        identity: identity,
        birth_date: dob,
      })
    ).then(async (response) => {
      const result = await response.json();
      if (result.code === 200) {
        alert("Withdrawal request accepted. Status changed to Pending.");
      } else {
        alert(`Withdrawal request rejected: ${result.message}`);
      }
    });
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Bank Transfer Withdrawal Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <SelectGroup 
            options={bankList}
            value={bank}
            label={bank.name}
            onChange={(value) => setBank(value)}/>

        <TextField
          label="IBAN"
          fullWidth
          value={iban}
          onChange={(e) => {
            setIban(e.target.value);
          }}
          margin="normal"
          required
        />

        <TextField
          label="TC Kimlik Numarası"
          fullWidth
          value={identity}
          onChange={(e) => setIdentity(e.target.value)}
          margin="normal"
          required
        />

        <TextField
          label="Date of Birth"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          margin="normal"
          required
        />

        <TextField
          label="Amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => {
            console.log(e);
            setAmount(e.target.value);
          }}
          margin="normal"
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Submit Withdrawal Request
        </Button>
      </form>
    </Box>
  );
};

export default BankTransferForm;
