import { Box, Collapse, Typography, styled } from "@mui/material";
import { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCashierModalTab, setCashierModalVisible } from "../../store/uiSlice";
import { useSystemType } from "src/app/hooks/useSystemType";
import { ONLINE_SYSTEM } from "src/app/constants";

const ItemLink = styled(Link)(({ selected }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",
  color: selected ? "#04CEB0 !important" : "#505155 !important",
  backgroundColor: selected ? "#F2F5F7" : "transparent",
  fontFamily: "styrene",
  fontSize: "14px",
  width: "100%",
  padding: "12px 18px",
  "&:hover": {
    backgroundColor: "#00000010",
  },
  transition: "all 0.15s",
}));

export const CardBox = ({ title, itemList }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const systemType = useSystemType();

  const handleLinkClick = (item) => {
    if (item.id === "deposit" || item.id === "withdrawal") {
      dispatch(setCashierModalTab(item.id));
      dispatch(setCashierModalVisible(true));
    }
  };

  return (
    <Box aria-label="cardBox" className="flex flex-col min-w-[310px]">
      <Box
        className="rounded-t-[12px] flex justify-between items-center bg-[#0d1d34] py-[8px] px-[16px]"
        onClick={() => setCollapsed((c) => !c)}
      >
        <Typography className="text-white text-[14px] uppercase font-500">{title}</Typography>
        {collapsed && <KeyboardArrowDownIcon />}
        {!collapsed && <KeyboardArrowUpIcon />}
      </Box>
      <Collapse in={!collapsed} className="bg-white rounded-b-[12px]">
        {itemList
          .filter(item => systemType === ONLINE_SYSTEM || (
            item.id !== "account-verification" &&
            item.id !== "deposit" &&
            item.id !== "withdrawal" &&
            item.id !== "bonus"
          ))
          .map((item) => (
            <ItemLink
              key={item.id}
              to={item.id === "withdrawal" || item.id === "deposit" ? undefined : item.path}
              onClick={() => handleLinkClick(item)}
              selected={location.pathname === item.path}
            >
              <img src={item.icon} alt="" />
              <Typography>{item.title}</Typography>
            </ItemLink>
        ))}
      </Collapse>
    </Box>
  );
};
