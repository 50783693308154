import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectSystem } from "../main/store/systemSettingsSlice";

function LogoNew() {
  const systemSettings = useSelector(selectSystem);

  return (
    <Box>
      <Link href="/">
        {systemSettings.logo && (
          <img
            src={systemSettings.logo}
            alt="logo"
            className="w-[140px] h-[30px] object-contain"
          />
        )}
      </Link>
    </Box>
  );
}

export default LogoNew;
