import { Box, Radio, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useModal } from "src/app/hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { setOddsFormat, selectUserOddsFormat } from "src/app/main/store/uiSlice";
import i18n from "src/i18n";
import { selectCurrentLanguage } from "app/store/i18nSlice";

const oddsList = [
  { id: "decimal", title: "Decimal_odds", sample: "1.90" },
  { id: "american", title: "American_odds", sample: "+/-" },
  { id: "fractional", title: "Fractional_odds", sample: "6/1" },
  { id: "malaysian", title: "Malaysian_odds", sample: "-0.834" },
  { id: "indonesian", title: "Indonesian_odds", sample: "+1.20" },
  { id: "hongkong", title: "Hong_Kong_odds", sample: "1.20" },
];

export const OddsSettings = ({ visible, onClose }) => {
  useModal(visible);
  const [checked, setChecked] = useState({});
  const dispatch = useDispatch();
  const oddsFormat = useSelector(selectUserOddsFormat);
  const currentLanguage = useSelector(selectCurrentLanguage);

  return (
    <Box
      className="fixed left-0 top-0 w-screen h-[calc(var(--app-height))] z-[1000] bg-[#ffffff20] opacity-0 data-[visible=true]:opacity-100 hidden data-[visible=true]:flex justify-center items-center transition-all duration-200"
      sx={{ backdropFilter: "blur(4px)" }}
      data-visible={visible}
      aria-label="oddsSettingsBackdrop"
      onClick={onClose}
    >
      <Box
        aria-label="oddsSettings"
        className="relative rounded-[12px] overflow-hidden bg-[#f0f3f8] shadow-xl"
        onClick={(e) => e.stopPropagation()}
      >
        <Box className="px-[24px] py-[12px] bg-white">
          <Typography className="text-[20px] text-[#3e4049]">Settings</Typography>
        </Box>
        <button onClick={onClose} className="absolute top-[16px] right-[16px]">
          <CloseIcon />
        </button>
        <Box className="flex flex-col md:flex-row p-[24px] gap-[16px]">
          <Box className="flex flex-col">
            <button className="bg-[#e2e8ee] rounded-[4px] p-[8px] text-[#4da533]">{i18n.t("Odds_format")}</button>
          </Box>
          <Box className="flex flex-col py-[4px] rounded-[4px] bg-white w-full lg:min-w-[500px]">
            {oddsList.map((odds) => (
              <button
                key={odds.id}
                className="px-[16px] py-[8px] flex justify-between gap-[48px] items-center first-of-type:border-t-0 border-t-1 border-t-[#e2e8ee] hover:bg-[#eee] transition-all duration-200"
                onClick={() => {
                  setChecked({ [odds.id]: true }), dispatch(setOddsFormat(odds.id));
                }}
              >
                <Box>
                  <Typography className="text-[14px]">{i18n.t(odds.title)}</Typography>
                  <Typography className="text-[#4da533] text-[12px] mt-[4px]">{odds.sample}</Typography>
                </Box>
                <Radio
                  checked={oddsFormat == odds.id}
                  onChange={(e) => {
                    setChecked(() => ({ [odds.id]: e.target.checked }));
                    dispatch(setOddsFormat(odds.id));
                  }}
                  sx={{
                    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                      color: checked[odds.id] ? "yellowgreen" : undefined,
                    },
                    "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                      color: "yellowgreen",
                    },
                  }}
                />
              </button>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
