import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "src/app/hooks/useModal";
import { setMobileSignupOpen, setMoblieLoginOpen, signout } from "src/app/main/store/uiSlice";
import { mobileNavItems } from "app/theme-layouts/layout1/components/NavigationBar";
import { Link, useLocation } from "react-router-dom";
import LanguageSwitcherMobile from "../LanguageSwitcherMobile";
import CloseIcon from "@mui/icons-material/Close";
import { useCustomAuth } from "src/app/hooks/useAuth";
import { useIsLive } from "src/app/hooks/useIsLive";
import { useSystemType } from "src/app/hooks/useSystemType";
import { ONLINE_SYSTEM } from "src/app/constants";
import i18n from "src/i18n";
import { selectCurrentLanguage } from "app/store/i18nSlice";


export const MobileNavBar = (props) => {
  const { visible, onClose } = props;
  const dispatch = useDispatch();
  const authenticated = useCustomAuth();
  const { isLive } = useIsLive();
  const systemType = useSystemType();
  
  // The following must be containted even it's not used
  const currentLanguage = useSelector(selectCurrentLanguage);

  useModal(visible);

  const handleLogout = () => {
    dispatch(signout());
    onClose();
  };

  const handleLogin = () => {
    dispatch(setMoblieLoginOpen(true));
    onClose();
  };

  const handleSignup = () => {
    dispatch(setMobileSignupOpen(true));
    onClose();
  };

  return (
    <>
      <Box
        aria-label="mobile-navbar-backdrop"
        className="fixed left-0 top-0 z-[9999] opacity-0 data-[open=true]:opacity-100 hidden data-[open=true]:block md:!hidden w-screen h-[calc(var(--app-height))] data-[open=true]:bg-[#969DA3a0] transition-all duration-150"
        data-open={visible}
        onClick={onClose}
      ></Box>
      <Box
        className="absolute top-0 z-[10000] flex flex-col gap-[30px] -left-[280px] data-[open=true]:left-0 bg-white w-[280px] h-full backdrop:blur-[10px] rounded-r-[6px] overflow-hidden transition-all duration-150"
        aria-label="mobile-navbar-left"
        data-open={visible}
      >
        <button className="absolute right-0 top-0 p-[4px] bg-[#ffffff10]" onClick={onClose}>
          <CloseIcon className="text-white" />
        </button>
        <Box className="px-[12px] py-[38px] bg-[#021c26]">
          <Box className="flex items-center justify-between">
            {authenticated && (
              <Link to="/sign-out" className="flex gap-[4px] items-center">
                <img src="assets/images/logout.svg" />
                <span className="text-[#FA652B] text-[12px] font-[500]">Log Out</span>
              </Link>
            )}
            {!authenticated && (
              <>
                <Box className="flex gap-[4px] text-[12px]">
                  <button
                    className="bg-[#ec622b] rounded-full text-white py-[5px] px-[10px] whitespace-nowrap"
                    onClick={handleSignup}
                  >
                    Sign Up
                  </button>
                  <button
                    className="bg-[#4da533] rounded-full text-white py-[5px] px-[10px] uppercase whitespace-nowrap"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </Box>
              </>
            )}
            <LanguageSwitcherMobile />
          </Box>
        </Box>
        <Box className="flex-grow overflow-y-auto">
          <Box className="flex flex-col">
            {mobileNavItems.filter(item => systemType === ONLINE_SYSTEM || (item.id !== "promotion" && item.id !== "affiliate")).map((item) => {
              let selected = RegExp(item.active).test(location.pathname);
              if (location.pathname.startsWith('/sports')) {
                selected = (item.id === "sports-betting" && !isLive) || (item.id === "live-sports" && isLive);
              }
              return (
                <Link
                  key={item.id}
                  className="flex gap-[8px] items-center h-[50px] px-[12px] py-[6px] hover:bg-[#1C273F] ease-out border-b border-b-[#F0F3F8]"
                  to={item.path}
                  onClick={onClose}
                  style={selected ? { backgroundColor: "#223661" } : undefined}
                >
                  <img src={`assets/images/navItems/${item.icon}`} alt="nav-item" />
                  <Typography
                    className="text-[14px] text-[#505155] font-500 uppercase"
                    sx={selected ? { color: "white !important" } : undefined}
                  >
                    {i18n.t(item.translate)}
                  </Typography>
                </Link>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};
