import { Alert, Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ModalBox } from "app/shared-components/ModalBox";
import { useModal } from "src/app/hooks/useModal";
import { InputGroup, SelectGroup } from "../../settings/component/InputGroups";
import { useRef } from "react";
import toast from "react-hot-toast";
import CloseIcon from '@mui/icons-material/Close';
import Turnstile from "react-turnstile";

const siteCategories = [
  { label: "Sports predictions", value: "sports-predictions" },
  { label: "Sports news", value: "sports-news" },
  { label: "Bookmakers and bets", value: "bookmakers-and-bets" },
  { label: "Sports broadcasts", value: "sports-broadcasts" },
  { label: "Casino", value: "casino" },
  { label: "Sport", value: "sport" },
  { label: "Other", value: "other" },
  { label: "Facebook", value: "facebook" },
  { label: "Aso", value: "aso" },
  { label: "Telegram", value: "telegram" },
  { label: "Ad networks", value: "ad-networks" },
];

const countries = [
  { label: "United States", value: "us" },
  { label: "Turkey", value: "tr" },
];

export const AffiliateRequestModal = ({ visible, onClose }) => {
  const recaptcha = useRef();

  useModal(visible);

  const header = () => {
    return (
      <Box className="p-[12px] min-w-screen md:min-w-[600px] invisible md:block">
        <img src="assets/images/logo/logo.png" alt="logo" className="w-[140px] h-auto object-contain" />
      </Box>
    );
  };

  const handleCaptcha = (token) => {};

  const handleSubmit = (event) => {
    event.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      toast.custom(<Alert severity="error">Please verify the reCAPTCHA!</Alert>);
    } else {
      // make form submission
    }
  };

  const body = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Box className="min-w-[60vw] p-[40px] max-h-[var(--app-height)] pb-[65px] md:pb-[40px] md:max-h-[80vh] overflow-y-auto">
          <Box className="py-[8px] bg-white flex md:hidden" onClick={onClose}>
            <button className="p-[4px] ml-auto">
              <CloseIcon />
            </button>
          </Box>
          <Box className="flex flex-col md:flex-row gap-[40px]">
            <Box aria-label="username-and-password" className="w-full flex flex-col gap-[24px]">
              <Box>
                <Typography className="text-[20px]">Username and password</Typography>
                <Box className="flex flex-col gap-[12px] mt-[16px]">
                  <InputGroup label="Login *" />
                  <InputGroup label="Password *" inputProps={{ type: "password" }} />
                </Box>
              </Box>
              <Box>
                <Typography className="text-[20px]">Additional information</Typography>
                <Box className="flex flex-col gap-[12px] mt-[16px]">
                  <InputGroup label="Website *" />
                  <SelectGroup label="Site category *" options={siteCategories} />
                </Box>
              </Box>
            </Box>
            <Box aria-label="contact-detail" className="w-full flex flex-col gap-[24px]">
              <Box>
                <Typography className="text-[20px]">Contact details</Typography>
                <Box className="flex flex-col gap-[12px] mt-[16px]">
                  <InputGroup label="First name *" />
                  <InputGroup label="Last name *" inputProps={{ type: "password" }} />
                  <InputGroup label="Email *" inputProps={{ type: "password" }} />
                  <SelectGroup label="Country *" options={countries} />
                  <InputGroup label="Phone number *" />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="mt-[42px]">
            <Typography component="span">The https://meganetgame.com is used in accordance with the</Typography>{" "}
            <button className="text-[#3c9fdd] underline hover:no-underline">Terms and Conditions</button> and{" "}
            <button className="text-[#3c9fdd] underline hover:no-underline">Privacy Policy.</button>
          </Box>
          <Typography className="mt-[12px]">
            The personal data that you supply when registering on this website may be shared with the meganetgame
            affiliate companies in different countries and third parties rendering services to meganetgame.
          </Typography>
          <FormControlLabel
            className="mt-[24px]"
            control={<Checkbox defaultChecked sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }} />}
            label="I have read, understood and accept the above Terms and Conditions and Privacy Policy"
          />
          <Box className="flex flex-col justify-center items-center mt-[12px] gap-[24px]">
            {/* <Turnstile sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY} onVerify={handleCaptcha} /> */}
            <button
              type="submit"
              className="uppercase text-white px-[50px] py-[12px] text-[18px] rounded-[6px] bg-[#28F09C] hover:bg-[#21e090]"
            >
              Request
            </button>
          </Box>
        </Box>
      </form>
    );
  };

  return <ModalBox visible={visible} onClose={onClose} header={header()} body={body()} />;
};
