import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";

export const CountdownToast = ({ countdownTime, toastId }) => {
  const [timeLeft, setTimeLeft] = useState(countdownTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(interval);
          toast.dismiss(toastId); // Close the toast when time is up
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [countdownTime, toastId]);

  return <div>Reconnecting in {timeLeft} seconds</div>;
};