import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useScreenSm } from "src/app/hooks/useScreens";
import { Box, Drawer, Popover, Collapse, Typography, useTheme } from "@mui/material";
import { setCashierModalVisible } from "src/app/main/store/uiSlice";
import { useSystemType } from "src/app/hooks/useSystemType";
import { ONLINE_SYSTEM } from "src/app/constants";
import { MobileUserProfileMenu } from "./MobileUserProfileMenu";
import i18n from "src/i18n";
import {selectCurrentLanguageId} from "app/store/i18nSlice";

const UserDropdown = ({ handleWithdraw }) => {
  const [menu, setMenu] = useState(null);
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
  const dispatch = useDispatch();
  const isSm = useScreenSm();
  const systemType = useSystemType();
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const handleDepositClick = () => {
    dispatch(setCashierModalVisible(true));

  };

  const handleWithdrawClick = () => {
    dispatch(setCashierModalVisible(true));
  };

  const handleUserbuttonClick = (e) => {
    if (isSm) {
      setMenu(e.currentTarget);
    } else {
      setMobileDrawerVisible(true);
    }2
  };

  const dropdownButtonList = [
    { id: "profile", title: "Edit Profile", translate: "Edit_Profile", path: "/settings/profile" },
    { id: "deposit", title: "Deposit", translate: "Deposit", path: "#", onClick: handleDepositClick },
    { id: "withdraw", title: "Withdraw", translate: "Withdraw", path: "#", onClick: handleWithdraw },
    { id: "bet-history", title: "bet history", translate: "Bet_History", path: "/settings/game-history" },
  ];


  return (
    <Box>
      <Box aria-label="userDropdown" className="relative ml-[12px] text-white">
        <button
          className="rounded-[12px] px-[8px] sm:pl-[12px] sm:pr-[8px] h-[40px] flex items-center bg-[#ffffff10] hover:bg-[#ffffff30] active:bg-[#ffffff50]"
          onClick={handleUserbuttonClick}
        >
          <PersonIcon />
          <KeyboardArrowDownIcon className="hidden sm:block" />
        </button>
      </Box>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={() => setMenu(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "hidden sm:block max-h-[400px] !duration-75 text-white mt-[12px] bg-transparent",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "12px",
          },
          zIndex: "9999",
        }}
      >
        <Box className="bg-[#2C374F] flex flex-col shadow-2xl overflow-hidden">
          <Box className="flex flex-col items-start">
            {dropdownButtonList
              .filter(item => systemType === ONLINE_SYSTEM || (item.id !== "deposit" && item.id !== "withdraw"))
              .map((b) => (
                <Link
                  key={b.id}
                  to={b.path}
                  className="px-[16px] py-[8px] hover:bg-[#cccccc40] uppercase whitespace-nowrap w-full text-left text-[12px]"
                  onClick={() => {
                    setMenu(null);
                    b.onClick && b.onClick();
                  }}
                  style={{ color: "white" }}
                >
                  {i18n.t(b.translate)}
                </Link>
            ))}
            <button
              className="px-[16px] py-[8px] hover:bg-[#cccccc40] uppercase whitespace-nowrap w-full text-left text-[12px] !border-t !border-t-[#ffffff80]"
              onClick={() => setMenu(null)}
            >
              <Link to="/sign-out" className="w-full h-full block" style={{ color: "white" }}>
                {i18n.t("Log_out")}
              </Link>
            </button>
          </Box>
        </Box>
      </Popover>
      <MobileUserProfileMenu 
        open={mobileDrawerVisible} 
        handleClose={() => setMobileDrawerVisible(false)} 
      />
    </Box>
  );
};


export default UserDropdown;