import { Box, Button, Stack, Typography } from "@mui/material";

export const Cashback = ({
  title,
  amount,
  onClaim,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      flexGrow={1}
      sx={{
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Box>
        <Typography variant="h6" color="white">{title}</Typography>
        <Typography variant="body2" color="white">Total earned</Typography>
        <Typography variant="h5" color="white">{amount}</Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={onClaim}
      >
        Claim
      </Button>
    </Stack>
  );
};