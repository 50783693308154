import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { convertOdds } from "src/app/utils";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getPrematchesByLeague = createAsyncThunk("sportsApp/getPrematchesByLeague", async (data) => {
  try {
    const { betType, sport_id, country_id, tournament_id } = data;
    const res = await axios.get(`${api_domain}events/${betType}/${sport_id}/${country_id}/${tournament_id}`);
    return res ? res.data.sportEventList.map((e) => ({ ...e, sportEvent: { ...e.sportEvent, tournament_id } })) : [];
  } catch (_) {
    return false;
  }
});

export const getPrematch = createAsyncThunk("sportsApp/getPrematch", async (data) => {
  const response = await axios.get(`${api_domain}events/${data.type}/${data.sport_id}`);
  const res = response.data.sportEventList;
  return res === undefined ? null : res;
});

export const getOutcomeByReference = createAsyncThunk("sportsApp/getOutcomeByReference", async (referenceId) => {
  const response = await axios.get(`${api_domain}outcomes/${referenceId}`);
  const data = response.data;
  return data === undefined ? null : data;
});

function classifyOutcomes(sportEventList, oddsFormart) {
  const classified = {};

  sportEventList.forEach((eventWrapper) => {
    const event = eventWrapper.sportEvent;
    const outcomes = eventWrapper.outcome;

    if (outcomes) {
      if (!classified[event.id]) {
        classified[event.id] = {
          eventDetails: event,
          markets: {},
        };
      }

      outcomes.forEach((outcome) => {
        if (!classified[event.id].markets[outcome.market_id]) {
          classified[event.id].markets[outcome.market_id] = [];
        }
        classified[event.id].markets[outcome.market_id].push({
          ...outcome,
          odds: convertOdds(outcome.odds, oddsFormart),
        });
      });
    }
  });

  return classified;
}

function updateOutcomeOdds(sportEventList, oddsFormat) {
  const updatedList = sportEventList
    .filter((e) => !!e.outcome)
    .map((eventWrapper) => {
      const updatedOutcomes = eventWrapper.outcome.map((outcome) => ({
        ...outcome,
        odds: convertOdds(outcome.odds, oddsFormat),
      }));

      return {
        ...eventWrapper,
        outcome: updatedOutcomes,
      };
    });

  return updatedList;
}

const prematchAdapter = createEntityAdapter();

export const { selectAll: selectAllPrematchList } = prematchAdapter.getSelectors(
  (state) => state.sportsApp.prematchList
);

const prematchSlice = createSlice({
  name: "sportsApp/prematch",
  initialState: prematchAdapter.getInitialState({
    prematchList: [],
    originPrematchList: [],
    oddsFormat: "decimal",
    loading: false,
    nextLoading: false,
    datafeedDown: false,
    marketDic: null,
  }),
  reducers: {
    updateOdds: {
      reducer: (state, action) => {
        state.oddsFormat = action.payload;
        // state.prematchList = updateOutcomeOdds(state.originPrematchList, action.payload);
      },
    },
    removeEventsByLeague: (state, action) => {
      const { tournament_id } = action.payload;
      state.prematchList = state.prematchList.filter((p) => p.sportEvent.tournament_id !== tournament_id);
    },
    clearEvents: (state) => {
      state.prematchList = [];
    },
    saveMarketDic: (state, action) => {
      state.marketDic = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrematch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrematch.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload) {
          state.prematchList = [];
          state.originPrematchList = [];
        } else {
          state.prematchList = action.payload; //updateOutcomeOdds(action.payload, state.oddsFormat);
          state.originPrematchList = action.payload;
        }
      })
      .addCase(getPrematch.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPrematchesByLeague.pending, (state) => {
        state.nextLoading = true;
      })
      .addCase(getPrematchesByLeague.fulfilled, (state, action) => {
        state.nextLoading = false;
        if (action.payload === false) {
          state.datafeedDown = true;
          return;
        }
        
        const neededEvents = action.payload.filter(
          (p) => !state.prematchList.some((pp) => pp.sportEvent.tournament_id === p.sportEvent.tournament_id)
        );
        state.prematchList = [...state.prematchList, ...neededEvents];
      })
      .addCase(getPrematchesByLeague.rejected, (state) => {
        state.datafeedDown = true;
        state.nextLoading = false;
      });
  },
});

export const { updateOdds, removeEventsByLeague, clearEvents,saveMarketDic } = prematchSlice.actions;
export const selectPrematchList = ({ sportsApp }) => sportsApp.prematch.prematchList;
export const selectPrematchLoading = ({ sportsApp }) => sportsApp.prematch.loading;
export const selectPrematchNextLoading = ({ sportsApp }) => sportsApp.prematch.nextLoading;
export const selectDatafeedDown = ({ sportsApp }) => sportsApp.prematch.datafeedDown;
export const selectMarketDic = ({ sportsApp }) => sportsApp.prematch.marketDic;
export default prematchSlice.reducer;
