import { Box } from "@mui/material";
import { ContentCard } from "../component/ContentCard";
import { useState } from "react";
import { SelectGroup } from "../component/InputGroups";
import { DatePicker } from "@mui/x-date-pickers";

const datePickerSx = {
  "& input": { paddingY: "3px" },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ddd !important",
    borderWidth: "1px !important",
    borderRadius: "12px",
  },
  "& .MuiInputBase-root": {
    minHeight: "38px",
  },
};

const options = [
  { label: "All", value: "all" },
  { label: "Deposit", value: "deposit" },
  { label: "Withdrawal", value: "withdrawal" },
  { label: "Refund", value: "refund" },
  { label: "Balance correction", value: "correction" },
  { label: "Reversal deposit", value: "reversal-deposit" },
  { label: "Reversal withdrawal", value: "reversal-withdrawal" },
];

export const Transaction = () => {
  const [value, onChange] = useState(new Date());

  return (
    <Box aria-label="Transaction">
      <ContentCard title="Transaction History" icon="assets/images/pages/settings/history.svg">
        <Box className="bg-white rounded-b-[12px] p-[12px]">
          <Box className="flex flex-col gap-[12px] max-w-[1000px] mx-auto min-h-[400px]">
            <Box className="flex flex-col md:flex-row justify-between items-center">
              <Box className="flex flex-col md:flex-row gap-[12px] w-full md:w-auto">
                <SelectGroup options={options} />
                <DatePicker sx={datePickerSx} />
                <DatePicker sx={datePickerSx} />
              </Box>
              <button className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] mt-[12px] md: pt-0">
                Get
              </button>
            </Box>
            <Box className="flex justify-center mt-[100px] text-[24px] text-[#00000080]">No Transactions</Box>
          </Box>
        </Box>
      </ContentCard>
    </Box>
  );
};
