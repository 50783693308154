import { Box, Collapse, Typography } from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const VerticalDrawer = (props) => {
  const { section, items, titleIcon } = props;
  const [menuVisible, setMenuVisible] = useState(true);

  return (
    <Box
      aria-label="verticalScrollBar"
      className="flex flex-col"
      sx={{
        "&:first-of-type": {
          "[aria-label=titleBox]": {
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          },
        },
        "&:last-child": {
          "[aria-label=drawButton]:last-child": {
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          },
        },
      }}
    >
      <button
        aria-label="titleBox"
        className="bg-[#E4E5E5] flex justify-between items-center px-[16px] py-[8px]"
        onClick={() => setMenuVisible((v) => !v)}
      >
        <Typography className="uppercase text-[#505155] font-500">
          {section.titleIcon && <FontAwesomeIcon icon={section.titleIcon} className="!text-[18px]" />} {section.title}
        </Typography>
        {menuVisible && <KeyboardArrowUpIcon />}
        {!menuVisible && <KeyboardArrowDownIcon />}
      </button>
      <Collapse in={menuVisible}>
        <Box className="flex flex-col">
          {items.map((item, index) => (
            <Link
              key={index}
              aria-label="drawButton"
              className="px-[16px] py-[8px] flex gap-[8px] items-center border-b border-b-[#F0F3F8] border-x border-x-[#F0F3F8] hover:bg-[#ddd] whitespace-nowrap"
              to={`/information/${item.section}/${item.id}`}
            >
              {item.icon && <img src={item.icon} alt="icon" />}
              <Typography className="text-[#7A7A7B] text-[14px]">{item.title}</Typography>
            </Link>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};
