import { Box, Typography, useTheme } from "@mui/material";
import { React, useEffect, useState } from "react";
import Slider from "react-slick";
import Carousel from "react-material-ui-carousel";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { getCMSList } from "../../store/cmsSlice";

const bannerSettings = {
  dots: true,
  infinite: true,
  speed: 4000,
  autoplay: false,
  autoplaySpeed: 30,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const carouselStyles = {
  img: {
    width: "100%",
    height: "233px",
    borderRadius: "6px",
    objectFit: "cover",
  },
  indicator: {
    position: "absolute",
    bottom: "10px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    zIndex: 1,
  },
};

function DashboardBanner() {
  const dispatch = useDispatch();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const [banners, setBanners] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    dispatch(getCMSList("banners")).then((res) => {
      const list = res.payload;
      setBanners(
        [...list]
          .filter((item) => item.section === "home" && item.active)
          .sort((a, b) => a.position < b.position)
      );
    });
  }, []);

  return (
    <Box
      aria-label="dashboardBanner"
      className="w-full max-w-full mx-auto rounded-[12px] overflow-hidden max-h-[230px]"
      sx={{
        ".slick-prev:before": {
          // visibility: "hidden",
        },
        ".slick-next:before": {
          // visibility: "hidden",
        },
      }}
    >
      <Carousel
        indicators={true}
        indicatorContainerProps={{ style: carouselStyles.indicator }}
      >
        {banners.map((banner) => (
          <img
            key={banner.id}
            src={banner.main_image}
            alt=""
            style={carouselStyles.img}
            className="carousel"
          />
        ))}
      </Carousel>
      {/* <Carousel
        indicators={true}
        navButtonsAlwaysVisible={true}
        indicatorContainerProps={{ style: carouselStyles.indicator }}
        indicatorIconButtonProps={{ style: carouselStyles.dot }}
        activeIndicatorIconButtonProps={{ style: carouselStyles.dotActive }}
      >
        <Slider {...bannerSettings}>
        <Box
          className="!flex bg-no-repeat bg-cover w-full h-[230px]"
          sx={{
            backgroundImage: "url('assets/images/pages/dashboard/banner.jpg')",
          }}
        >
          <Box className="w-full md:w-[75%] h-full rounded-[12px] overflow-hidden !flex flex-col items-start justify-center pl-[32px]">
            <Typography className="text-white text-[12px] md:text-[12px] font-700 uppercase">{i18next.t("Get_Reward")}</Typography>
            <Typography className="text-white text-[20px] md:text-[36px] font-700 uppercase">
              {i18next.t("Sign_up_and_get_a_bonus")}
            </Typography>
            <Typography className="text-white text-[12px] leading-[24px] font-thin uppercase max-w-[90%] md:max-w-[60%]">
              {i18next.t("Dive_into_our_wide_range_of_in_house_games_live_casino_and_statsto_experience.")}
            </Typography>
            <button
              className="py-[2px] md:py-[8px] px-[8px] md:px-[16px] font-700 text-[11px] md:text-[16px] capitalize rounded-[4px] md:rounded-[8px] mt-[24px]"
              style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText }}
            >
              {i18next.t("Sign_Up_&_Play")}
            </button>
          </Box>
          <Box className="w-[25%] h-full rounded-[12px] hidden md:flex flex-col items-center justify-center uppercase">
            <Typography className="text-white text-[16px]">{i18next.t("WELCOME_BONUS")}</Typography>
            <Typography className="text-white text-[36px] font-700">100%</Typography>
            <Typography className="text-white text-[12px]">{i18next.t("UP_TO")}</Typography>
            <Typography className="text-white text-[36px] font-700">$ 100</Typography>
            <Typography className="text-white text-[16px] mt-[12px]">{i18next.t("FOR_SPORTS_BETTING")}</Typography>
          </Box>
        </Box>
        </Slider>
      </Carousel> */}
    </Box>
  );
}

export default DashboardBanner;
