import { grpc } from '@improbable-eng/grpc-web';
import { SportsbookServiceClient } from './proto/service_grpc_web_pb';
import { LiveOddsRequest, ListPrematchRequest, LiveScoreRequest } from './proto/service_pb';
import toast from 'react-hot-toast';
import { CountdownToast } from 'app/shared-components/CountdownToast';

const grpcUrl = process.env.REACT_APP_GRPC_PROXY_URL || 'http://localhost:8080';
const client = new SportsbookServiceClient(grpcUrl, null, null);

let toastTimer = null;

export const sendLiveOdds = (onDataCallback, onEndCallback) => {
  const request = new LiveOddsRequest();

  const stream = client.sendLiveData(request, null);

  stream.on('data', response => {
    if (toastTimer) {
      clearInterval(toastTimer);
      toastTimer = null;
    }
    const data = response.toObject(); // Convert response to plain object
    onDataCallback(data);
  });

  stream.on('end', (status, statusMessage) => {
    onEndCallback(status, statusMessage);
  });

  stream.on('status', status => {
    if (status.code !== grpc.Code.OK) {
      console.log('Error code: ', status.code, 'Details: ', status.details);

      if (toastTimer) return;
      toast.error(
        <CountdownToast countdownTime={15} toastId={0} />,
        { duration: 10500, id: "countdown" } // Set duration to Infinity so we can manually dismiss it
      );
      toastTimer = setInterval(() => {
        toast.error(
          <CountdownToast countdownTime={15} toastId={0} />,
          { duration: 10500, id: "countdown" } // Set duration to Infinity so we can manually dismiss it
        );
      }, 45000);
    }
  });

  return stream;
};

export const listPrematch = (callback) => {
  const request = new ListPrematchRequest();

  client.listPrematch(request, null, (err, response) => {
    if (err) {
      console.error('Error listing prematches:', err);
      callback(err, null);
      return;
    }

    const data = response.toObject().dataList; // Convert response to plain object
    callback(null, data);
  });
};

export const sendLiveScore = (onDataCallback, onEndCallback) => {
  const request = new LiveScoreRequest();

  const stream = client.sendLiveScore(request, null);

  stream.on('data', response => {
    const data = response.toObject(); // Convert response to plain object
    onDataCallback(data);
  });

  stream.on('end', (status, statusMessage) => {
    onEndCallback(status, statusMessage);
  });

  stream.on('status', status => {
    if (status.code !== grpc.Code.OK) {
      console.log('Error code: ', status.code, 'Details: ', status.details);
    }
  });

  return stream;
};
