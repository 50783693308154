import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import i18n from 'src/i18n';
import { setDefaultSettings } from './fuse/settingsSlice';
import settingsConfig from 'app/configs/settingsConfig';
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getSystemSettings = createAsyncThunk("sportsApp/getSettings", async (data) => {
  const response = await axios.get(`${api_domain}system/getSettings`);
  return response.data.system;
});

export const changeLanguage = (languageId) => (dispatch, getState) => {
  const { direction } = getState().fuse.settings.defaults;

  const newLangDirection = i18n.dir(languageId);

  /*
    If necessary, change theme direction
     */
  if (newLangDirection !== direction) {
    dispatch(setDefaultSettings({ direction: newLangDirection }));
  }

  /*
    Change Language
     */
  return i18n.changeLanguage(languageId).then(() => {
    dispatch(i18nSlice.actions.languageChanged(languageId));
  });
};

const i18nSlice = createSlice({
  name: 'i18n',
  initialState: {
    language: i18n.options.lng,
    languages: settingsConfig.languages,
    timezone: "GMT(+3)",
    user_currencies: ["USD", "TRY"],
    date_format: "DD/MM/YYYY",
    price_format: "decimal",
  },
  reducers: {
    languageChanged: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSystemSettings.fulfilled, (state, action) => {
      const languagesMap = {
        1: 'tr',
        2: 'en',
        3: 'ar',
        4: 'es',
        5: 'pt',
        6: 'ru',
        7: 'fr',
        8: 'de',
        9: 'zh',
        10: 'ja',
        11: 'it',
        12: 'ko'
      };

      const parsedLanguages = action.payload.languages
        .slice(1, -1) // Remove the curly braces
        .split(',') // Split by comma
        .map(langId => settingsConfig.languages.find(lang => lang.id === languagesMap[parseInt(langId)])); // Map to language objects

      state.timezone = action.payload.timezone;
      state.user_currencies = action.payload.user_currencies.slice(1, -1).split(',');
      state.languages = parsedLanguages;
      state.date_format = action.payload.date_format;
      state.price_format = action.payload.price_format;
    });
  },
});

export const selectCurrentLanguageId = ({ i18n: _i18n }) => _i18n.language;

export const selectLanguages = ({ i18n: _i18n }) => _i18n.languages;
export const selectCurrencies = ({ i18n: _i18n }) => _i18n.user_currencies;

export const selectCurrentLanguageDirection = createSelector([selectCurrentLanguageId], (id) => {
  return i18n.dir(id);
});

export const selectCurrentLanguage = createSelector(
  [selectCurrentLanguageId, selectLanguages],
  (id, languages) => {
    return languages.find((lng) => lng.id === id);
  }
);

export default i18nSlice.reducer;
