import React from "react";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginModalVisible, setLoginModalVisible, setMobileSignupOpen, setMoblieLoginOpen, setSignupModalVisible } from "../../store/uiSlice";
import { useCustomAuth } from "src/app/hooks/useAuth";
import {
  addFavGameSync,
  addFavoriteGames,
  removeFavGameSync,
  removeFavoriteGames,
  selectFavGames,
} from "../../store/favSlice";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useNavigate } from "react-router-dom";

export const CasinoCard = (props) => {
  const { isFavorite = false, rootPath, item } = props;
  const { id, id_hash, title, people, image, image_square, } = item;
  const [hovered, setHovered] = useState(false);
  const authenticated = useCustomAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const isMd = useScreenMd();

  const favGames = useSelector(selectFavGames);
  const isFavoriteGame = isFavorite || favGames.some((f) => f.id === id);

  const [imageError, setImageError] = useState(false);

  const handleLoginClick = () => {
    if (isMd) {
      dispatch(setLoginModalVisible(true));
    } else {
      dispatch(setMoblieLoginOpen(true));
    }
  };

  const handleSignupClick = () => {
    if (isMd) {
      dispatch(setSignupModalVisible(true));
    } else {
      dispatch(setMobileSignupOpen(true));
    }
  };

  const handleAddFavoriteClick = async () => {
    if (isFavoriteGame) {
      dispatch(removeFavoriteGames(id));
      dispatch(removeFavGameSync(id));
    } else {
      dispatch(addFavoriteGames(id));
      dispatch(addFavGameSync(props));
    }
  };

  const handlePlayDemoClick = () => {
    navigate(`/${rootPath}/demo/${id_hash}`);
  }

  const handlePlayClick = () => {
    navigate(`/${rootPath}/game/${id_hash}`);
  }

  return (
    <Box
      aria-label="casinoCard"
      className="w-full h-full"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box className="relative w-full h-full rounded-[6px] overflow-hidden bg-no-repeat bg-cover bg-[#62b3ff40]">
        {imageError && (
          <img
            src={"/assets/images/casino-default.png"}
            alt=""
            data-hovered={hovered}
            className="w-full h-full rounded-[6px] absolute left-0 top-0 z-0 select-none pointer-events-none data-[hovered=true]:scale-[1.2] transition-all duration-200"
          />
        )}
        {!imageError && (
          <img
            // src={bigImage ? image_square : image}
            src={true ? image_square : image}
            alt=""
            data-hovered={hovered}
            className="w-full h-full rounded-[6px] absolute left-0 top-0 z-0 select-none pointer-events-none data-[hovered=true]:scale-[1.2] transition-all duration-200 object-cover object-center"
            onError={() => setImageError(true)}
          />
        )}
        <Box
          className="bg-[#00000080] rounded-full py-[4px] px-[16px] flex gap-[4px] items-center absolute right-[10px] top-[10px] text-white transition-all duration-200"
          sx={{ top: hovered ? "-30px !important" : undefined }}
        >
          <Box>P</Box>
          <Box>{people}</Box>
        </Box>
        <Box
          className="z-[200] w-full h-full absolute left-0 top-0 bg-[#000000aa] opacity-0 data-[hovered=true]:opacity-100"
          data-hovered={hovered}
        ></Box>
        <Box
          className="z-[300] w-full h-full absolute left-0 top-[100%] data-[hovered=true]:top-0 flex flex-col gap-[8px] justify-center items-center transition-all duration-300 text-white"
          data-hovered={hovered}
        >
          {!authenticated && (
            <>
              <Typography className="text-[14px] font-bold text-[#3e4049] max-w-full mt-[8px]">{title}</Typography>
              <button className="text-green-600 underline" onClick={handleLoginClick}>
                Login
              </button>
              <Typography>or</Typography>
              <button className="bg-[#e07d4f] py-[2px] px-[8px] rounded-full uppercase" onClick={handleSignupClick}>
                SIGN UP
              </button>
              {rootPath !== "live-casino" && (
                <button
                  className="border border-white py-[2px] px-[8px] rounded-full uppercase"
                  onClick={handlePlayDemoClick}
                >
                  DEMO
                </button>
              )}
            </>
          )}
          {authenticated && (
            <>
              <button
                className="border border-white py-[2px] px-[8px] rounded-full uppercase"
                onClick={handlePlayClick}
              >
                Play
              </button>
              <button className="text-green-600 underline" onClick={handleAddFavoriteClick}>
                {isFavoriteGame ? "Remove from favorite" : "Add to favorite"}
              </button>
              {rootPath !== "live-casino" && (
                <button
                  className="border border-white py-[2px] px-[8px] rounded-full uppercase"
                  onClick={handlePlayDemoClick}
                >
                  DEMO
                </button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
