import { Box, Collapse, FormControlLabel, Switch, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { HorizontalScroll } from "./HorizontalScroll";
import { useModal } from "../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { getCashierModalTab, setCashierModalTab, getBonusList, getPaymentURL, getWithdraw, getBankDepositPaymentURL, getDepositPaparaURL, getDepositMaksiURL } from "../main/store/uiSlice";
import { selectUser } from "app/store/userSlice";
import BankTransferForm from './BankTransferForm';
import PaparaForm from './PaparaForm';
import PayfixForm from './PayfixForm';
import { BonusItem, bonusItemsData } from "./LoginSignUpButtons/BonusItem";
import { useEffect } from "react";

const buttons = [
  { id: "all", label: "All" },
  { id: "recommeneded", label: "Recommended" },
  { id: "bank-transfer", label: "Bank Transfer" },
  // { id: "credit-card", label: "Credit Card" },
  { id: "cryptocurrency", label: "Cryptocurrency" },
];

const methods = [
  {
    id: "recommeneded",
    title: "Recommended",
    items: [
      { id: "bank-tranfper", title: "Bank Transfer", logo: "assets/images/payments/bank.svg" },
      // { id: "rapid-transfer", title: "Rapid Transfer", logo: "assets/images/payments/rapid.svg" },
      // { id: "credit-card", title: "Credit Card", logo: "assets/images/payments/creditcard.svg" },
      // { id: "revolut", title: "Revolut", logo: "assets/images/payments/revolut.svg" },
      // { id: "skrill", title: "Skrill", logo: "assets/images/payments/skrill.svg" },
    ],
  },
  {
    id: "bank-transfer",
    title: "Bank Transfers",
    items: [
      { id: "bank-transfer", title: "Bank Transfer", logo: "assets/images/payments/bank.svg" },
      { id: "papara", title: "Papara", logo: "assets/images/payments/paparamaksi-new.png" },
      { id: "payfix", title: "Payfix", logo: "assets/images/payments/payfixmaksi-new.png" },
      // { id: "rapid-transfer", title: "Rapid Transfer", logo: "assets/images/payments/rapid.svg" },
    ],
  },
  // {
  //   id: "credit-card",
  //   title: "Credit card",
  //   items: [
  //     { id: "credit-card", title: "Credit Card", logo: "assets/images/payments/creditcard.svg" },
  //     { id: "revolut", title: "Revolut", logo: "assets/images/payments/revolut.svg" },
  //     { id: "bp-wallet", title: "BP Walle", logo: "assets/images/payments/bpwallet.svg" },
  //     { id: "webpayz", title: "WebPayz Credit Card", logo: "assets/images/payments/webpayzcreditcards.svg" },
  //   ],
  // },
  {
    id: "cryptocurrency",
    title: "Cryptocurrency",
    items: [
      { id: "BTC", title: "Bitcoin BTC", logo: "https://www.coinpayments.net/images/coins/BTC.png" },
      { id: "BTC.BEP2", title: "Bitcoin/BTCB Token (BC Chain)", logo: "https://www.coinpayments.net/images/coins/chained/BTC.BNB.png" },
      { id: "BTC.LN", title: "Bitcoin (Lightning Network)", logo: "https://www.coinpayments.net/images/coins/BTCLN.png" },
      { id: "BCH", title: "Bitcoin Cash", logo: "https://www.coinpayments.net/images/coins/BCH.png" },
      { id: "BNB", title: "BNB Coin (Mainnet)", logo: "https://www.coinpayments.net/images/coins/BNB.png" },
      { id: "DOGE", title: "Dogecoin", logo: "https://www.coinpayments.net/images/coins/DOGE.png" },
      { id: "ETC", title: "Ether Classic", logo: "https://www.coinpayments.net/images/coins/ETC.png" },
      { id: "ETH", title: "Ether", logo: "https://www.coinpayments.net/images/coins/ETH.png" },
      { id: "FIRO", title: "Firo", logo: "https://www.coinpayments.net/images/coins/FIRO.png" },
      { id: "FTN", title: "Fasttoken (ERC20)", logo: "https://www.coinpayments.net/images/coins/FTN.png" },
      { id: "FTN.BAHAMUT", title: "Fasttoken (Bahamut)", logo: "https://www.coinpayments.net/images/coins/FTN.png" },
      { id: "SHIB", title: "SHIBA INU (ERC20)", logo: "https://www.coinpayments.net/images/coins/chained/SHIB.ETH.png" },
      { id: "SHIB.BEP20", title: "SHIBA INU (BSC Chain)", logo: "https://www.coinpayments.net/images/coins/chained/SHIB.BNBBSC.png" },
      { id: "SOL", title: "Solana", logo: "https://www.coinpayments.net/images/coins/SOL.png" },
      { id: "LTC", title: "Litecoin", logo: "https://www.coinpayments.net/images/coins/LTC.png" },
      { id: "TRX", title: "TRON", logo: "https://www.coinpayments.net/images/coins/TRX.png" },
      { id: "TUSD", title: "TrueUSD", logo: "https://www.coinpayments.net/images/coins/chained/TUSD.ETH.png" },
      { id: "TUSD.BEP20", title: "TrueUSD (BSC Chain)", logo: "https://www.coinpayments.net/images/coins/chained/TUSD.BNBBSC.png" },
      { id: "TUSD.PRC20", title: "TrueUSD (Polygon)", logo: "https://www.coinpayments.net/images/coins/chained/TUSD.MATICPOLY.png" },
      { id: "TUSD.TRC20", title: "TrueUSD (Tron/TRC20)", logo: "https://www.coinpayments.net/images/coins/chained/TUSD.TRX.png" },
      { id: "USDC", title: "USD Coin (ERC20)", logo: "https://www.coinpayments.net/images/coins/chained/USDC.ETH.png" },
      { id: "USDC.BEP20", title: "USD Coin (BSC Chain)", logo: "https://www.coinpayments.net/images/coins/chained/USDC.BNBBSC.png" },
      { id: "USDC.SOL", title: "USD Coin (Solana)", logo: "https://www.coinpayments.net/images/coins/USDC.png" },
      { id: "USDT.BEP2", title: "Tether USD (BC Chain)", logo: "https://www.coinpayments.net/images/coins/chained/USDT.BNB.png" },
      { id: "USDT.BEP20", title: "Tether USD (BSC Chain)", logo: "https://www.coinpayments.net/images/coins/chained/USDT.BNBBSC.png" },
      { id: "USDT.ERC20", title: "Tether USD (ERC20)", logo: "https://www.coinpayments.net/images/coins/chained/USDT.ETH.png" },
      { id: "USDT.PRC20", title: "Tether USD (Polygon/MATIC)", logo: "https://www.coinpayments.net/images/coins/chained/USDT.MATICPOLY.png" },
      { id: "USDT.SOL", title: "Tether USD (Solana)", logo: "https://www.coinpayments.net/images/coins/USDT.png" },
      { id: "USDT.TRC20", title: "Tether USD (Tron/TRC20)", logo: "https://www.coinpayments.net/images/coins/chained/USDT.TRX.png" },
      { id: "XEM", title: "NEM", logo: "https://www.coinpayments.net/images/coins/XEM.png" },
      { id: "XMR", title: "Monero", logo: "https://www.coinpayments.net/images/coins/XMR.png" },
      { id: "XVG", title: "VERGE", logo: "https://www.coinpayments.net/images/coins/XVG.png" },
      { id: "ZEC", title: "ZCash", logo: "https://www.coinpayments.net/images/coins/ZEC.png" },
    ],
  },
];

export const MethodGroup = ({ title, items, handleSelectCoin }) => {
  const [menuVisible, setMenuVisible] = useState(true);

  const handlePayment = (item) => {
    handleSelectCoin(item)
  }

  return (
    <Box className="bg-[#456397] rounded-[12px]">
      <button
        className="flex justify-between items-center px-[12px] py-[4px] w-full"
        onClick={() => setMenuVisible((v) => !v)}
      >
        <Typography>{title}</Typography>
        {menuVisible && <KeyboardArrowUpIcon />}
        {!menuVisible && <KeyboardArrowDownIcon />}
      </button>
      <Collapse in={menuVisible}>
        <Box className="grid grid-cols-5 gap-[4px] p-[8px]">
          {items.map((item) => (
            <button onClick={() => handlePayment(item)} key={item.id} className="w-full flex flex-col items-center">
              <Box className="bg-white w-ful l h-[60px] p-[12px] rounded-[12px]">
                <img
                  src={item.logo}
                  className="w-full h-full object-contain hover:scale-105 transition-all duration-150"
                />
              </Box>
              <Typography className="text-[11px]">{item.title}</Typography>
            </button>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export const CashierModal = ({ visible, onClose }) => {
  const cashierModalTab = useSelector(getCashierModalTab);
  const dispatch = useDispatch();
  const [group, setGroup] = useState("all");
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const user = useSelector(selectUser);
  const [maksiParaMethod, setMaksiParaMethod] = useState('');
  const [bonusMode, setBonusMode] = useState("caisno");
  const [bonusList, setBonusList] = useState([])

  useEffect(() => {
    dispatch(getBonusList({trigger: 'deposit'})).then((res) => {
      const data = res.payload;
      const bonusData = data.map((d) => {return {
        id: d.id,
        title: d.name,
        subtitle: "",
      }})
      setBonusList(bonusData);
    });
  }, [])

  const setTab = (tab) => {
    dispatch(setCashierModalTab(tab));
  };

  const handleSelectCoin = (item) => {
    // if(item.id == "bank-transfer") {
    //   dispatch(getBankDepositPaymentURL({bonusMode})).then((res) => {
    //     const data = res.payload;
    //     window.open(data.url);
    //   });
    // } else if(item.id == "papara") {
    //   dispatch(getDepositMaksiURL({bonusMode})).then((res) => {
    //     const data = res.payload;
    //     window.open(data.url);
    //   });
    // } else if(item.id == "payfix") {
    //   dispatch(getDepositMaksiURL({gateway: item.id, bonusMode})).then((res) => {
    //     const data = res.payload;
    //     window.open(data.url);
    //   });
    // } 
    
    if(item.id == "bank-transfer" || item.id == "papara" || item.id == "payfix" ) {
      dispatch(getDepositMaksiURL({gateway: item.id, bonusMode})).then((res) => {
        const data = res.payload;
        window.open(data.url);
      });
    }
    else {
      dispatch(getPaymentURL({coin: item.id, amount, bonusMode})).then((res) => {
        const data = res.payload;
        window.open(data.result.checkout_url);
      });
    }
  }

  const handleMaksiparaWithdraw = (item) => {
    console.log("handleMaksiparaWithdraw", item)
    setMaksiParaMethod(item.id)
  }

  const handleWithdraw = (item) => {
    dispatch(getWithdraw({coin: item.id, amount, address})).then((res) => {
      
    });
  }

  useModal(visible);

  return (
    <>
      <Box
        className="fixed w-screen h-[calc(var(--app-height))] left-0 top-0 invisible data-[visible=true]:visible opacity-0 data-[visible=true]:opacity-100 transition-all duration-150 z-[9999] bg-[#ffffff10]"
        data-visible={visible}
        onClick={onClose}
        sx={{ backdropFilter: "blur(4px)" }}
      ></Box>
      <Box
        className="hidden data-[visible=true]:block opacity-0 data-[visible=true]:opacity-100 transition-all duration-150 fixed top-0 left-1/2 -translate-x-1/2 w-full max-w-[600px] sm:mt-[50px] z-[10000] bg-[#0d1d34] p-[24px] sm:rounded-[12px] shadow-lg text-white"
        data-visible={visible}
      >
        <button className="absolute right-[4px] top-[4px] sm:-right-[40px] sm:-top-[4px]" onClick={onClose}>
          <CloseIcon className="text-white" fontSize="large" />
        </button>

        <Box className="flex flex-col gap-[12px] h-[calc(var(--app-height))] sm:max-h-[calc(var(--app-height)-100px)] overflow-y-auto">
          <Typography className="text-[24px] font-bold text-center">Cashier</Typography>
          <Box className="flex rounded-[12px] overflow-hidden bg-[#1a3769]">
            <button
              className="flex justify-center items-center py-[8px] hover:bg-[#456397] data-[selected=true]:bg-green data-[selected=true]:text-white w-full"
              data-selected={cashierModalTab === "deposit"}
              onClick={() => setTab("deposit")}
            >
              Deposit
            </button>
            <button
              className="flex justify-center items-center py-[8px] hover:bg-[#456397] data-[selected=true]:bg-green w-full"
              data-selected={cashierModalTab === "withdrawal"}
              onClick={() => setTab("withdrawal")}
            >
              Withdrawal
            </button>
          </Box>
          {cashierModalTab === "deposit" && (
            <>
              <Box className="p-[12px]">
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      sx={{
                        ".MuiSwitch-track": {
                          backgroundColor: "#ffffff80 !important",
                        },
                        ".Mui-checked+.MuiSwitch-track": {
                          backgroundColor: "green !important",
                        },
                      }}
                    />
                  }
                  label="My Bonuses"
                />
                <Box className="flex gap-[4px] bg-white">
                  {bonusList.length > 0 && bonusList.map((item) => (
                    <Box key={item.id} className="border-t-[2px] border-t-[#F5F5F5] first:border-t-0 text-gray-800">
                      <BonusItem
                        {...item}
                        checked={item.id === bonusMode}
                        onClick={() => {
                          setBonusMode(item.id);
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box>Payment method</Box>
              <HorizontalScroll>
                <Box className="flex gap-[4px]">
                  {buttons.map((b) => (
                    <button
                      key={b.id}
                      className="data-[selected=true]:bg-[green] text-[#ddd] data-[selected=true]:text-white rounded-[5px] bg-[#555] px-[12px] py-[4px]"
                      data-selected={b.id === group}
                      onClick={() => setGroup(b.id)}
                      sx={{
                        "&:hover": {
                          backgroundColor: b.id === group ? "#00FF00" : "#324A5F",
                        },
                      }}
                    >
                      {b.label}
                    </button>
                  ))}
                </Box>
              </HorizontalScroll>
              <Box className="flex flex-col gap-[9px] max-h-[calc(var(--app-height)-400px)] overflow-y-auto">
                {methods
                  .filter((m) => group === "all" || m.id === group)
                  .map((method) => (
                    <MethodGroup key={method.id} {...method} handleSelectCoin={handleSelectCoin} />
                  ))}
              </Box>
              <Typography className="flex"> Amount ({user.data.currency}): </Typography>
              <input
                className="border text-gray-800 py-[12px] px-[24px] rounded-full"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </>
          )}
          {cashierModalTab === "withdrawal" && (
            <Box className="min-h-[300px] justify-center">
              
              <Box className="flex flex-col gap-[9px] h-[500px] overflow-y-auto">
                {methods
                  .filter((m) => m.id === "cryptocurrency" || m.id === "bank-transfer")
                  .map((method) => 
                    {
                      if(method.id === "cryptocurrency")
                        return <MethodGroup key={method.id} {...method} handleSelectCoin={handleWithdraw} />
                      else 
                       return <MethodGroup key={method.id} {...method} handleSelectCoin={handleMaksiparaWithdraw} />
                    }
                  )}
                  
                  <Typography className="flex"> Address </Typography>
                  <input
                    className="border text-gray-800 py-[12px] px-[24px] rounded-full"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                
                  <Typography className="flex"> Amount </Typography>
                  <input
                    className="border text-gray-800 py-[12px] px-[24px] rounded-full"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
              </Box>
              

              <Box className="bg-[#ffffff] mt-[25px]">
                {maksiParaMethod === 'bank-transfer' && <BankTransferForm />}
                {maksiParaMethod === 'papara' && <PaparaForm />}
                {maksiParaMethod === 'payfix' && <PayfixForm />}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
