

function FooterLogos() {
    return (
        <>
            <div className="footer_images">
                <ul>
                    <li>
                        <a href="#">
                            <img src="assets/images/FooterLogos/Bitcoin.svg" alt="bitcoin" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="assets/images/FooterLogos/Dogecoin.svg" alt="Dogecoin" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="assets/images/FooterLogos/eos.svg" alt="Eos" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="assets/images/FooterLogos/etherium2.svg" alt="Ethereum" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="assets/images/FooterLogos/tron2.svg" alt="Tron" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="assets/images/FooterLogos/ripple2.svg" alt="Ripple" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="assets/images/FooterLogos/banxa2.svg" alt="Banxa" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="assets/images/FooterLogos/wert2.svg" alt="Wert" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="assets/images/FooterLogos/moonpay2.svg" alt="Moonpay" />
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
}


export default FooterLogos;