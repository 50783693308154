import { Box } from "@mui/material";
import { ContentCard } from "../../component/ContentCard";
import { SelectGroup } from "../../component/InputGroups";
import { DatePicker } from "@mui/x-date-pickers";
import HistoryTable from "./HistoryTable";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { couponDatas, headCells } from "./historyData";
import { getCasinoHistory } from "../../store/historySlice";
import { getLastMondayMoreThanWeekAgo } from "src/app/utils";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

const datePickerSx = {
  "& input": { paddingY: "3px" },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ddd !important",
    borderWidth: "1px !important",
    borderRadius: "12px",
  },
  "& .MuiInputBase-root": {
    minHeight: "38px",
  },
};

const options = [
  { label: "All", value: "all" },
  { label: "Won", value: "Casino In" },
  { label: "Lost", value: "Casino Out" },
  { label: "Rollback", value: "rollback" },
];

export const CasinoHistory = () => {
  const [value, onChange] = useState(new Date());
  const [items, setItems] = useState(["s"]);
  const dispatch = useDispatch();
  const [hitories, setCasinoHistory] = useState([]);
  const [transactionType, setTransactionType] = useState(options[0])
  const [startDate, setStartDate] = useState(Date.now())
  const [endDate, setEndDate] = useState(Date.now())
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    const lastMonday = getLastMondayMoreThanWeekAgo();
    setStartDate(lastMonday);
  }, []);


  useEffect(() => {
    const startDateString = new Date(startDate).toISOString().split('T')[0] ;
    const endDateString = new Date(endDate).toISOString().split('T')[0] ;

    dispatch(getCasinoHistory({startDate: startDateString, endDate: endDateString, transactionType})).then((res) => {
      setCasinoHistory(res.payload);
      console.log("getCasinoHistory Paylod:", res.payload);
    });
  }, [transactionType, startDate, endDate]);

  return (
    <Box aria-label="Transaction">
      <ContentCard title={i18n.t("Casino_History")} icon="assets/images/pages/settings/history.svg">
        <Box className="bg-white rounded-b-[12px] p-[12px] min-h-[400px]">
          <Box className="flex flex-col gap-[12px] max-w-[1000px] mx-auto relative z-[100]">
            <Box className="flex flex-col md:flex-row justify-between items-center gap-[12px]">
              <Box className="w-full flex flex-col md:flex-row gap-[12px]">
                <SelectGroup options={options} value={transactionType} onChange={(v) => setTransactionType(v)}/>
                <DatePicker value={startDate} sx={datePickerSx} onChange={(date) => setStartDate(date)} />
                <DatePicker value={endDate} sx={datePickerSx} onChange={(date) => setEndDate(date)}/>
              </Box>
              <button className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce]">
                {i18n.t("Get")}
              </button>
            </Box>
            {items.length === 0 && (
              <Box className="flex justify-center mt-[100px] text-[24px] text-[#00000080]">{i18n.t('No_History')}</Box>
            )}
          </Box>
          <Box height="12px"></Box>
          <HistoryTable headCells={headCells} rows={hitories} />
        </Box>
      </ContentCard>
    </Box>
  );
};
