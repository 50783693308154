import React from "react";
import { Typography, Box, Divider, IconButton, useTheme } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useState, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavLeague,
  addFavoriteLeague,
  removeFavLeague,
  removeFavoriteLeague,
  selectFavLeagues,
} from "../../store/favSlice";
import { useRef } from "react";
import { useScreenSm } from "src/app/hooks/useScreens";
import { flagPath } from "src/app/utils";
import { selectLeagueList } from "../../store/tournamentSlice";
import { useMemo } from "react";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";


export const sportsEventHeaderItems = {
  79: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "1X", type: "double_chance", position: "1X", market_id: 3, reference_id: "1X2 Double Chance;1X" },
    { title: "12", type: "double_chance", position: "12", market_id: 3, reference_id: "1X2 Double Chance;12" },
    { title: "2X", type: "double_chance", position: "2X", market_id: 3, reference_id: "1X2 Double Chance;X2" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  76: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "1X", type: "double_chance", position: "1X", market_id: 3, reference_id: "1X2 Double Chance;1X" },
    { title: "12", type: "double_chance", position: "12", market_id: 3, reference_id: "1X2 Double Chance;12" },
    { title: "2X", type: "double_chance", position: "2X", market_id: 3, reference_id: "1X2 Double Chance;X2" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  "Aussie-rules": [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  78: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  85: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  42: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  // eSports: [
  //   { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
  //   { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  //   { title: "U", type: "total", position: "U", market_id: 11, reference_id: ";U" },
  //   { title: "TOTAL", type: "total", market_id: 11, reference_id: "2" },
  //   { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
  //   { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
  //   { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
  //   { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  // ],
  39: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  83: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    // incl_1
    // incl_2
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  MMA: [
    // 1
    // 2
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  84: [
    // 1
    // 2
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  74: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  77: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  80: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
    { title: "1X", type: "double_chance", position: "1X", market_id: 3, reference_id: "1X2 Double Chance;1X" },
    { title: "12", type: "double_chance", position: "12", market_id: 3, reference_id: "1X2 Double Chance;12" },
    { title: "2X", type: "double_chance", position: "2X", market_id: 3, reference_id: "1X2 Double Chance;X2" },
  ],
  40: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  82: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  Waterpolo: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  54: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  Billiard: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
  ],
  23: [
    // 1
    // 2
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
  ],
  24: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  "Field hockey": [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  Biathlon: [
    // no list, outcome is null
  ],
  40: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  Badminton: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
  ],
  32: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
  ],
  55: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  "Basketball 3x3": [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  73: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
  ],
  21: [
    { title: "1", type: "moneyline", position: "1", market_id: 2, reference_id: "1X2;1" },
    { title: "X", type: "moneyline", position: "X", market_id: 2, reference_id: "1X2;X" },
    { title: "2", type: "moneyline", position: "2", market_id: 2, reference_id: "1X2;2" },
    { title: "H1", type: "handicap", position: "H1", market_id: 7, reference_id: ";1" },
    { title: "Handicap", type: "handicap", market_id: 7, reference_id: "0.5" },
    { title: "H2", type: "handicap", position: "H2", market_id: 7, reference_id: ";2" },
    { title: "Under_symbol", type: "total", position: "U", market_id: 11, reference_id: ";U" },
    { title: "Total", type: "total", market_id: 11, reference_id: "2" },
    { title: "Over_symbol", type: "total", position: "O", market_id: 11, reference_id: ";O" },
  ]
};

export const sportsEventHeaderItemMinWidths = [0, 0, 0, 750, 750, 750, 850, 850, 850, 1050, 1050, 1050];

export const checkReferenceIdExists = (referenceId, sportId) => {
  if (referenceId.includes("Total") || referenceId.includes("Handicap")) 
    return true;
  if (sportsEventHeaderItems[sportId]) {
    const exists = sportsEventHeaderItems[sportId].some(item => item.reference_id === referenceId);
    return exists
  }
  return false;
};

const SportsOddsHeader = ({ league, sport_name }) => {
  const dispatch = useDispatch();
  const favLeagues = useSelector(selectFavLeagues);
  const leagueList = useSelector(selectLeagueList);
  const [width, setWidth] = useState(0);
  const headerRef = useRef(null);
  const isSm = useScreenSm();
  const theme = useTheme();
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const isFavorite = useMemo(() => {
    if (!league) return false;
    const selectedLeague = favLeagues.find((e) => e.id == league.id);
    return !!selectedLeague;
  }, [favLeagues, league]);

  const handleFavoriteToggle = () => {
    if (!isFavorite) {
      dispatch(addFavoriteLeague(league));
    } else {
      dispatch(removeFavoriteLeague(league.id));
    }
  };

  const onResize = () => {
    const header = headerRef.current;
    if (!header) return;
    const { width } = header.getBoundingClientRect();
    setWidth(width);
  };

  useEffect(() => {
    const header = headerRef.current;
    if (!header) return;

    const observer = new ResizeObserver(onResize);
    observer.observe(header);

    return () => observer.disconnect();
  }, []);

  const leagueInList = useMemo(() => {
    if (!league) return null;
    return leagueList.find((lea) => lea.id === league.id);
  }, [league, leagueList]);

  if (!league) return null;

  return (
    <Box
      aria-label="SportsOddHeader"
      className="w-full flex items-center pl-[10px] pr-[60px] py-[7px] mb-[10px] rounded-[4px]"
      bgcolor={theme.palette.background.default}
      ref={headerRef}
    >
      <Box className="flex items-center min-w-[260px]">
        <IconButton onClick={handleFavoriteToggle} sx={{ marginRight: "5px", p: 0 }}>
          {isFavorite ? <StarIcon sx={{ color: "orange" }} /> : <StarBorderIcon />}
        </IconButton>
        <img
          className="w-[20px] h-[20px] rounded-full mr-[10px] object-cover"
          src={leagueInList ? flagPath(leagueInList.sport_flag) : ""}
          alt=""
        />
        <img
          className="w-[20px] h-[20px] rounded-full mr-[10px] object-cover"
          src={flagPath(league.flag || league.tournament_flag || league.country_flag || league.country_flag)}
          alt=""
        />
        <Typography
          className="sm:max-w-[200px] sm:truncate"
          sx={{
            textAlign: "left",
            marginRight: "auto",
            fontSize: "12px",
          }}
        >
          {" "}
          {league.name || league.tournament_name}{" "}
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box className="flex items-center justify-between w-full">
        {isSm &&
          sportsEventHeaderItems[league.sport_id]
            ?.filter((_, index) => sportsEventHeaderItemMinWidths[index] < width)
            .map((item, index) => (
              <Box key={index} className="w-full flex justify-center items-center gap-[4px]">
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {i18n.t(item.title)}
                </Typography>
                <Typography className="text-[#aaa] font-black italic">{item.incl && "i"}</Typography>
              </Box>
            ))}
      </Box>
    </Box>
  );
};

export default SportsOddsHeader;
