import { Box, Typography } from "@mui/material"
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CardBox } from "src/app/main/dashboard/components/CardBox"
import { getOutcomeByReference } from "src/app/main/store/prematchSlice";
import { selectDetailedEvent } from "src/app/main/store/uiSlice";

export const TrackerContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [trackUrl, setTrackUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasScout, setHasScount] = useState(false);
  const detailedEvent =  useSelector(selectDetailedEvent);

  useEffect(() => {
    setLoading(true);
    dispatch(getOutcomeByReference(id)).then((res) => {
      setLoading(false);
      const event = res.payload;
      const { sport_ref_id, reference_id, has_scout } = event.sportEvent;
      setHasScount(has_scout);
      if (has_scout) {
        setTrackUrl(`https://tracker.betapi.win/en/${sport_ref_id}/${reference_id}`);
      }
    });
  }, [id]);
  
  if (!loading && !hasScout) return null;

  return (
    <Box className="bg-white rounded-[12px] overflow-y-auto w-full aspect-video">
      {loading && (
        <Box className="flex justify-center pt-[48px]">
          <Box className="text-black">Loading</Box>
        </Box>
      )}
      {!loading && hasScout && (
        <iframe width="100%" height="100%" src={trackUrl}>
        </iframe>
      )}
      {!loading && !hasScout && (
        <Box className="flex justify-center pt-[48px]">
          <Box className="text-black">Not support the live stream</Box>
        </Box>
      )}
    </Box>
  )
}