import { Box, Collapse, IconButton, Stack, Typography, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { dateToText } from "src/app/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBetType,
  selectUserOddsFormat,
} from "src/app/main/store/uiSlice";
import { selectMarketDic } from "src/app/main/store/prematchSlice";
import {
  addBanker,
  removeBanker,
  removeTicket,
  selectBankers,
  updateStakeAmount,
} from "src/app/main/store/ticketSlice";
import { InputGroup } from "src/app/main/settings/component/InputGroups";
import { StateAmountInput } from "./StateAmountInput";
import React, { useState, useEffect } from "react";
import { convertOdds } from "src/app/utils";
import { selectSportsList } from "src/app/main/store/tournamentSlice";
import { useRef } from "react";
import LockIcon from "@mui/icons-material/Lock";

const BankerButton = (props) => {
  const { selected, onChange } = props;

  return (
    <button
      className="border border-[#ddd] text-[#ddd] rounded-[2px] py-[2px] px-[6px] uppercase data-[selected=true]:text-white data-[selected=true]:bg-[#ccc]"
      data-selected={selected}
      onClick={() => onChange && onChange(!selected)}
    >
      B
    </button>
  );
};

export const TicketCard = (props) => {
  const { ticket, amountEditing, onAmountEdit, onAmountOkay } = props;
  const {
    id,
    date,
    homeTeam,
    awayTeam,
    odds,
    stake_amount,
    outcome_reference_id,
    trend: ticketTrend,
    sport_name,
    sport_id,
    game_type,
    active,
    market_id,
  } = ticket;
  const [oddsPrice, setOddsPrice] = useState(odds);
  const dispatch = useDispatch();
  const betType = useSelector(selectBetType);
  const bankers = useSelector(selectBankers);
  const marketDic = useSelector(selectMarketDic);
  const [detail, setDetail] = useState("");
  const inputId = `stake-amount-${id}-${detail}`;
  const inputElement = document.getElementById(inputId);
  const oddsFormat = useSelector(selectUserOddsFormat);
  const sportList = useSelector(selectSportsList);
  const theme = useTheme();

  const timerRef = useRef();
  const [trend, setTrend] = useState("");

  const sportImagePath =
    sportList.find((s) => s.name === sport_name)?.icon ||
    sportList.find((s) => s.id === sport_id)?.icon ||
    "";

  useEffect(() => {
    setOddsPrice(convertOdds(odds, oddsFormat));
  }, [oddsFormat, odds]);

  useEffect(() => {
    setTrend(ticketTrend);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setTrend("");
    }, 3000);
    return () => clearTimeout(timerRef.current);
  }, [ticketTrend]);

  const handleDeleteTicket = (id, outcome_reference_id) => {
    const data = {
      id,
      outcome_reference_id,
    };
    dispatch(removeTicket(data));
  };

  useEffect(() => {
    if (marketDic) {
      let result = "";
      let name = outcome_reference_id.split(";")[1];
      if (name == "1") {
        name = "Home";
      } else if (name == "2") {
        name = "Away";
      }
      if (name == "U") {
        name = "Under";
      }
      if (name == "O") {
        name = "Over";
      }
      if(outcome_reference_id.includes("Handicap") && !outcome_reference_id.includes(",0")) {
        if(outcome_reference_id.includes("-")) {
          if(name == "Home") name = "-" + name
          else if(name == "Away") name = "+" + name
        } else {
          if(name == "Home") name = "+" + name
          else if(name == "Away") name = "-" + name
        }
      }
      
      let prefix = marketDic && marketDic[market_id] ? marketDic[market_id].split(";")[0] : outcome_reference_id.split(";")[0];

      const parts = outcome_reference_id.split(";")[0].split(",");
      parts[0] = prefix;
      if (outcome_reference_id.includes("%P")) {
        const cleanedParts = parts.map((part) => part.replace("%P", " "));
        prefix = cleanedParts[0] + outcome_reference_id.split(';')[0].split(',')[1];
        // prefix = formatPrefix(prefix);
      } else if (outcome_reference_id.split(";")[0].includes(",")) {
        prefix = parts[0];
        name = name + " " + parts[1];
      } else if (outcome_reference_id.split(";")[0].includes("%")) {
        prefix = prefix.replace("%1", "Home").replace("%2", "Away");
      }

      setDetail(prefix + ": " + name)
    }
  }, [marketDic]);

  const setInputValue = (value) => {
    dispatch(
      updateStakeAmount({ id, outcome_reference_id, stake_amount: value })
    );
  };

  const handleSingleBetAmountChange = (id, outcome_reference_id, amount) => {
    setInputValue(amount);
  };

  const handleIncrease = (value) => {
    setInputValue(+(stake_amount ?? 0) + +value);
  };

  const handleDigitClick = (digit) => {
    const inputValue = stake_amount ?? "";
    const cursorPos = inputElement.selectionStart;
    const leftSlice = inputValue.toString().slice(0, cursorPos);
    const rightSlice = inputValue.toString().slice(cursorPos);
    setInputValue(`${leftSlice}${digit}${rightSlice}`);
    setTimeout(() => {
      inputElement.selectionStart = inputElement.selectionEnd = cursorPos + 1;
      inputElement.focus();
    });
  };

  const handlePointClick = () => {
    const inputValue = stake_amount ?? "";
    const cursorPos = inputElement.selectionStart;
    if (inputValue.includes(".")) {
      setTimeout(() => {
        inputElement.selectionStart = inputElement.selectionEnd = cursorPos;
        inputElement.focus();
      });
      return;
    }
    const leftSlice = inputValue.toString().slice(0, cursorPos);
    const rightSlice = inputValue.toString().slice(cursorPos);
    setInputValue(`${leftSlice}.${rightSlice}`);
    setTimeout(() => {
      inputElement.selectionStart = inputElement.selectionEnd = cursorPos + 1;
      inputElement.focus();
    });
  };

  const handleBackspaceClick = () => {
    const inputValue = stake_amount ?? "";
    const cursorPos = inputElement.selectionStart;
    const leftSlice = inputValue.toString().slice(0, cursorPos);
    const rightSlice = inputValue.toString().slice(cursorPos);
    setInputValue(`${leftSlice.slice(0, -1)}${rightSlice}`);
    setTimeout(() => {
      inputElement.selectionStart = inputElement.selectionEnd = cursorPos - 1;
      inputElement.focus();
    });
  };

  return (
    <Box
      className="p-[12px] border-b last:border-b-0 hover:bg-[#00000008] transition-all duration-100"
      color={theme.palette.text.primary}
      borderColor={theme.palette.divider}
      aria-label="TicketCard"
    >
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="4px"
        >
          <Typography
            variant="caption"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={sportImagePath}
              alt=""
              className="mr-[4px] w-auto h-[18px]"
            />
            {dateToText(date)}
            {game_type === "Live" && (
              <Box className="max-h-[24px] overflow-y-hidden flex items-center ml-[8px]">
                <img
                  src="assets/images/live.png"
                  alt=""
                  className="h-[40px] object-contain"
                />
              </Box>
            )}
          </Typography>
          <Box className="ml-auto px-[12px] py-[4px] border rounded-[4px] overflow-hidden relative">
            <Typography variant="h6" className="text-[12px]">
              {active ? oddsPrice : <LockIcon className="text-[#00000080]" />}
            </Typography>
            {trend === "up" ? (
              <Box aria-label="up-trend-box" className="up-trend-box"></Box>
            ) : trend === "down" ? (
              <Box aria-label="down-trend-box" className="down-trend-box"></Box>
            ) : null}
          </Box>
          <IconButton
            onClick={() => handleDeleteTicket(id, outcome_reference_id)}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          gap="4px"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="body2" className="text-[13px]">
              {detail}
            </Typography>
            <Typography variant="body2" className="text-[12px] mt-[4px]">
              {homeTeam} vs {awayTeam}
            </Typography>
          </Box>
          {betType === "single" && (
            <InputGroup
              containerProps={{ width: "auto !important" }}
              inputProps={{
                id: inputId,
                // type: "number",
                placeholder: "Stake",
                style: { maxWidth: "100px", textAlign: "right" },
                value: stake_amount,
                onChange: (e) =>
                  handleSingleBetAmountChange(
                    id,
                    outcome_reference_id,
                    e.target.value
                  ),
                onClick: onAmountEdit,
              }}
            />
          )}
          {betType === "system" && (
            <Box className="mr-[12px]">
              <BankerButton
                selected={bankers.some((b) => b.id == ticket.id)}
                onChange={(selected) => {
                  if (selected) {
                    dispatch(addBanker(ticket));
                  } else {
                    dispatch(removeBanker(ticket));
                  }
                }}
              />
            </Box>
          )}
        </Stack>
      </Box>

      <Collapse in={amountEditing && betType === "single"} className="mt-[8px]">
        <StateAmountInput
          onOkay={onAmountOkay}
          onIncrease={handleIncrease}
          onDigitClick={handleDigitClick}
          onPointClick={handlePointClick}
          onBackspaceClick={handleBackspaceClick}
        />
      </Collapse>
    </Box>
  );
};
