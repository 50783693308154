import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLanguage,
  selectCurrentLanguage,
  selectLanguages,
  getSystemSettings,
} from "app/store/i18nSlice";
import { Box } from "@mui/material";
import { OddsSettings } from "app/shared-components/LanguageSwitcher/OddsSettings";
import { useEffect } from "react";

function LanguageSwitcher() {
  const currentLanguage = useSelector(selectCurrentLanguage);
  const languages = useSelector(selectLanguages);
  const [menu, setMenu] = useState(null);
  const [oddsVisible, setOddsVisible] = useState(false);
  const dispatch = useDispatch();

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };
  
  const langMenuClose = () => {
    setMenu(null);
  };
  
  function handleLanguageChange(lng) {
    dispatch(changeLanguage(lng.id));
    localStorage.setItem('app-lang', lng.id);
    langMenuClose();
  }

  useEffect(() => {
    const savedLanguage = localStorage.getItem('app-lang') || 'en';
    dispatch(changeLanguage(savedLanguage));
  }, []);

  useEffect(() => {
    dispatch(getSystemSettings())
  }, [dispatch])

  return (
    <>
      <Box className="flex items-center h-40 bg-[#ffffff10] hover:bg-[#ffffff15] rounded-[12px] px-[8px] transition-all duration-150">
        <button
          onClick={langMenuClick}
          className="p-[4px] min-w-fit min-h-fit h-fit rounded-full hover:bg-[#ffffff33]"
        >
          <img
            className="min-w-[18px] w-[18px] h-[18px] rounded-full object-cover"
            src={`assets/images/flags/${currentLanguage.flag}.svg`}
            alt={currentLanguage.title}
          />
        </button>
        <img
          src="assets/images/login/line.svg"
          alt="line"
          className="mx-[8px]"
        />
        <button
          onClick={() => setOddsVisible(true)}
          className="p-[4px] min-w-fit min-h-fit h-fit rounded-full hover:bg-[#ffffff33]"
        >
          <img src="assets/images/login/setting.svg" alt="settings" />
        </button>
      </Box>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper:
            "py-8 max-h-[400px] !duration-75 bg-[#2C374F] text-white mt-[12px]",
        }}
      >
        <Box className="overflow-y-auto">
          {languages.map((lng) => (
            <MenuItem
              key={lng.id}
              onClick={() => handleLanguageChange(lng)}
              className="transition-all duration-150"
              sx={{
                "&:hover": {
                  backgroundColor: "#3C475F",
                },
              }}
            >
              <ListItemIcon className="min-w-20">
                <img
                  className="iconSize iconList mr-10 w-[24px] h-[24px] rounded-full object-cover"
                  src={`assets/images/flags/${lng.flag}.svg`}
                  alt={lng.title}
                />
              </ListItemIcon>
              <ListItemText
                primary={lng.title}
                className="truncate text-[10px]"
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              />
            </MenuItem>
          ))}
        </Box>
      </Popover>
      <OddsSettings
        visible={oddsVisible}
        onClose={() => setOddsVisible(false)}
      />
    </>
  );
}

export default LanguageSwitcher;
