import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const signUpAffiliate = createAsyncThunk("affiliateApp/getAffiliate", async (body) => {
  try {
    const response = await axios.post(`${api_domain}auth/affiliate/sign-up`, body);
    console.log('success')
    return response;
  } catch(err) {
    console.log(err.response.data,'failure')
    return err.response.data
  }
});

export const getAffiliateList = createAsyncThunk(
  "affiliateApp/affiliate/getAffiliateList",
  async (typename) => {
    const response = await axios.get(`${api_domain}affiliate/list/${typename}`);
    const data = response.data.affiliateList;
    return data === undefined ? null : data;
  }
);

const affiliateAdapter = createEntityAdapter();

export const { selectAll: selectAllaffiliate } = affiliateAdapter.getSelectors();

const affiliateSlice = createSlice({
  name: "affiliateApp/affiliate",
  initialState: affiliateAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAffiliateList.fulfilled, (state, action) => {
      affiliateAdapter.setAll(state, action.payload || []);
    })
  }
})

export default affiliateSlice.reducer;