import jwtServiceConfig from "../auth/services/jwtService/jwtServiceConfig";

export const dateToText = (date) => {
  const value = new Date(date);
  return `${value.toLocaleTimeString("en", {
    timeStyle: "short",
    hour12: false,
  })}, ${value.toLocaleDateString("en", { month: "short", day: "2-digit" })}`;
};

export const generateSystemOptions = (ticket_cnt, banker_cnt) => {
  const systemOptions = [];
  const count = ticket_cnt - banker_cnt;
  if (banker_cnt > 0)
    systemOptions.push({
      label: `${count} Picks`,
      bets: `${count} bets`,
    });
  if (count >= 3) {
    systemOptions.push({
      label: `2/${count}`,
      bets: `${(count * (count - 1)) / 2} Bets`,
    });
    if (count === 3) {
      systemOptions.push({ label: "Patent", bets: "7 Bets" });
      systemOptions.push({ label: "Trixie", bets: "4 Bets" });
    }
  }
  if (count >= 4) {
    systemOptions.push({
      label: `3/${count}`,
      bets: `${(count * (count - 1) * (count - 2)) / 6} Bets`,
    });
    if (count === 4) { 
      systemOptions.push({ label: "Yankee", bets: "11 Bets" });
      systemOptions.push({ label: "Lucky 15", bets: "15 Bets" });
    }
  }
  if (count >= 5) {
    systemOptions.push({
      label: `4/${count}`,
      bets: `${(count * (count - 1) * (count - 2) * (count - 3)) / 24} Bets`,
    });
    if (count === 5) {
      systemOptions.push({ label: "Canadian", bets: "26 Bets" });
      systemOptions.push({ label: "Lucky 31", bets: "31 Bets" });
    }
  }
  if (count >= 6) {
    systemOptions.push({
      label: `5/${count}`,
      bets: `${(count * (count - 1) * (count - 2) * (count - 3) * (count - 4)) / 120} Bets`,
    });
    if (count === 6) {
      systemOptions.push({ label: "Heinz", bets: "57 Bets" });
      systemOptions.push({ label: "Lucky 63", bets: "63 Bets" });
    }
  }
  if (count >= 7) {
    systemOptions.push({
      label: `6/${count}`,
      bets: `${(count * (count - 1) * (count - 2) * (count - 3) * (count - 4) * (count - 5)) / 720} Bets`,
    });
    if (count === 7) systemOptions.push({ label: "Super Heinz", bets: "120 Bets" });
  }
  if (count >= 8) {
    systemOptions.push({
      label: `7/${count}`,
      bets: `${
        (count * (count - 1) * (count - 2) * (count - 3) * (count - 4) * (count - 5) * (count - 6)) / 5040
      } Bets`,
    });
    if (count === 8) systemOptions.push({ label: "Goliath", bets: "247 Bets" });
  }
  if (count > 8) {
    for (let i = 2; i < count; i++) {
      systemOptions.push({
        label: `${i}/${count}`,
        bets: `${Math.round((count * (count - 1)) / 2)} Bets`,
      });
    }
  }
  if (banker_cnt > 0) {
    return systemOptions.map((op) => ({ ...op, label: `${banker_cnt} Bankers-${op.label}` }));
  }
  return systemOptions;
};

export const convertOdds = (americanOdds, format) => {
  const toDecimal = (american) => {
    return american > 0 ? american / 100 + 1 : 100 / Math.abs(american) + 1;
  };

  const toFractional = (american) => {
    return american > 0 ? `${american / 100}/1` : `1/${Math.abs(american) / 100}`;
  };

  const toMalaysian = (american) => {
    return american > 0 ? american / 100 : american / 100;
  };

  const toIndonesian = (american) => {
    return american > 0 ? american / 100 : american / 100;
  };

  const toHongKong = (american) => {
    return american > 0 ? american / 100 : 100 / Math.abs(american);
  };

  switch (format) {
    case "decimal":
      return toDecimal(americanOdds).toFixed(2);
    case "fractional":
      return toFractional(americanOdds);
    case "malaysian":
      return toMalaysian(americanOdds).toFixed(2);
    case "indonesian":
      return toIndonesian(americanOdds).toFixed(2);
    case "hongkong":
      return toHongKong(americanOdds).toFixed(2);
    default:
      return americanOdds > 0 ? `+${Math.round(americanOdds)}` : americanOdds;
  }
};

export const isAsianHome = (item) => {
  return item.reference_id.includes(";1");
};

export const isAsianAway = (item) => {
  return item.reference_id.includes(";2");
};

export const isTotalUnder = (item) => {
  return item.reference_id.includes(";O");
};

export const isTotalOver = (item) => {
  return item.reference_id.includes(";U");
};

export const formatPrefix = (prefix) => {
  const parts = prefix.split(',');

  // Step 2: Remove any '%' characters
  const cleanedParts = parts.map(part => part.replace('%', ''));

  // Step 3: Join the parts back into a single string with a space
  return cleanedParts.join(' ');
}

const cdnPath = jwtServiceConfig.cdnUrl;

export const flagPath = (name) => {
  return `${cdnPath}/flags/${name}`;
};

export const moneyText = (amount, currency, fixedNumber = 2) => {
  return `${currency === "USD" ? "$" : ""} ${(+amount).toFixed(fixedNumber)} ${currency !== "USD" ? currency : ""}`;
};

export const textSearch = (array, searchField, searchText) => {
  const stext = searchText.toLowerCase();
  return array.filter(item => item[searchField].toLowerCase().includes(stext));
}
