import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import { useScreenMd } from "src/app/hooks/useScreens"
import { selectGameData } from "../store/gameSlice";
import { useMemo } from "react";
import { CasinoCard } from "./CasinoCard";

export const CasinoGroup = ({ title, items: itemsProp, onAllClick, rootPath }) => {
  const isMd = useScreenMd();
  const { vendorsStatus, gamesStatus } = useSelector(selectGameData);

  const fulfilled = vendorsStatus === "fulfilled" && gamesStatus === "fulfilled";

  const items = useMemo(() => itemsProp.filter(item => (item.mobile && !isMd) || (!item.mobile && isMd)).slice(0, 16), [itemsProp, isMd]);
  
  return (
    <Box className="bg-white flex flex-col rounded-[8px] shadow-md w-full" aria-label="CasinoGroup">
      <Box className="flex justify-between items-center px-[24px] py-[12px] bg-[#E3E9ED] rounded-t-[8px]">
        <Typography className="text-[20px] font-bold capitalize">{title.replace(/-/g, " ")}</Typography>
        <button
          className="text-[#ec622b] uppercase p-[4px] rounded-md font-500 text-[12px] hover:bg-[#eee] active:bg-[#ddd]"
          onClick={onAllClick}
          // disabled={!fulfilled}
        >
          SHOW ALL
        </button>
      </Box>
      <Box className="mt-[10px] px-[24px] py-[12px] max-w-full overflow-x-hidden">
        <Box className="grid grid-cols-2 md:grid-cols-none md:grid-rows-2 md:grid-flow-col md:h-[420px] gap-[8px] justify-start">
          <Box className="w-full md:w-auto aspect-square md:row-span-2 md:h-full">
            <Box className="w-[100%] aspect-square md:w-[420px] md:h-[420px]">
              <CasinoCard rootPath={rootPath} item={items[0]} />
            </Box>
          </Box>
          {items.length > 1 && items.slice(1).map((item) => (
            <Box key={item.id} className="w-full md:w-auto aspect-square md:row-span-1 md:h-full">
              <Box className="w-[100%] aspect-square md:w-[210px] md:h-[210px]">
                <CasinoCard rootPath={rootPath} item={item} />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}