import React, { useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box,
  Typography, Chip, TablePagination
} from "@mui/material";
import { format } from "date-fns";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from "@mui/material/styles";

// Chip component for status display
const StatusChip = ({ status }) => {
  return (
    <Chip label={status === "active" ? "Active" : "Inactive"} color={status === "active" ? "primary" : "default"} />
  );
};

// Styled TableCell to match the sidebar header
const StyledTableCell = styled(TableCell)(({ theme, textAlign }) => ({
  backgroundColor: "#0d1d34",
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign,
  padding: '16px 24px',
  whiteSpace: 'nowrap'
}));

// Define columns with widths
const columns = [
  { id: "bonus", label: "Bonus", width: '15%', align: 'left' },
  { id: "status", label: "Status", width: '10%', align: 'center', value: (row) => <StatusChip status={row.status} /> },
  { id: "expire", label: "Expire", width: '10%', align: 'center', value: (row) => format(new Date(row.expire), "MM-dd-yyyy") },
  { id: "amount", label: "Amount", width: '10%', align: 'right' },
  { id: "wageringReq", label: "Wagering Requirement", width: '10%', align: 'right' },
  { id: "amountWagered", label: "Amount Wagered", width: '10%', align: 'right' }
];

// Sample data rows
const rows = [
  { id: "1", bonus: "Bonus 1", status: "active", expire: "2024-12-31", amount: "$24", wageringReq: "$100", amountWagered: "$80" },
  { id: "2", bonus: "Bonus 2", status: "inactive", expire: "2024-11-15", amount: "$50", wageringReq: "$200", amountWagered: "$150" },
  { id: "3", bonus: "Bonus 3", status: "active", expire: "2025-01-10", amount: "$30", wageringReq: "$120", amountWagered: "$100" },
  { id: "4", bonus: "Bonus 4", status: "inactive", expire: "2024-09-05", amount: "$15", wageringReq: "$50", amountWagered: "$40" },
  { id: "5", bonus: "Bonus 5", status: "active", expire: "2024-12-01", amount: "$75", wageringReq: "$300", amountWagered: "$250" },
  { id: "6", bonus: "Bonus 6", status: "inactive", expire: "2024-10-22", amount: "$40", wageringReq: "$150", amountWagered: "$120" },
  { id: "7", bonus: "Bonus 7", status: "active", expire: "2024-08-30", amount: "$20", wageringReq: "$90", amountWagered: "$70" },
  { id: "8", bonus: "Bonus 8", status: "inactive", expire: "2024-07-22", amount: "$35", wageringReq: "$110", amountWagered: "$90" },
  { id: "9", bonus: "Bonus 9", status: "active", expire: "2024-06-18", amount: "$60", wageringReq: "$180", amountWagered: "$140" },
  { id: "10", bonus: "Bonus 10", status: "inactive", expire: "2024-05-12", amount: "$45", wageringReq: "$160", amountWagered: "$130" },
  // Add more rows as needed
];

export const BonusTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate paginated rows
  const paginatedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Mobile view layout
  if (isMobile) {
    return (
      <Box>
        {paginatedRows.map((row) => (
          <Box key={row.id} sx={{ marginBottom: 2, padding: 2, border: '1px solid #ddd', borderRadius: '8px' }}>
            {columns.map((column) => (
              <Box key={column.id} sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                <Typography variant="subtitle2">{column.label}</Typography>
                <Typography variant="body2">
                  {column.value ? column.value(row) : row[column.id]}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
        <TablePagination
          component="div"
          count={rows.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    );
  }

  // Desktop view layout
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} style={{ width: column.width }} textAlign={column.align}>
                <Typography variant="subtitle2">{column.label}</Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map((row) => (
            <TableRow key={row.id}>
              {columns.map((column) => (
                <TableCell key={column.id} style={{ width: column.width, px: "24px" }} align={column.align}>
                  {column.value ? column.value(row) : row[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};
