import React from "react";
import { Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getSystemOptions, getSystemOption, setSystemOption, selectTickets, selectBankers } from "../../../store/ticketSlice";
import { SelectGroup } from "src/app/main/settings/component/InputGroups";
import { useEffect, useState } from "react";
import { generateSystemOptions } from 'src/app/utils'

const SystemOptions = () => {
  const selectedOption = useSelector(getSystemOption);
  const tickets = useSelector(selectTickets);
  const bankers = useSelector(selectBankers);
  const dispatch = useDispatch();
  const [options, setOptions] = useState(null);

  useEffect(() => {
    setOptions(generateSystemOptions(tickets.length, bankers.length))
  }, [tickets, bankers])

  useEffect(() => {
    if(options && options.length) {
      dispatch(setSystemOption({ label: `${options[0].label} (${options[0].bets})`, value: options[0].label, betCount: parseInt(options[0].bets.match(/\d+/)[0], 10) }));
    }
  }, [options])

  const handleChange = (value) => {
    dispatch(setSystemOption(value));
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ color: "black", mb: 1, pl: "16px", pt: "8px" }}>
        System
      </FormLabel>
      <Box className="px-[16px]">
        {options? (
          <SelectGroup
          value={selectedOption}
          onChange={handleChange}
          options={options.map((op) => ({ label: `${op.label} (${op.bets})`, value: op.label, betCount: parseInt(op.bets.match(/\d+/)[0], 10) }))}
          />): null
        }
        
      </Box>
    </FormControl>
  );
};

export default SystemOptions;
