import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getCMSList } from "src/app/main/store/cmsSlice";

const sections = [
  { title: "Company", value: "company" },
  { title: "Terms of Privacy", value: "terms_of_privacy" },
  { title: "Rules", value: "rules" },
  { title: "Responsible Gambling", value: "responsible_gambling" },
  { title: "FAQ", value: "faq" },
]

function FooterMenu() {
  const [isActiveMenu, setIsActiveMenu] = useState();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const mobileMenuToggle = () => {
    setIsActiveMenu(!isActiveMenu);
  };

  useEffect(() => {
    dispatch(getCMSList("pages_of_contents")).then((res) => {
      const list = res.payload || [];
      setData(list.filter(item => !item.isdeleted));
    });
  }, []);

  return (
    <>
      <div className="footer_menu_wrapper">
        {sections.map((section, index) => (
          <div key={index} className={isActiveMenu ? "footer_menu active" : "footer_menu"}>
            <a onClick={mobileMenuToggle}>
              {section.title}
              <i className="fa-solid fa-angle-down"></i>
            </a>
            <ul>
              {data
                .filter(item => item.section === section.value)
                .map((item, subIndex) => (
                  <li key={item.id}>
                    <Link to={`/information/${item.section}/${item.id}`}>{item.title}</Link>
                  </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
}

export default FooterMenu;
