import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { ModalBox } from "app/shared-components/ModalBox";
import { useModal } from "src/app/hooks/useModal";
import { InputGroup, SelectGroup } from "../../settings/component/InputGroups";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import Turnstile from "react-turnstile";
import { useDispatch } from "react-redux";
import { signUpAffiliate } from "../../store/affiliateSlice";
import i18next from "i18next";
import countryList from "react-select-country-list";

const options = countryList().getData();

const siteCategories = [
  { label: "Sports predictions", value: "sports-predictions" },
  { label: "Sports news", value: "sports-news" },
  { label: "Bookmakers and bets", value: "bookmakers-and-bets" },
  { label: "Sports broadcasts", value: "sports-broadcasts" },
  { label: "Casino", value: "casino" },
  { label: "Sport", value: "sport" },
  { label: "Other", value: "other" },
  { label: "Facebook", value: "facebook" },
  { label: "Aso", value: "aso" },
  { label: "Telegram", value: "telegram" },
  { label: "Ad networks", value: "ad-networks" },
];

const countries = [
  { label: "United States", value: "us" },
  { label: "Turkey", value: "tr" },
];

const initialAffiliateValue = {
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  phoneNumber: "",
  userName: "",
  password: "",
};

export const AffiliateRequestModal = ({ visible, onClose }) => {
  const recaptcha = useRef();
  const [affiliateValue, setAffiliateValue] = useState(initialAffiliateValue);
  const [errors, setErrors] = useState({});
  const [country, setCountry] = useState("");
  const dispatch = useDispatch();

  useModal(visible);

  const header = () => {
    return (
      <Box className="p-[12px] min-w-screen md:min-w-[600px] invisible md:block">
        <img
          src="assets/images/logo/logo.png"
          alt="logo"
          className="w-[140px] h-auto object-contain"
        />
      </Box>
    );
  };

  const handleCaptcha = (token) => {};

  const handleChangeCountry = (e) => {
    setCountry(e);
    setAffiliateValue((prev) => ({ ...prev, country: e.value }));
  };

  const validateAffiliateForm = (values) => {
    const errors = {};

    // Validate firstName
    if (!values.firstName.trim()) {
      errors.firstName = "First Name is required.";
    }

    // Validate lastName
    if (!values.lastName.trim()) {
      errors.lastName = "Last Name is required.";
    }

    // Validate email
    if (!values.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email)) {
      errors.email = "Invalid email format.";
    }

    // Validate country
    if (!values.country.trim()) {
      errors.country = "Country is required.";
    }

    // Validate phoneNumber
    if (!values.phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required.";
    } else if (!/^\d{10,15}$/.test(values.phoneNumber)) {
      errors.phoneNumber =
        "Invalid phone number. It should contain 10 to 15 digits.";
    }

    // Validate userName
    if (!values.userName.trim()) {
      errors.userName = "Username is required.";
    } else if (values.userName.length < 4) {
      errors.userName = "Username must be at least 4 characters long.";
    }

    // Validate password
    // if (!values.password) {
    //   errors.password = "Password is required.";
    // } else if (values.password.length < 8) {
    //   errors.password = "Password must be at least 8 characters long.";
    // } else if (
    //   !/[A-Z]/.test(values.password) ||
    //   !/[a-z]/.test(values.password) ||
    //   !/\d/.test(values.password)
    // ) {
    //   errors.password =
    //     "Password must include uppercase, lowercase letters, and numbers.";
    // }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateAffiliateForm(affiliateValue);
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length === 0) {
      try {
        // Dispatch the action and get the response
        const response = await dispatch(signUpAffiliate(affiliateValue))
        console.log(response, 'response')
        // Check if the backend response indicates success
        if (response?.payload?.status === 200) {
          toast.success("Signup successful!"); // Show success message or perform a redirect
        } else {
          toast.error(response?.payload?.user_error); // Show failure message
        }
      } catch (error) {
        // Accessing error response depending on Axios' structure
        const errorMessage = error.response?.data?.user_error || error.message || "An error occurred.";
        console.log(error, 'error')
        
        // Display specific backend errors
        if (errorMessage === "Email already exists") {
          setErrors((prev) => ({ ...prev, email: "Email already exists." }));
        } else if (errorMessage === "Username already exists") {
          setErrors((prev) => ({ ...prev, userName: "Username already exists." }));
        } else {
          toast.error(errorMessage); // Fallback for other unexpected errors
        }
      }
    }
  }

  const body = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Box className="min-w-[60vw] p-[40px] max-h-[var(--app-height)] pb-[65px] md:pb-[40px] md:max-h-[80vh] overflow-y-auto overflow-x-hidden">
          <Box className="py-[8px] bg-white flex md:hidden" onClick={onClose}>
            <button className="p-[4px] ml-auto">
              <CloseIcon />
            </button>
          </Box>
          <Box className="flex flex-col md:flex-row gap-[40px]">
            <Box
              aria-label="username-and-password"
              className="w-full flex flex-col gap-[24px]"
            >
              <Box>
                <Typography className="text-[20px]">
                  {i18next.t("Username_and_password")}
                </Typography>
                <Box className="flex flex-col gap-[12px] mt-[16px]">
                  <InputGroup
                    label={i18next.t("Username") + " *"}
                    inputProps={{
                      value: affiliateValue.userName,
                      onChange: (e) =>
                        setAffiliateValue((prev) => ({
                          ...prev,
                          userName: e.target.value,
                        })),
                    }}
                  />
                  {errors.userName && (
                    <Typography color="error">{errors.userName}</Typography>
                  )}
                  <InputGroup
                    label={i18next.t("Password") + " *"}
                    inputProps={{
                      type: "password",
                      value: affiliateValue.password,
                      onChange: (e) =>
                        setAffiliateValue((prev) => ({
                          ...prev,
                          password: e.target.value,
                        })),
                    }}
                  />
                  {errors.password && (
                    <Typography color="error">{errors.password}</Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              aria-label="contact-detail"
              className="w-full flex flex-col gap-[24px]"
            >
              <Box>
                <Typography className="text-[20px]">{i18next.t("Contact_details")}</Typography>
                <Box className="flex flex-col gap-[12px] mt-[16px]">
                  <InputGroup
                    label={i18next.t("First_name") + " *"}
                    inputProps={{
                      value: affiliateValue.firstName,
                      onChange: (e) =>
                        setAffiliateValue((prev) => ({
                          ...prev,
                          firstName: e.target.value,
                        })),
                    }}
                  />
                  {errors.firstName && (
                    <Typography color="error">{errors.firstName}</Typography>
                  )}
                  <InputGroup
                    label={i18next.t("Last_name") + " *"}
                    inputProps={{
                      value: affiliateValue.lastName,
                      onChange: (e) =>
                        setAffiliateValue((prev) => ({
                          ...prev,
                          lastName: e.target.value,
                        })),
                    }}
                  />
                  {errors.lastName && (
                    <Typography color="error">{errors.lastName}</Typography>
                  )}
                  <InputGroup
                    label={i18next.t("Email") + " *"}
                    inputProps={{
                      value: affiliateValue.email,
                      onChange: (e) =>
                        setAffiliateValue((prev) => ({
                          ...prev,
                          email: e.target.value,
                        })),
                    }}
                  />
                  {errors.email && (
                    <Typography color="error">{errors.email}</Typography>
                  )}
                  <SelectGroup
                    label={i18next.t("Country") + " *"}
                    options={options}
                    value={country}
                    onChange={(e) => handleChangeCountry(e)}
                  />
                  {errors.country && (
                    <Typography color="error">{errors.country}</Typography>
                  )}
                  <InputGroup
                    label={i18next.t("Phone_number") + " *"}
                    inputProps={{
                      value: affiliateValue.phoneNumber,
                      onChange: (e) =>
                        setAffiliateValue((prev) => ({
                          ...prev,
                          phoneNumber: e.target.value,
                        })),
                    }}
                  />
                  {errors.phoneNumber && (
                    <Typography color="error">{errors.phoneNumber}</Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="mt-[42px]">
            <Typography component="span">
              {i18next.t("The")} https://meganetgame.com {i18next.t("is_used_in_accordance_with_the")}
            </Typography>{" "}
            <button className="text-[#3c9fdd] underline hover:no-underline">
              {i18next.t("Terms_and_Conditions")}
            </button>{" "}
            {i18next.t("and") + " "}
            <button className="text-[#3c9fdd] underline hover:no-underline">
              {i18next.t("Privacy_Policy")}
            </button>
          </Box>
          <Box>
            <Typography 
              className="mt-[12px]"
              sx={{ wordWrap: "break-word", whiteSpace: "normal" }}
            >
              {i18next.t("The_personal_data_that_you_supply_when_registering_on_this_website_may_be_shared_with_the_meganetgame_affiliate_companies_in_different_countries_and_third_parties_rendering_services_to_meganetgame")}
            </Typography>
          </Box>
          <FormControlLabel
            className="mt-[24px]"
            control={
              <Checkbox
                defaultChecked
                sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }}
              />
            }
            label={i18next.t("I_have_read_understood_and_accept_the_above_Terms_and_Conditions_and_Privacy_Policy")}
          />
          <Box className="flex flex-col justify-center items-center mt-[12px] gap-[24px]">
            {/* <Turnstile sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY} onVerify={handleCaptcha} /> */}
            <button
              type="submit"
              className="uppercase text-white px-[50px] py-[12px] text-[18px] rounded-[6px] bg-[#28F09C] hover:bg-[#21e090]"
            >
              {i18next.t("Request")}
            </button>
          </Box>
        </Box>
      </form>
    );
  };

  return (
    <ModalBox visible={visible} onClose={onClose} header={header()} body={body()} />
  );
};
