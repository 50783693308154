import React from "react";
import { Box, useTheme } from "@mui/material";

export const SearchBox = ({ inputProps, boxProps }) => {
  const theme = useTheme();
  
  return (
    <Box
      aria-label="searchBox"
      className="border border-[#D3D3D3] rounded-[12px] py-[8px] pl-[10px] pr-[14px] flex items-center gap-[12px] w-full"
      bgcolor={theme.palette.background.default}
      {...boxProps}
    >
      <input
        className="text-[#585858] text-[12px] font-thin border-none bg-transparent w-full leading-none"
        placeholder="Search"
        {...inputProps}
      />
      <img src="assets/images/search-icon.svg" className="w-[16px] h-[16px] object-contain" />
    </Box>
  );
};
