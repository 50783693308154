import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getGamesByVendor, getPopularGames, selectGameData } from "../store/gameSlice";
import { useIsLive } from "src/app/hooks/useIsLive";
import { CasinoCard } from "./CasinoCard";
import { useState } from "react";
import { useMemo } from "react";
import { LoadingBox } from "app/shared-components/LoadingBox";
import { getFavorites } from "../../store/favSlice";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useRef } from "react";
import { selectCasinoTypes } from "../../store/uiSlice";

export const ShowAllBox = ({ rootPath, origin, currentPage }) => {
  const { casinoId, vendorId } = useParams();
  const dispatch = useDispatch();
  const { isLive } = useIsLive();
  const { casinoTypes: allCasinoTypes, vendors, games, gamesStatus } = useSelector(selectGameData);
  const isMd = useScreenMd();
  const navigate = useNavigate();
  // const vendors = useSelector(selectVendors);
  // const gameStatus = useSelector(selectGameStatus);
  // const games = useSelector(selectGameList);

  const casinoTypes = useMemo(() => {
    const type =
      rootPath === "casino"
        ? "Casino"
        : rootPath === "live-casino"
        ? "Live Casino"
        : rootPath === "virtual-games"
        ? "Virtual"
        : "";
    return allCasinoTypes.filter((casino) => casino.type === type);
  }, [allCasinoTypes, rootPath]);

  const vendorsPerPage = 2;
  const gamesPerPage = 20;
  // const [currentPage, setCurrentPage] = useState(0);

  const pageOfContentRef = useRef(0);
  pageOfContentRef.current = currentPage;

  // useEffect(() => {
  //   if (casinoId && casinoId !== "all") {
  //     dispatch(getVendors(casinoId));
  //     dispatch(setGameList([]));
  //   } else if (casinoId === "all") {
  //     // dispatch(getAllVendors(casinoTypes.map((c) => c.id)));
  //   }
  // }, [casinoId, dispatch, isLive]);

  useEffect(() => {
    if (origin === "favorite") {
      dispatch(getFavorites({ langId: "en" }));
    } else if (origin === "popular") {
      dispatch(getPopularGames());
    }
  }, [origin, dispatch]);

  useEffect(() => {
    if (origin === "vendor") {
      dispatch(getGamesByVendor(vendorId));
    }
  }, [origin, vendorId]);

  useEffect(() => {
    // setCurrentPage(0);
  }, [vendorId, rootPath]);

  const gamesFiltered = games
    .filter(
      (item) => (origin !== "vendor" && origin !== "popular") || casinoTypes.some((type) => type.name === item.type)
    )
    .filter((item) => (item.mobile && !isMd) || (!item.mobile && isMd));
  const moreVisible = useMemo(() => (currentPage + 1) * gamesPerPage < gamesFiltered.length, [gamesFiltered]);

  const handleCasinoPlayClick = (casinoId) => {
    navigate(`/${rootPath}/game/${casinoId}`);
  }

  // useEffect(() => {
  //   if (vendors.length === 0) return;
  //   dispatch(
  //     addGameListFromVendors({
  //       vendors: vendors.filter(
  //         (_, index) => index >= currentPage * vendorsPerPage && index < (currentPage + 1) * vendorsPerPage
  //       ),
  //     })
  //   );
  // }, [vendors, currentPage, vendorsPerPage]);

  return (
    <Box>
      <Box className="grid grid-cols-2 md:grid-cols-6 p-[26px] gap-[12px]">
        {gamesFiltered.slice(0, (currentPage + 1) * gamesPerPage).map((game, index) => (
          <Box key={index} className="w-full aspect-square min-w-[180px] min-h-[180px]">
            <CasinoCard rootPath={rootPath} item={game} />
          </Box>
        ))}
      </Box>
      {/* {gamesStatus === "loading" && <LoadingBox loading={gamesStatus === "loading"} />} */}
      {/* {moreVisible && gamesStatus === "fulfilled" && (
        <Box className="flex justify-center py-[24px]">
          <button
            className="px-[8px] py-[4px] rounded-[4px] bg-green text-white hover:bg-[darkgreen]"
            onClick={() => setCurrentPage((c) => c + 1)}
          >
            Load more games
          </button>
        </Box>
      )} */}
    </Box>
  );
};
