

function FooterText() {
    return (
        <>
            <div className="footer_text">
                <p> In order to register for this website, the user is required to accept the
                    General Terms &amp; Conditions In the event the General Terms &amp; Conditions are updated,
                    existing users may choose to discontinue using the products and services before the said update
                    shall become effective, which is a minimum of two weeks after it has been announced</p>
            </div>
            <div className="copyright">
                <p> © 2018-2024 All Rights Reserved </p>
            </div>
        </>
    );
}

export default FooterText;