import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useRef } from "react";
import Select from "react-select";

export const InputGroup = ({ label, inputProps, containerProps }) => {
  const ref = useRef();

  const handleWheel = (e) => {
    if (document.activeElement === ref.current) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const inputElement = ref.current;
    inputElement?.addEventListener("wheel", handleWheel);
    return () => {
      inputElement?.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <Box className="w-full flex flex-col items-start md:flex-row md:items-center gap-[8px]" {...containerProps}>
      {label && <Typography className="text-[#505155] text-[14px] capitalize min-w-[124px]">{label}</Typography>}
      <input
        ref={ref}
        className="border-[1.5px] border-[#D9E1E5] rounded-[12px] px-[12px] py-[8px] bg-[#EDF0F2] text-[#727b80] w-full"
        {...inputProps}
      />
    </Box>
  );
};

export const SelectGroup = ({ value, onChange, label, options }) => {
  return (
    <Box className="flex flex-col items-start md:flex-row md:items-center gap-[8px] text-black">
      {label && <Typography className="text-[#505155] text-[14px] capitalize min-w-[180px]">{label}</Typography>}
      <Select value={value} onChange={onChange} className="custom-select" options={options} isSearchable={false} />
    </Box>
  );
};

export const Hr = () => <Box className="w-full h-[1.5px] border-t-[1.5px] border-t-[#D9E1E5]"></Box>;
