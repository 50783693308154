import { memo } from "react";
import FooterText from "app/theme-layouts/shared-components/CopyrightText";
import FooterLogos from "app/theme-layouts/shared-components/FooterLogos";
import FooterPaymentLogos from "app/theme-layouts/shared-components/FooterPaymentLogos";
import FooterMenu from "app/theme-layouts/shared-components/FooterMenu";
import LogoNew from "app/shared-components/LogoNew";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSystem } from "src/app/main/store/systemSettingsSlice";

function FooterLayout1() {
  const systemSettings = useSelector(selectSystem);
  const { facebook, twitter, instagram, tiktok } = systemSettings;

  return (
    <>
      <footer aria-label="FooterLayout1">
        <div className="container">
          <div className="footer_inner mb-[65px] md:mb-0">
            <FooterMenu />
            {/* <FooterPaymentLogos /> */}
            <FooterLogos />
            <Stack direction={{ md: "row" }} justifyContent="space-between" gap="12px">
              <LogoNew />
              <Stack
                justifyContent={{ xs: "space-between", md: "flex-start" }}
                direction="row"
                gap="12px"
                px={{ xs: "24px", md: 0 }}
              >
                <Link to={facebook}>
                  <img src={"assets/images/social-media/facebook.png"} alt="" />
                </Link>
                <Link to={twitter}>
                  <img src={"assets/images/social-media/twitter.png"} alt="" />
                </Link>
                <Link to={instagram}>
                  <img src={"assets/images/social-media/instagram.png"} alt="" />
                </Link>
                <Link to={tiktok}>
                  <img src={"assets/images/social-media/tiktok.png"} alt="" />
                </Link>
              </Stack>
            </Stack>
            <FooterText />
          </div>
        </div>
      </footer>
    </>
  );
}

export default memo(FooterLayout1);
