import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useScreenSm } from "src/app/hooks/useScreens";
import { Box, Drawer, Popover, Collapse, Typography, useTheme } from "@mui/material";
import { setCashierModalVisible } from "src/app/main/store/uiSlice";
import { VerticalDrawer } from "../../main/dashboard/components/VerticalDrawler";
import { faAddressCard, faHome, faBullhorn, faWallet, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectUser } from "app/store/userSlice";
import { moneyText } from "src/app/utils";
import { useSystemType } from "src/app/hooks/useSystemType";
import { ONLINE_SYSTEM } from "src/app/constants";

export const UserDropdown = () => {
  const [menu, setMenu] = useState(null);
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
  const dispatch = useDispatch();
  const isSm = useScreenSm();
  const [menuVisible, setMenuVisible] = useState(true);
  const user = useSelector(selectUser);
  const systemType = useSystemType();

  const handleDepositClick = () => {
    dispatch(setCashierModalVisible(true));
  };

  const handleUserbuttonClick = (e) => {
    if (isSm) {
      setMenu(e.currentTarget);
    } else {
      setMobileDrawerVisible(true);
    }
  };

  const dropdownButtonList = [
    { id: "profile", title: "Edit Profile", path: "/settings/profile" },
    { id: "deposit", title: "Deposit", path: "#", onClick: handleDepositClick },
    { id: "withdraw", title: "Withdraw", path: "/settings/withdraw" },
    { id: "bet-history", title: "bet history", path: "/settings/game-history" },
  ];

  const rightSideBarList = [
    {
      id: "account",
      title: "MY ACCOUNT",
      icon: faAddressCard,
      items: [
        { icon: "", path: "/settings/profile", name: "Personal Details" },
        { icon: "", path: "/settings/preferences", name: "Preferences" },
        { icon: "", path: "/settings/verification", name: "Account Verification" },
      ],
    },
    {
      id: "payment",
      title: "PAYMENT",
      icon: faHome,
      items: [
        { id: "deposit", icon: "", path: undefined, name: "Deposit" },
        { id: "withdrawal", icon: "", path: undefined, name: "Withdrawal" },
        { id: "tx-history", icon: "", path: "/settings/transaction-history", name: "Transaction History" },
      ],
    },
    {
      id: "promotions",
      title: "ACTIVE PROMOTIONS",
      icon: faBullhorn,
      items: [
        { icon: "", path: "", name: "Bonues" },
        { icon: "", path: "", name: "Coins" },
      ],
    },
    {
      id: "mybets",
      title: "MY BETS",
      icon: faWallet,
      items: [
        { icon: "", path: "/settings/game-history", name: "Bet History" },
        { icon: "", path: "/settings/casino-history", name: "Casino History" },
      ],
    },
  ];

  const balanceInfo = [
    { id: "accountBalance", title: "Withdrawable" },
    { id: "casino", title: "Casino bonus" },
    { id: "sports", title: "Sports bonus" },
  ];

  const theme = useTheme();

  return (
    <>
      <Box aria-label="userDropdown" className="relative ml-[12px] text-white">
        <button
          className="rounded-[12px] px-[8px] sm:pl-[12px] sm:pr-[8px] h-[40px] flex items-center bg-[#ffffff10] hover:bg-[#ffffff30] active:bg-[#ffffff50]"
          onClick={handleUserbuttonClick}
        >
          <PersonIcon />
          <KeyboardArrowDownIcon className="hidden sm:block" />
        </button>
      </Box>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={() => setMenu(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "hidden sm:block max-h-[400px] !duration-75 text-white mt-[12px] bg-transparent",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "12px",
          },
        }}
      >
        <Box className="bg-[#2C374F] flex flex-col shadow-2xl overflow-hidden">
          <Box className="flex flex-col items-start">
            {dropdownButtonList
              .filter(item => systemType === ONLINE_SYSTEM || (item.id !== "deposit" && item.id !== "withdraw"))
              .map((b) => (
                <Link
                  key={b.id}
                  to={b.path}
                  className="px-[16px] py-[8px] hover:bg-[#cccccc40] uppercase whitespace-nowrap w-full text-left text-[12px]"
                  onClick={() => {
                    setMenu(null);
                    b.onClick && b.onClick();
                  }}
                  style={{ color: "white" }}
                >
                  {b.title}
                </Link>
            ))}
            <button
              className="px-[16px] py-[8px] hover:bg-[#cccccc40] uppercase whitespace-nowrap w-full text-left text-[12px] !border-t !border-t-[#ffffff80]"
              onClick={() => setMenu(null)}
            >
              <Link to="/sign-out" className="w-full h-full block" style={{ color: "white" }}>
                Log out
              </Link>
            </button>
          </Box>
        </Box>
      </Popover>

      <Drawer
        aria-label="UserDropdownForMobile"
        open={mobileDrawerVisible}
        anchor="right"
        onClose={() => setMobileDrawerVisible(false)}
        className="block sm:hidden"
      >
        <Box aria-label="user-info" className="pt-[24px] pb-[4px] px-[8px] relative">
          <button
            className="absolute right-0 top-0 p-[4px] bg-[#00000030]"
            onClick={() => setMobileDrawerVisible(false)}
          >
            <CloseIcon className="text-[#333]" />
          </button>
          <div className="pt-[20px]">
            <button
              aria-label="titleBox"
              className="bg-[#E4E5E5] w-full flex justify-between items-center px-[16px] py-[8px] max-w-[300px]"
              onClick={() => setMenuVisible((v) => !v)}
            >
              <Box className="uppercase text-[#505155] font-500 max-w-[240px] flex items-center gap-[4px]">
                <FontAwesomeIcon icon={faUser} className="!text-[18px]" />{" "}
                <Box className="max-w-full truncate">{user.data.displayName || user.data.email}</Box>
              </Box>
              {menuVisible && <KeyboardArrowUpIcon />}
              {!menuVisible && <KeyboardArrowDownIcon />}
            </button>
            <Collapse in={menuVisible}>
              <Box className="flex flex-col">
                {systemType === ONLINE_SYSTEM && balanceInfo.map((item) => (
                  <Box
                    key={item.id}
                    aria-label="drawButton"
                    className="px-[16px] py-[8px] flex gap-[8px] items-center justify-between border-b border-b-[#F0F3F8] border-x border-x-[#F0F3F8] hover:bg-[#ddd] whitespace-nowrap"
                    to={item.path}
                  >
                    <Typography className="text-[#7A7A7B] text-[14px]">{item.title}</Typography>
                    <Typography className="text-[#7A7A7B] text-[14px]">
                      {moneyText(user.data[item.id] || 0, user.data.currency)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Collapse>
          </div>
          {rightSideBarList
            .filter(item => systemType === ONLINE_SYSTEM || (item.id !== "payment" && item.id !== "promotions"))
            .map((b, index) => (
              <VerticalDrawer
                key={index}
                titleIcon={b.icon}
                title={b.title}
                items={b.items}
                onLinkClick={() => setMobileDrawerVisible(null)}
              />
          ))}
        </Box>
      </Drawer>
    </>
  );
};
