import { Box, Collapse, Typography, styled } from "@mui/material";
import { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSystemType } from "src/app/hooks/useSystemType";
import { ONLINE_SYSTEM } from "src/app/constants";

const ListItem = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  color: "#505155",
  fontFamily: "styrene",
  fontSize: "14px",
  width: "100%",
  padding: "12px 18px",
}));

export const BalanceCard = ({ title, currency, itemList }) => {
  const [collapsed, setCollapsed] = useState(false);
  const systemType = useSystemType();

  return (
    <Box aria-label="balanceCard" className="flex flex-col min-w-[310px]">
      <Box
        className="rounded-t-[12px] flex justify-between items-center bg-[#0d1d34] py-[8px] px-[16px]"
        onClick={() => setCollapsed((c) => !c)}
      >
        <Typography className="text-white text-[14px] uppercase font-500">
          {title}
        </Typography>
        {collapsed && <KeyboardArrowDownIcon />}
        {!collapsed && <KeyboardArrowUpIcon />}
      </Box>
      <Collapse in={!collapsed} className="bg-white rounded-b-[12px]">
        {itemList.filter(item => systemType === ONLINE_SYSTEM || item.id === "balance" ).map((item) => (
          <ListItem key={item.id}>
            <Typography>{`${item.title} (${currency})`}</Typography>
            <Typography>{item.balance.toFixed(2)}</Typography>
          </ListItem>
        ))}
      </Collapse>
    </Box>
  );
};
