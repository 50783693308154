import "../../../../styles/customPhoneCode.css";
import { Box, Typography } from "@mui/material";
import { ContentCard } from "../component/ContentCard";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { getProfile, updateProfile, updatePassword } from "../store/profileSlice";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PhoneInput from "react-phone-input-2";
import countryList from "react-select-country-list";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import i18next from "i18next";
import {selectCurrentLanguageId} from "app/store/i18nSlice";
import i18n from "src/i18n";

const options = countryList().getData();

const InputGroup = ({ label, inputProps }) => {
  return (
    <Box className="w-full flex flex-col md:flex-row items-start md:items-center  -[8px] mt-[5px]">
      <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">{label}</Typography>
      <input
        className="border-[1.5px] border-[#D9E1E5] rounded-[12px] px-[12px] py-[8px] bg-[#EDF0F2] text-[#5E5A51] w-full"
        {...inputProps}
      />
    </Box>
  );
};

const Hr = () => <Box className="w-full h-[1.5px] border-t-[1.5px] border-t-[#D9E1E5]"></Box>;

export const Profile = () => {
  const genderOptions = [
    { value: "male", label: i18next.t("Male") },
    { value: "female", label: i18next.t("Female") },
  ]

  const dispatch = useDispatch();
  const isMd = useScreenMd();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    dob: null,
    email: "",
    country: "",
    gender: "",
    userName: "",
    city: "",
    idNumber: "",
    street: "",
    houseNumber: "",
    zipCode: "",
    currentPassword: "",
    newPassword: "",
  });
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    dispatch(getProfile()).then((res) => {
      const data = res.payload;
      setFormData({
        firstName: data.firstname || "",
        lastName: data.lastname || "",
        phone: data.phone || "",
        dob: data.birthday ? new Date(data.birthday) : null,
        email: data.email || "",
        country: data.country || "",
        gender: data.gender || "",
        userName: data.username || "",
        city: data.city || "",
        idNumber: data.id_number || "",
        street: data.address || "",
        houseNumber: data.house_number || "",
        zipCode: data.post_zip || "",
        currentPassword: "",
        newPassword: "",
      });
    });
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (!formData.idNumber) {
      alert("ID Number is required");
      return;
    }
    const submitData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      id_number: formData.idNumber,
      house_number: formData.houseNumber,
      gender: formData.gender,
      birthday: formData.dob,
      country: formData.country,
      city: formData.city,
      street: formData.street,
      post_zip: formData.zipCode,
      phone: formData.phone,
    };

    dispatch(updateProfile(submitData));
    console.log("Save button clicked", formData);
  };

  const handleChangePassword = () => {
    dispatch(updatePassword({ current_password: formData.currentPassword, new_password: formData.newPassword }));
    console.log("Change Password button clicked", formData.currentPassword, formData.newPassword);
  };

  return (
    <Box aria-label="Profile">
      <ContentCard title={i18next.t("edit_profile")} icon="assets/images/pages/settings/profile.svg">
        <Box className="bg-white rounded-b-[12px] pb-[24px]">
          <Box className="flex flex-col gap-[12px] max-w-[1000px] mx-auto">
            <Box className={isMd ? "p-[16px] grid grid-cols-2 gap-x-[48px] gap-y-[12px]" : "p-[16px] block gap-[12px]"}>
              <InputGroup
                label={i18next.t("First_Name")}
                inputProps={{ name: "firstName", value: formData.firstName, onChange: handleChange }}
              />
              <Box className="w-full flex items-center gap-[8px] mt-[5px]">
                <InputGroup
                  label={i18next.t("Last_Name")}
                  inputProps={{ name: "lastName", value: formData.lastName, onChange: handleChange }}
                />
              </Box>
              <Box className="w-full flex items-center gap-[8px] mt-[5px]">
                <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">{i18next.t("Phone")}</Typography>
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  value={formData.phone}
                  onChange={(phone) => setFormData((prev) => ({ ...prev, phone }))}
                  inputClass="!border-[1.5px] !border-[#D9E1E5] !rounded-[12px] !bg-[#EDF0F2]"
                />
              </Box>
              <Box className="w-full flex items-center gap-[8px] mt-[5px]">
                <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">{i18next.t("DOB")}</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={formData.dob}
                    onChange={(date) => setFormData((prev) => ({ ...prev, dob: date }))}
                    renderInput={(params) => <input {...params} />}
                    sx={{
                      backgroundColor: "#EDF0F2",
                      width: "100%",
                      borderRadius: "12px",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "1.5px solid #D9E1E5",
                        borderRadius: "12px",
                      },
                      input: {
                        padding: "8px 12px",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <InputGroup label={i18next.t("Email")} inputProps={{ name: "email", value: formData.email, readOnly: true }} />
              <Box className="w-full flex items-center gap-[8px] mt-[5px] relative">
                <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">{i18next.t("Country")}</Typography>
                <Select
                  options={options}
                  value={options.find((option) => option.label === formData.country)}
                  placeholder={i18next.t("Search_country")}
                  className="custom-select"
                  isSearchable
                  onChange={(val) => setFormData((prev) => ({ ...prev, country: val.label }))}
                />
              </Box>
              <Box className="w-full flex items-center gap-[8px] mt-[5px] relative">
                <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">{i18next.t("Gender")}</Typography>
                <Select
                  options={genderOptions}
                  // value={i18n.t(genderOptions.find((option) => option.label === formData.gender))}
                  value={genderOptions.find((option) => option.label === formData.gender)}
                  className="custom-select"
                  onChange={(val) => {console.log(); setFormData((prev) => ({...prev, gender: val.label }))}}
                />
              </Box>
              {/* <InputGroup
                label="Gender"
                inputProps={{ name: "gender", value: formData.gender, onChange: handleChange }}
              /> */}
            </Box>
            <Hr />
            <Box className={isMd ? "p-[16px] grid grid-cols-2 gap-x-[48px] gap-y-[12px]" : "p-[16px] block gap-[12px]"}>
              <InputGroup
                label={i18next.t("User_Name")}
                inputProps={{ name: "userName", value: formData.userName, readOnly: true }}
              />
              <InputGroup
                  label={i18next.t("City")}
                  inputProps={{ name: "city", value: formData.city, onChange: handleChange }} />
              <InputGroup
                label={i18next.t("ID_Number")}
                inputProps={{ name: "idNumber", value: formData.idNumber, onChange: handleChange, required: true }}
              />
              <InputGroup
                label={i18next.t("Street")}
                inputProps={{ name: "street", value: formData.street, onChange: handleChange }}
              />
              <InputGroup
                label={i18next.t("House_Number")}
                inputProps={{ name: "houseNumber", value: formData.houseNumber, onChange: handleChange }}
              />
              <InputGroup
                label={i18next.t("Post_ZIP_code")}
                inputProps={{ name: "zipCode", value: formData.zipCode, onChange: handleChange }}
              />
              <button
                className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] mt-[24px]"
                onClick={handleSave}
              >
                {i18n.t("Save")}
              </button>
            </Box>
            <Hr />
            <Box className={isMd ? "p-[16px] grid grid-cols-2 gap-x-[48px] gap-y-[12px]" : "p-[16px] block gap-[12px]"}>
              <InputGroup
                label={i18next.t("Change_Password")}
                inputProps={{
                  name: "currentPassword",
                  value: formData.currentPassword,
                  onChange: handleChange,
                  placeholder: i18next.t("Current_password"),
                }}
              />
              <InputGroup
                label={i18next.t("Password_Confirm")}
                inputProps={{
                  name: "newPassword",
                  value: formData.newPassword,
                  onChange: handleChange,
                  placeholder: i18next.t("New_password"),
                }}
              />
              <button
                className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] mt-[24px]"
                onClick={handleChangePassword}
              >
                {i18next.t("Change_Password")}
              </button>
            </Box>
          </Box>
        </Box>
      </ContentCard>
    </Box>
  );
};
