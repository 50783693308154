import { Box, Stack, Typography } from "@mui/material";
import { AffiliateRequestModal } from "./components/AffiliateRequestModal";
import { useState } from "react";
import i18next from "i18next";
import {useSelector} from "react-redux";
import {selectCurrentLanguageId} from "app/store/i18nSlice";

export const Affiliate = () => {
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  return (
    <>
      <Box className="bg-[#061041] min-h-[calc(var(--app-height)-134px)] z-10 relative" aria-label="Affiliate">
        <Box aria-label="decor" className="absolute left-0 top-0 right-0 bottom-0 overflow-hidden -z-1">
          <Box className="container h-full">
            <img
              className="absolute top-0 right-0 translate-x-[70%] -translate-y-[55%]"
              src="assets/images/affiliate/first-screen-bg-light.png"
            />
            <img
              className="absolute top-[560px] left-[30%] -translate-x-[50%] -translate-y-[50%]"
              src="assets/images/affiliate/about-bg-light.png"
            />
          </Box>
        </Box>
        <Box aria-label="content" className="relative z-1">
          <Box className="container">
            <Stack direction={{ xs: "column", lg: "row" }} justifyContent="space-between" alignItems="center">
              <Box className="px-[30px] pt-[40px] lg:py-[120px]">
                <Typography className="text-white text-[18px] xs:text-[24px] text-center lg:text-left lg:text-[50px] xl:text-[60px] font-bold">
                  {i18next.t("Become_Affiliate")}
                </Typography>
                <Typography className="text-[#42DFE3] text-[16px] xs:text-[22px] text-center lg:text-left md:text-[30px] xl:text-[40px] mt-[20px]">
                  {i18next.t("Level_up_your_profit_with_us")}
                </Typography>
                <Box className="flex justify-center lg:block mt-[100px] lg:mt-[100px]">
                  <button
                    className="animation-button uppercase text-white px-[50px] py-[12px] text-[13px] lg:text-[16px] xl:text-[18px] rounded-[6px] hover:-translate-y-[6px] duration-300 relative"
                    style={{ background: "linear-gradient(98.97deg, #28F09C 15.05%, #00BE67 71.41%)" }}
                    onClick={() => setRequestModalVisible(true)}
                  >
                    {i18next.t("Join_us_now")}
                  </button>
                </Box>
              </Box>
              <Box className="relative w-full h-[370px] overflow-hidden xs:w-[370px] xs:h-[370px] sm:w-[500px] sm:h-[500px] lg:w-[978px] lg:h-[795px] pointer-events-none flex lg:block">
                <Box className="relative w-[978px] h-[795px] min-w-[978px] min-h-[795px] scale-[0.3] xs:scale-[0.4] sm:scale-50 lg:scale-100 origin-top-left">
                  <img
                    src="assets/images/affiliate/content/shine-down-planet.png"
                    alt="content-image"
                    className="absolute"
                  />
                  <img
                    src="assets/images/affiliate/content/line1.png"
                    alt="content-image"
                    className="screen-line1 absolute w-[654px] left-[123px] bottom-[187px]"
                  />
                  <img
                    src="assets/images/affiliate/content/line2.png"
                    alt="content-image"
                    className="screen-line2 absolute w-[542px] left-[185px] bottom-[194px]"
                  />
                  <img
                    src="assets/images/affiliate/content/line3.png"
                    alt="content-image"
                    className="screen-line3 absolute w-[591px] left-[174px] bottom-[102px]"
                  />
                  <img
                    src="assets/images/affiliate/content/planet-light.png"
                    alt="content-image"
                    className="screen-light absolute w-[551px] left-[198px] bottom-[125px]"
                  />
                  <img
                    src="assets/images/affiliate/content/shine-dots.png"
                    alt="content-image"
                    className="screen-shine absolute w-[578px] left-[183px] bottom-[152px]"
                  />
                  <img
                    src="assets/images/affiliate/content/planet.png"
                    alt="content-image"
                    className="absolute w-[850px] left-[19px] top-[68px]"
                  />
                  <img
                    src="assets/images/affiliate/content/shine-dots-decor-small.png"
                    alt="content-image"
                    className="absolute screen-shine-small w-[694px] left-[113px] bottom-[184px]"
                  />
                  <img
                    src="assets/images/affiliate/content/stones-fly.png"
                    alt="content-image"
                    className="absolute screen-stones w-[841px] left-[91px] bottom-[64px]"
                  />
                  <img
                    src="assets/images/affiliate/content/ball-chip.png"
                    alt="content-image"
                    className="absolute screen-ball-chip w-[600px] left-[151px] bottom-[218px]"
                  />
                  <img
                    src="assets/images/affiliate/content/box.png"
                    alt="content-image"
                    className="absolute screen-box w-[381px] left-[205px] bottom-[91px]"
                  />
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
      <AffiliateRequestModal visible={requestModalVisible} onClose={() => setRequestModalVisible(false)} />
    </>
  );
};
