import { Box, Radio, Typography } from "@mui/material";

export const bonusItemsData = [
  {
    id: "casino",
    title: "100% up to €120",
    subtitle: "Welcome Bonus Casino",
  },
  {
    id: "sports",
    title: "100% up to €100",
    subtitle: "Welcome Bonus Sport",
  },
  {
    id: "no",
    title: "No Bonus",
    subtitle: "Continue without bonus",
  },
];

export const BonusItem = (props) => {
  const { title, subtitle, checked, onClick } = props;
  return (
    <Box
      aria-label="BonusItem"
      className="py-[16px] cursor-pointer hover:bg-[#00000010] transition-all duration-150"
      onClick={onClick}
    >
      <Box className="flex gap-[8px] items-center">
        <Radio checked={checked} readOnly sx={{ "& .MuiSvgIcon-root": { color: checked ? "green" : "#121212a0" } }} />
        <Typography className="text-[16px] data-[checked=true]:text-[green]" data-checked={checked}>
          {title}
        </Typography>
      </Box>
      <Typography className="text-[12px] text-[#7A7A7B] px-[12px]">{subtitle}</Typography>
    </Box>
  );
};
