import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import format from "date-fns/format";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import { SortTableHead } from "app/shared-components/SortTableHead/SortTableHead";
import { Collapse, Divider, Drawer, Grid, IconButton, Slider, Stack, TableHead, Tooltip, Typography } from "@mui/material";
import { Chip } from "./Chip";
import { useState } from "react";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useSystemType } from "src/app/hooks/useSystemType";
import { BETSHOP_SYSTEM } from "src/app/constants";
import { useDispatch, useSelector } from "react-redux";
import { offerCashoutCoupon, makeCashoutCoupon } from "../../store/historySlice";
import { selectUser } from "app/store/userSlice";
import toast from "react-hot-toast";
import { getUserPreference } from "src/app/main/settings/store/profileSlice";
import { dateToText } from "src/app/utils";
import {
  PopularPrinters,
  PaperStyles
} from "src/app/constants";
import { selectMarketDic } from "src/app/main/store/prematchSlice";
import { useEffect } from "react";
import ReactDOM from 'react-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const tableHeaders = [
  { id: "id", title: "Betslip ID" },
  { id: "created_at", title: "Date", wrapper: (value) => format(new Date(value), "yyyy MMM dd HH:mm") },
  { id: "coupon_type", title: "Slip Type" },
  { id: "game_type", title: "Game Type" },
  { id: "original_stake", title: "", wrapper: (value) => value.toFixed(2)  },
  { id: "currency", title: "Currency" },
  { id: "total_odds", title: "Total Odds", wrapper: (value) => value.toFixed(2) },
  { id: "total_won", title: "Total Won", wrapper: (value) => value.toFixed(2) },
  // { id: "partial_cash_amount", title: "Partial Cashout Amount", wrapper: (value) => value.toFixed(2) },
  { id: "refunded_amount", title: "Refunded Amount", wrapper: (value) => value.toFixed(2) },
  {
    id: "cancel",
    title: "Cancel",
    wrapper: () => (
      <Tooltip title="Cancel the betting" placement="left">
        <button className="hover:text-[red]">
          <DoNotDisturbOnOutlinedIcon />
        </button>
      </Tooltip>
    ),
  },
  { id: "slip_status", title: "Status", wrapper: (value) => <Chip status={value} /> },
  {
    id: "details",
    title: "Details",
    wrapper: () => (
      <IconButton>
        <RemoveRedEyeIcon className="text-[#4f46e5]" />
      </IconButton>
    ),
  },
];

export default function HistoryTable(props) {
  const { rows, headCells, handleRefresh } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [open, setOpen] = React.useState(false);
  const [couponUserDetails, setCouponUserDetails] = useState();
  const [winningRecord, setWinningRecord] = useState(null);
  const [winningRecordsVisible, setWinningRecordsVisible] = useState(false);
  const systemType = useSystemType();
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState(100)
  const user = useSelector(selectUser);
  const [selectedPrinter, setSelectedPrinter] = useState(PopularPrinters[0]);
  const [autoPrint, setAutoPrint] = useState(false);
  const marketDic = useSelector(selectMarketDic);
  const [columns, setColumns] = useState([]);

  const [cashoutAccepted, setCashoutAccepted] = useState(false);
  const [cashoutAmount, setCashoutAmount] = useState(0)
  const showAlert = (type) => {
    toast.error(`${type} not supported`);
  }

  useEffect(() => {
    dispatch(getUserPreference()).then((res) => {
      setAutoPrint(res.payload.auto_print); 
      setSelectedPrinter(PopularPrinters.find(p => p.value === res.payload.printer_type) || PopularPrinters[0]);
    });
  }, [])

  // console.log('@@', couponUserDetails)

  const md = useScreenMd();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const generateCombinations = (event, row) => {
    let combs = [];
    for (let i = 2; i < couponUserDetails?.coupon_details.length; i++) {
      let res = combinations(couponUserDetails?.coupon_details, i);
      combs = combs.concat(res);
    }
    setColumns(combs);
    console.log(combs)
  };

  const combinations = (arr, k) => {
    const result = [];

    function helper(start, combo) {
      if (combo.length === k) {
        result.push([...combo]);
        return;
      }
      for (let i = start; i < arr.length; i++) {
        combo.push(arr[i]);
        helper(i + 1, combo);
        combo.pop();
      }
    }

    helper(0, []);
    return result;
  };


  const handleColumnStatus = (column) => {
    let status = "Won";
    for (let i = 0; i < column.length; i++) {
      if (column[i].bet_status == "Waiting") {
        status = "Waiting";
        break;
      }
    }
    for (let i = 0; i < column.length; i++) {
      if (column[i].bet_status == "Lost") {
        status = "Lost";
        break;
      }
    }
    return status;
  };

  const handleColumnWinnings = (column) => {
    let winnings = 1.0;
    for (let i = 0; i < column.length; i++) {
      winnings *= column[i].odds_price;
    }
    return winnings.toFixed(2);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, row) => {
    setOpen(true);
    setCashoutAccepted(false);
    setSliderValue(100);
    setCouponUserDetails(row);
    setWinningRecord(row.coupon_details);

    setColumns([])
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrint = () => {
    const totalOdds = couponUserDetails.total_odds;
    const potentialWin = couponUserDetails.total_won;

    const printWindow = window.open('', '_blank', 'width=800,height=600');
    printWindow.document.write('<html><head><title>Print Tickets</title></head><body><div id="print-root"></div></body></html>');
    printWindow.document.close();

    ReactDOM.render(
      <PrintTickets 
        coupons={couponUserDetails} 
        betType={couponUserDetails.coupon_type} 
        totalBetAmount={couponUserDetails.original_stake} 
        totalOdds={couponUserDetails.total_odds} 
        potentialWin={couponUserDetails.total_won} 
        selectedPrinter={selectedPrinter} 
        marketDic={marketDic}
      />, 
      printWindow.document.getElementById('print-root')
    );

    if (autoPrint) {
      printWindow.focus();
      printWindow.print();
    }
  }

  const RenderedMarket = (val) => {
    let name = val?.outcome_ref_id.split(";")[1];
    if (name == "1") {
      name = "Home";
    } else if (name == "2") {
      name = "Away";
    }
    if (name == "U") {
      name = "Under";
    }
    if (name == "O") {
      name = "Over";
    }
    if(val?.outcome_ref_id.includes("Handicap") && !val?.outcome_ref_id.includes(",0")) {
      if(val?.outcome_ref_id.includes("-")) {
        if(name == "Home") name = "-" + name
        else if(name == "Away") name = "+" + name
      } else {
        if(name == "Home") name = "+" + name
        else if(name == "Away") name = "-" + name
      }
    }
    
    let prefix = marketDic && marketDic[val.market_id] ? marketDic[val.market_id].split(";")[0] : val?.outcome_ref_id.split(";")[0];

    const parts = val?.outcome_ref_id.split(";")[0].split(",");
    parts[0] = prefix;
    if (val?.outcome_ref_id.includes("%P")) {
      const cleanedParts = parts.map((part) => part.replace("%P", " "));
      prefix = cleanedParts[0] + val?.outcome_ref_id.split(';')[0].split(',')[1];
      // prefix = formatPrefix(prefix);
    } else if (val?.outcome_ref_id.split(";")[0].includes(",")) {
      prefix = parts[0];
      name = name + " " + parts[1];
    } else if (val?.outcome_ref_id.split(";")[0].includes("%")) {
      prefix = prefix.replace("%1", "Home").replace("%2", "Away");
    }
  
    return (
      <TableCell className="text-[12px]">
        {prefix} {name}
      </TableCell>
    );
  };

  const handleGetOfferClick = () => {
    const partialAmount = couponUserDetails.original_stake * sliderValue / 100.0;
    const couponId = couponUserDetails.id;
    if(!cashoutAccepted) {
      dispatch(
        offerCashoutCoupon({ partialAmount, couponId })
      ).then((res) => {
        const data = res.payload;
        console.log(res);
        if(data.can_cash_out) {
          setCashoutAccepted(true);
          setCashoutAmount(data.cash_out_amount);
        }
        if(data.status != 200 && data.data.user_error) {
          showAlert(data.data.user_error);
        }
      });
    } else {
      dispatch(
        makeCashoutCoupon({ partialAmount, couponId })
      ).then((res) => {
        const data = res.payload;
        if(data.success) {
          setCashoutAccepted(false);
          setOpen(false);
          handleRefresh();
        }
        if(data.user_error) {
          showAlert(data.user_error);
        }
      });
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => {
    if (!rows) return [];
    return stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [order, orderBy, page, rowsPerPage, rows]);

  console.log(couponUserDetails?.slip_status, 'slip status')

  const drawer = () => {
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          ".MuiPaper-root": { overflow: "visible" },
          width: { xs: "100%", md: "auto" },
        }}
      >
        <Box className="relative pb-[120px]">
          <IconButton className="absolute top-[4px] right-[4px] md:right-auto md:-left-[20px] text-white bg-[#111825] hover:bg-[#111825]" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
          <Stack spacing="12px" className="max-h-[var(--app-height)] overflow-y-auto">
            <Box aria-label="slipHeader" className="bg-[#2C374F] text-white pt-[24px] pb-[8px]">
              <Stack direction={{ md: "row"}} spacing="36px" className="px-[24px] pb-[12px]" justifyContent="space-between">
                <Typography variant="body1" sx={{ fontSize: '16px' }}>
                  <span style={{ fontSize: '18px' }}><FiberManualRecordIcon fontSize="4px" sx={{
                    color: '#4DA533',
                  }} /> Betslip # :</span>  {couponUserDetails?.id}</Typography>
                <Typography variant="body1" sx={{ fontSize: '16px' }}>
                  <span style={{ fontSize: '18px' }}><FiberManualRecordIcon fontSize="4px" sx={{
                    color: '#4DA533'
                  }} /> Date:</span> {
                  couponUserDetails ? format(new Date(couponUserDetails?.created_at), "yyyy MMM dd HH:mm") : "?"
                }</Typography>
                <Typography variant="body1" sx={{ fontSize: '16px' }}>
                  <span style={{ fontSize: '18px' }}><FiberManualRecordIcon fontSize="4px" sx={{
                    color: '#4DA533'
                  }} /> Closing:</span> </Typography>
                <Stack direction="row">
                  <Typography variant="body1" sx={{ fontSize: '16px' }}>
                    <span  style={{ fontSize: '18px' }}><FiberManualRecordIcon fontSize="4px" sx={{
                      color: '#4DA533'
                    }} /> Status:</span></Typography>
                  <Box className="ml-[8px] px-[8px] py-[2px] bg-[#4f46e5] text-white rounded-[4px]">
                    {couponUserDetails?.slip_status}
                  </Box>
                  <button
                    className="ml-[8px] bg-[#4f46e5] rounded-[4px] text-white px-[8px] py-[2px]"
                    onClick={() => { setWinningRecordsVisible((v) => !v); generateCombinations(); }}
                    style={{ visibility: couponUserDetails?.coupon_type === "System" ? "visible" : "hidden" }}
                  >
                    <RemoveRedEyeIcon fontSize="small" />
                  </button>
                </Stack>
              </Stack>
              <Divider />
              <Grid container className="px-[24px]">
                <Grid item xs={6} md={3} mt={'12px'}>
                  <Stack gap="12px">
                    <Typography>
                      Betslip Type: {couponUserDetails?.coupon_type}
                    </Typography>
                    <Typography>Live: {couponUserDetails?.game_type == "Prematch" ? "No" : "Yes"}</Typography>
                    <Typography>Total Bets: { couponUserDetails?.coupon_type === "System" ? columns.length : couponUserDetails?.coupon_details?.length } </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={3}  mt={'12px'}>
                  <Stack gap="12px">
                    <Typography>Total Odds: {couponUserDetails?.total_odds.toFixed(2)}</Typography>
                    <Typography>Total Stake: {couponUserDetails?.original_stake.toFixed(2)}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={3}  mt={'12px'}>
                  <Stack gap="12px">
                    <Typography>Partial Cashed: {couponUserDetails?.partial_cash_amount.toFixed(2)}</Typography>
                    <Typography>Cashed Out: {couponUserDetails?.refunded_amount.toFixed(2)}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs  mt={'12px'}>
                  <Stack gap="12px">
                    <Typography>Pos.Winnings: {couponUserDetails?.slip_status == "Waiting" ? "" : couponUserDetails?.total_won.toFixed(2)}</Typography>
                    <Typography>Winnings: </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box className="px-[12px] bg-[white]">
              <Box className="max-w-[700px] mx-auto">
                <Stack direction={{ md: "row" }} alignItems="center" gap="12px">
                    {couponUserDetails?.slip_status === 'Waiting' && (
                      <Stack
                        direction="row"
                        alignItems={'center'}
                        sx={{
                          width: '100%'
                        }}
                      >
                        <Box className="flex-grow pr-[24px] min-w-[300px]">
                          <Slider value={sliderValue} color="success" onChange={(e) => {setSliderValue(e.target.value)}} />
                        </Box>
                        <Box className="mr-[32px]">{user.data.currency} {couponUserDetails?.original_stake.toFixed(2)} ({sliderValue}% , {(couponUserDetails?.original_stake * sliderValue / 100).toFixed(2)})</Box>
                      </Stack>
                    )}
                  {(couponUserDetails?.slip_status == 'Waiting' && !cashoutAccepted) && (
                    <button
                      className="bg-[#4f46e5] text-white rounded-full px-[8px] py-[4px]"
                      onClick={handleGetOfferClick}
                    >
                      Get Offer for CashOut
                    </button>
                  )}
                  {cashoutAccepted && (
                    <button className="bg-[#3abb7f] text-white rounded-full px-[8px] py-[4px] " 
                    onClick={handleGetOfferClick}>
                      Accept Cashout Offer {cashoutAmount.toFixed(2)} {user.data.currency}
                    </button>
                  )}
                  {user?.role && user.role.includes("betshop_user") ? <button
                        className="bg-[#4f46e5] text-white rounded-full px-[8px] py-[4px]"
                        onClick={handlePrint}
                      >
                        Print
                    </button> : null}
                </Stack>
              </Box>
            </Box>
            {md && <Table aria-label="FinalScoreTable" stickyHeader className="">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "20px", fontSize: "12px" }}>#</TableCell>
                  <TableCell style={{ width: "65px", fontSize: "12px" }}>Type</TableCell>
                  <TableCell style={{ width: "120px", fontSize: "12px" }}>Date</TableCell>
                  <TableCell style={{ width: "325px", fontSize: "12px" }}>Event</TableCell>
                  <TableCell style={{ width: "65px", fontSize: "12px" }}>Bet Market</TableCell>
                  <TableCell style={{ width: "75px", fontSize: "12px" }}>Selection</TableCell>
                  <TableCell style={{ width: "50px", fontSize: "12px" }}>Odds</TableCell>
                  <TableCell style={{ width: "75px", fontSize: "12px" }}>Score</TableCell>
                  <TableCell style={{ width: "75px", fontSize: "12px" }}>Time</TableCell>
                  <TableCell style={{ width: "40px", fontSize: "12px" }}>Banker</TableCell>
                  <TableCell style={{ width: "100px", fontSize: "12px" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {couponUserDetails?.coupon_details?.map((val, i) => (
                  <TableRow key={i} className="h-72 cursor-pointer" hover role="checkbox" tabIndex={-1}>
                    <TableCell className="text-[12px]">{i + 1}</TableCell>
                    <TableCell className="text-[12px]">
                      {val?.bet_game_type === "Live" ? (
                        <img
                          src="assets/images/live.png"
                          alt=""
                          className="h-[40px] object-contain"
                        />
                      ) : null}
                    </TableCell>
                    <TableCell className="text-[12px]">
                      {couponUserDetails ? format(new Date(couponUserDetails?.created_at), "yyyy MMM dd HH:mm") : "?"}
                    </TableCell>
                    <TableCell className="text-[12px]">{val?.event_name}</TableCell>
                    <TableCell className="text-[12px]">{val?.outcome_ref_id.split(";")[0]}</TableCell>
                    <TableCell className="text-[12px]">{val?.outcome_ref_id.split(";")[1]}</TableCell>
                    <TableCell className="text-[12px]">{val?.odds_price.toFixed(2)}</TableCell>
                    <TableCell className="text-[12px]">{val?.score == ":" ? "0:0" : val?.score}</TableCell>
                    <TableCell className="text-[12px]">
                        {val?.bet_game_type == 'Live' && val?.tmr_update > 0 ? (() => {
                          const minutes = Math.floor(val?.tmr_update / 60);
                          const seconds = val?.tmr_update % 60;

                          const suffixPeriod = val?.sport_id == 79 ? "h":"p";

                          const period = val?.period == 0 ? "" : `${val?.period}${suffixPeriod} `;

                          return `${period}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                        })() : "-"}
                    </TableCell>
                    <TableCell className="text-[12px]">{val?.is_banker ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      <Chip status={val?.bet_status}>{val?.bet_status}</Chip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>}
            {!md && couponUserDetails?.coupon_details?.map((val, i) => (
              <Stack
                key={i}
                className="cursor-pointer"
                sx={{
                  "&>div": {
                    display: "flex",
                    alignItems: "center",
                    "&>div:first-child": {
                      minWidth: "100px"
                    }
                  }
                }}
                gap="12px"
                px="12px"
              >
                <Box className="text-[12px]">
                  <Box>No</Box>
                  <Box>{i + 1}</Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Type</Box>
                  <Box>
                    {val?.bet_game_type === "Live" ? (
                      <img
                        src="assets/images/live.png"
                        alt=""
                        className="h-[40px] object-contain"
                      />
                    ) : null}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Date</Box>
                  <Box>
                    {couponUserDetails ? format(new Date(couponUserDetails?.created_at), "yyyy MMM dd hh:mm") : "?"}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Event</Box>
                  <Box>
                    {val?.event_name}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Bet Market</Box>
                  <Box>
                    {val?.outcome_ref_id.split(";")[0]}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Selection</Box>
                  <Box>
                    {val?.outcome_ref_id.split(";")[1]}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Odds</Box>
                  <Box>
                    {val?.odds_price.toFixed(2)}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Score</Box>
                  <Box>
                    {val?.score == ":" ? "0:0" : val?.score}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Time</Box>
                  <Box>
                    {val?.bet_game_type == 'Live' && val?.tmr_update > 0 ? (() => {
                      const minutes = Math.floor(val?.tmr_update / 60);
                      const seconds = val?.tmr_update % 60;

                      const suffixPeriod = val?.sport_id == 79 ? "h":"p";

                      const period = val?.period == 0 ? "" : `${val?.period}${suffixPeriod} `;

                      return `${period}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                    })() : "-"}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Banker</Box>
                  <Box>
                    {val?.is_banker ? "Yes" : "No"}
                  </Box>
                </Box>
                <Box>
                  <Box>Status</Box>
                  <Chip status={val?.bet_status}>{val?.bet_status}</Chip>
                </Box>
              </Stack>
            ))}
            <Box>
            {columns.map((column) => {
              return (
                <Collapse in={winningRecordsVisible} className="p-[12px]">
                  <Box
                    aria-label="SystemColumns"
                    className="border rounded-[8px] p-[12px]"
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1">{`System Columns: ${column.length}/${couponUserDetails.coupon_details.length}`}</Typography>
                      <Typography variant="body1">{`Closing: `}</Typography>
                      <Typography variant="body1">{`Status: ${handleColumnStatus(
                        column
                      )}`}</Typography>
                      <Stack direction="row" spacing="8px">
                        <Typography>Winnings: </Typography>
                        <Typography
                          variant="body1"
                          className="text-white bg-[#6ebd6e] rounded-[4px] px-[4px]"
                        >
                          {handleColumnWinnings(column)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Grid container className="mt-[12px]">
                      <Grid item xs={3}>
                        <Typography variant="body1">{`Bestslip Type: System`}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body1">{`Total Odds: ${handleColumnWinnings(
                          column
                        )}`}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body1">{`Bonus Stake: ${couponUserDetails.stake}`}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body1">{`Possible Winnings: ${
                          handleColumnWinnings(column) * couponUserDetails.stake
                        }`}</Typography>
                      </Grid>
                    </Grid>
                    <Table
                      stickyHeader
                      className="mt-[12px]"
                      aria-labelledby="tableTitle"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ width: "20px", fontSize: "12px" }}
                          >
                            #
                          </TableCell>
                          <TableCell
                            style={{ width: "60px", fontSize: "12px" }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            style={{ width: "125px", fontSize: "12px" }}
                          >
                            Event
                          </TableCell>
                          <TableCell
                            style={{ width: "65px", fontSize: "12px" }}
                          >
                            Bet Market
                          </TableCell>
                          <TableCell
                            style={{ width: "75px", fontSize: "12px" }}
                          >
                            Selection
                          </TableCell>
                          <TableCell
                            style={{ width: "50px", fontSize: "12px" }}
                          >
                            Odds
                          </TableCell>
                          <TableCell
                            style={{ width: "40px", fontSize: "12px" }}
                          >
                            Live
                          </TableCell>
                          <TableCell
                            style={{ width: "80px", fontSize: "12px" }}
                          >
                            Final Score
                          </TableCell>
                          <TableCell
                            style={{ width: "100px", fontSize: "12px" }}
                          >
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {column?.map((val, i) => (
                          <TableRow
                            key={i}
                            className="h-72 cursor-pointer"
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell className="text-[12px]">
                              {i + 1}
                            </TableCell>
                            <TableCell className="text-[12px]">
                              {couponUserDetails?.created_at
                                ? format(
                                    new Date(couponUserDetails.created_at),
                                    "yyyy MMM dd"
                                  )
                                : "Invalid Date"}
                            </TableCell>
                            <TableCell className="text-[12px]">
                              {val.event_name}
                            </TableCell>
                            <TableCell className="text-[12px]">
                              {val?.outcome_ref_id.split(":")[0]}
                            </TableCell>
                            <TableCell className="text-[12px]">
                              {val?.outcome_ref_id.split(":")[1]}
                            </TableCell>
                            <TableCell className="text-[12px]">
                              {val.odds_price.toFixed(2)}
                            </TableCell>
                            <TableCell className="text-[12px]">
                              {val.bet_game_type}
                            </TableCell>
                            <TableCell className="text-[12px]">
                              {val.FinalScore}
                            </TableCell>
                            <TableCell>
                              <Chip status={val?.bet_status}>
                                {val?.bet_status}
                              </Chip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              );
            })}
            </Box>
          </Stack>
        </Box>
      </Drawer>
    )
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper className="w-full mb-[24px] overflow-hidden rounded-0">
        {md && (
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <SortTableHead
                headCells={headCells.filter(h => systemType === BETSHOP_SYSTEM || h.id !== "cancel")}
                order={order} orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className="cursor-pointer min-h-[48px]"
                    >
                      <TableCell sx={{ py: "4px", fontSize: "12px" }}>{row.id}</TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px", px: 0 }}>
                        {format(new Date(row.created_at), "yyyy MMM dd HH:mm")}
                      </TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px" }}>{row.coupon_type}</TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px" }}>{row.game_type}</TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px", textAlign: "right" }}>
                        {row.original_stake.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px" }}>{row.currency}</TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px", textAlign: "right" }}>
                        {row.total_odds.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px", textAlign: "right" }}>
                        {row.slip_status == "Waiting" ? "" : row.total_won.toFixed(2)}
                      </TableCell>
                      {/* <TableCell sx={{ py: "4px", fontSize: "12px", textAlign: "right" }}>
                        {row.partial_cash_amount.toFixed(2)}
                      </TableCell> */}
                      <TableCell sx={{ py: "4px", fontSize: "12px", textAlign: "right" }}>
                        {row.refunded_amount.toFixed(2)}
                      </TableCell>
                      {systemType === BETSHOP_SYSTEM && <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                        <Tooltip title="Cancel the betting" placement="left">
                          <button className="hover:text-[red]">
                            <DoNotDisturbOnOutlinedIcon />
                          </button>
                        </Tooltip>
                      </TableCell>}
                      <TableCell sx={{ py: "4px" }}>
                        <Chip status={row.slip_status} />
                      </TableCell>
                      <TableCell sx={{ py: "4px" }}>
                        <IconButton>
                          <RemoveRedEyeIcon className="text-[#4f46e5]" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!md && (
          <Box className="py-[12px]">
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <Box key={index} className="px-[12px] border-b last:border-b-0 py-[8px]">
                  {tableHeaders.map(({ id, title, wrapper }) => {
                    return (
                      <Box
                        key={id}
                        className="flex items-center w-full py-[4px]"
                        onClick={event => handleClick(event, row)}
                      >
                        <Box className="flex-1">{title}</Box>
                        <Box className="flex-1">{wrapper ? wrapper(row[id]) : row[id]}</Box>
                      </Box>
                    )
                  })}
                </Box>
              );
            })}
          </Box>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {drawer()}
    </Box>
  );
}

const PrintTickets = ({ coupons, betType, totalBetAmount, totalOdds, potentialWin, selectedPrinter, marketDic  }) => {
  const selectedPaperStyle = PaperStyles[selectedPrinter.value] || PaperStyles.A4;

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', ...selectedPaperStyle, fontSize: '14px' }}>
      <h2>Betslip</h2>
      <div>
        <p style={{margin: '0px'}}><strong>Bet Type:</strong> {betType}</p>
        <p style={{margin: '0px'}}><strong>Total Stake:</strong> {totalBetAmount}</p>
        <p style={{margin: '0px'}}><strong>Total Odds:</strong> {totalOdds}</p>
        <p style={{margin: '0px'}}><strong>Possible Winnings:</strong> {potentialWin}</p>
        <h4>Tickets:</h4>
        {coupons?.coupon_details.map((coupon, index) => {
          
          let name = coupon.outcome_ref_id.split(";")[1];
          if (name == "1") {
            name = "Home";
          } else if (name == "2") {
            name = "Away";
          }
          if (name == "U") {
            name = "Under";
          }
          if (name == "O") {
            name = "Over";
          }
          if(coupon.outcome_ref_id.includes("Handicap") && !coupon.outcome_ref_id.includes(",0")) {
            if(coupon.outcome_ref_id.includes("-")) {
              if(name == "Home") name = "-" + name
              else if(name == "Away") name = "+" + name
            } else {
              if(name == "Home") name = "+" + name
              else if(name == "Away") name = "-" + name
            }
          }
          
          let prefix = marketDic && marketDic[coupon.market_id] ? marketDic[coupon.market_id].split(";")[0] : coupon.outcome_ref_id.split(";")[0];

          const parts = coupon.outcome_ref_id.split(";")[0].split(",");
          parts[0] = prefix;
          if (coupon.outcome_ref_id.includes("%P")) {
            const cleanedParts = parts.map((part) => part.replace("%P", " "));
            prefix = cleanedParts[0] + coupon.outcome_ref_id.split(';')[0].split(',')[1];
            // prefix = formatPrefix(prefix);
          } else if (coupon.outcome_ref_id.split(";")[0].includes(",")) {
            prefix = parts[0];
            name = name + " " + parts[1];
          } else if (coupon.outcome_ref_id.split(";")[0].includes("%")) {
            prefix = prefix.replace("%1", "Home").replace("%2", "Away");
          }
          return <div key={index} style={{ marginBottom: '10px', borderBottom: '1px solid #ccc' }}>
              <p style={{margin: '0px'}}><strong>Event:</strong> {coupon.event_name}</p>
              <p style={{margin: '0px'}}><strong>Date:</strong> {dateToText(coupons.created_at)}</p>
              <p style={{margin: '0px'}}><strong>Market:</strong> {prefix + ": " + name}</p>
              <p style={{margin: '0px'}}><strong>Odds:</strong> {coupon.odds_price.toFixed(2)}</p>
              <p style={{margin: '0px'}}><strong>Stake:</strong> {coupons.original_stake.toFixed(2)}</p>
            </div>
      })}
      </div>
    </div>
  );
};
