import { Box } from "@mui/material";
import { PromotionCategoryLink } from "./components/PromotionCategoryLink/PromotionCategoryLink";
import { Outlet, useLocation } from "react-router-dom";
import FooterLayout1 from "app/theme-layouts/layout1/components/FooterLayout1";
import { HorizontalScroll } from "app/shared-components/HorizontalScroll";

const categoryList = [
  {
    id: "popular",
    title: "Popular",
    icon: "assets/images/pages/casino/popular.svg",
    path: "/promotions/popular",
  },
  {
    id: "welcome",
    title: "WELCOME",
    icon: "assets/images/pages/casino/all.svg",
    path: "/promotions/welcome",
  },
  {
    id: "sports",
    title: "Sports",
    icon: "assets/images/navItems/sports.svg",
    path: "/promotions/sports",
  },
  {
    id: "casino",
    title: "Casino",
    icon: "assets/images/navItems/casino.svg",
    path: "/promotions/casino",
  },
  {
    id: "live_casino",
    title: "Live Casino",
    icon: "assets/images/navItems/live-casino.svg",
    path: "/promotions/live-casino",
  },
  {
    id: "poker",
    title: "Poker",
    icon: "assets/images/pages/casino/poker.svg",
    path: "/promotions/poker",
  },
  {
    id: "games",
    title: "Games",
    icon: "assets/images/pages/casino/new.svg",
    path: "/promotions/games",
  },
  {
    id: "others",
    title: "Others",
    icon: "assets/images/navItems/more.svg",
    path: "/promotions/others",
  },
];

export const Promotion = () => {
  const location = useLocation();

  return (
    <Box aria-label="Promotions" className="max-h-[inherit] overflow-y-auto">
      <Box className="px-[8px]">
        <HorizontalScroll>
          <Box className="flex gap-[4px] mt-[8px]">
            {categoryList.map((category) => (
              <PromotionCategoryLink key={category.id} {...category} selected={location.pathname === category.path} />
            ))}
          </Box>
        </HorizontalScroll>
      </Box>
      <Box className="mt-[12px]">
        <Outlet />
        <FooterLayout1 />
      </Box>
    </Box>
  );
};
