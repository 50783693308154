import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { ONLINE_SYSTEM } from "src/app/constants";
import { useSystemType } from "src/app/hooks/useSystemType";

const DropdownButton = ({ title, balance, currency, main = false }) => {
  return (
    <Box
      className="w-full flex justify-between items-center px-[12px] py-[6px]"
      sx={{ color: main ? "white" : "#ccc" }}
    >
      <Typography>{`${title} (${currency})`}</Typography>
      <Typography>{(+balance).toFixed(2)}</Typography>
    </Box>
  );
};

export const BalanceDropdown = ({ balance, currency }) => {
  const [menu, setMenu] = useState(null);
  const systemType = useSystemType();

  return (
    <Box className="relative">
      <button
        className="hidden sm:flex justify-center items-center gap-[12px] h-[40px] px-[4px] sm:pl-[24px] sm:pr-[16px] rounded-[12px] text-white bg-[#ffffff10] hover:bg-[#ffffff30] active:bg-[#ffffff50]"
        onClick={(e) => setMenu(e.currentTarget)}
      >
        {`${(+balance).toFixed(2)}(${currency})`}
        <KeyboardArrowDownIcon className="hidden sm:block" />
      </button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={() => setMenu(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "max-h-[400px] !duration-75 text-white mt-[12px] bg-transparent",
        }}
      >
        <Box className="flex flex-col w-[250px] bg-[#2C374F]">
          <DropdownButton title="Main currency" balance={balance} main={true} currency={currency} />
          <Box className="h-[1px] border-t border-t-[#ffffff80] w-full"></Box>
          {systemType === ONLINE_SYSTEM && (
            <>
              <DropdownButton title="Casino currency" balance={0} currency={currency} />
              <DropdownButton title="Sports currency" balance={0} currency={currency} />
            </>
          )}
        </Box>
      </Popover>
    </Box>
  );
};
