import React, { useState, useEffect } from "react";
import { getWithdrawBankTransfer, getBankList, getWithdrawJetpay } from "../main/store/uiSlice";
import { useDispatch } from "react-redux";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { SelectGroup } from "../main/settings/component/InputGroups";

const JetpayForm = () => {
  const [bank, setBank] = useState({ id: 9, name: "Akbank" });
  const [iban, setIban] = useState("");
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();
  const [bankList, setBankList] = useState([
    { id: 1, name: "Akbank" },
    { id: 2, name: "Denizbank" },
    { id: 3, name: "Finansbank" },
    { id: 4, name: "Halk Bankası" },
    { id: 5, name: "İş Bankası" },
    { id: 6, name: "TEB Bankası" },
    { id: 7, name: "Yapı Kredi Bankası" },
    { id: 8, name: "Ziraat Bankası" },
    { id: 9, name: "Vakıfbank" },
    { id: 10, name: "ING Bank" },
    { id: 11, name: "Şeker Bankası" },
    { id: 12, name: "PTT Bank" },
    { id: 13, name: "Albaraka Türk Bankası" },
    { id: 14, name: "Kuveyt Türk Bankası" },
    { id: 15, name: "Enpara" },
    { id: 16, name: "Türkiye Finans Katılım" },
    { id: 17, name: "Garanti Bank" },
    { id: 18, name: "Fiba Bank" },
    { id: 19, name: "Türkiye Ziraat Katılım Bankası" },
    { id: 20, name: "BURGAN BANK" },
    { id: 21, name: "Alternatif Bank A.Ş" },
    { id: 22, name: "Anadolu Bank A.Ş" },
    { id: 23, name: "City Bank" },
    { id: 24, name: "HSBC Bank A.Ş" },
    { id: 25, name: "Odea Bank" },
    { id: 26, name: "Türkiye Emlak Katılım Bankası" },
    { id: 27, name: "Vakıf Katılım Bankası" },
    { id: 28, name: "Aktif Yatırım Bankası" },
    { id: 29, name: "Adabank" },
    { id: 30, name: "Kuveyt Türk Evkaf Finans Kurumu" },
    { id: 31, name: "Asya Katılım Bankası A.Ş" },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      getWithdrawJetpay({
        amount: amount,
        iban: iban,
        bank: bank
      })
    ).then(async (response) => {
      const result = await response.json();
      if (result.code === 200) {
        alert("Withdrawal request accepted. Status changed to Pending.");
      } else {
        alert(`Withdrawal request rejected: ${result.message}`);
      }
    });
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Bank Transfer Withdrawal Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <div>
          <select
            className="w-full rounded-8 h-40 overflow-hidden my-8 bg-black"
            value={bank}
            onChange={(e) => setBank(e.target.value)}
          >
            {bankList.map((bank) => (
              <option key={bank.id} value={bank.id}>
                {bank.name}
              </option>
            ))}
          </select>
        </div>

        <TextField
          label="IBAN"
          fullWidth
          value={iban}
          onChange={(e) => {
            setIban(e.target.value);
          }}
          margin="normal"
          required
        />

        <TextField
          label="Amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => {
            console.log(e);
            setAmount(e.target.value);
          }}
          margin="normal"
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Submit Withdrawal Request
        </Button>
      </form>
    </Box>
  );
};

export default JetpayForm;
