import { Box, Typography } from "@mui/material";
import { ContentCard } from "../component/ContentCard";
import { useState } from "react";
import { Hr } from "../component/InputGroups";
import { useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail } from "../store/profileSlice";
import { selectUser } from 'app/store/userSlice';
import { useSystemType } from "src/app/hooks/useSystemType";
import { BETSHOP_SYSTEM } from "src/app/constants";

export const Verification = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [value, onChange] = useState(new Date());
  const [type, setType] = useState("identity");
  const fileInputRef = useRef(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const systemType = useSystemType();

  const handleFileChange = (event) => {
    // Handle the file input change event
    const file = event.target.files[0];
    // console.log("Selected file:", file);
  };

  const handleVerify = () => {
    dispatch(verifyEmail());
  };

  if (systemType === BETSHOP_SYSTEM) {
    location.href = "/";
    return null;
  }

  return (
    <Box aria-label="Verification" className="flex flex-col-reverse md:flex-row gap-[12px]">
      <ContentCard title="Know Your Customer" icon="assets/images/pages/settings/verification.svg">
        <Box className="bg-white rounded-b-[12px] p-[12px]">
          <Box className="flex flex-col gap-[12px] max-w-[1000px] mx-auto min-h-[500px]">
            <Box className="font-bold text-[16px]">Email Verification</Box>
            <Box sx={{ color: user.data.email_verified ? "green" : "red" }}>{user.data.email_verified ? "Verified" : "Not Verified"}</Box>
            {!user.data.email_verified && (
              <button
                className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce]"
                onClick={handleVerify}
              >
                Verify
              </button>
            )}
            <Hr />
            <Box className="">
              Please upload copies of documents in the format supported by our website (JPG, JPEG, PNG). The file size
              must not exceed 5 MB and only 10 files are allowed per one upload.
            </Box>
            <Hr />
            <Box className="font-bold text-[16px]">
              {type === "identity" && "Proof of identity"}
              {type === "address" && "Proof of address"}
              {type === "deposit" && "Proof of deposit"}
            </Box>
            <Box className="flex flex-col gap-[8px]">
              {type === "identity" && (
                <>
                  <Typography>Passport</Typography>
                  <Typography>Driver's license</Typography>
                  <Typography>National ID</Typography>
                </>
              )}
              {type === "address" && (
                <>
                  <Typography>Bank statement</Typography>
                  <Typography>Utility bill</Typography>
                  <Typography>Payment for mobile service</Typography>
                </>
              )}
              {type === "deposit" && (
                <>
                  <Typography>Must display the company name</Typography>
                  <Typography>Must display the amount paid</Typography>
                </>
              )}
            </Box>
            <Box className="flex items-center gap-[24px]">
              <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
              <button
                className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce]"
                onClick={() => fileInputRef.current?.click()}
              >
                Upload Document(s)
              </button>
              {/* <button className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce]">
                Upload Document(s)
              </button> */}
            </Box>
          </Box>
        </Box>
      </ContentCard>
      <Box className="flex flex-col gap-[24px]">
        <button
          className="p-[12px] flex flex-col gap-[12px] shadow-md hover:shadow-lg hover:bg-[#00000030] rounded-[12px] max-w-[420px] text-left"
          onClick={() => setType("identity")}
        >
          <Typography>Proof of identity</Typography>
          <Hr />
          <Typography className="text-[12px]">
            Please make sure that all data (name, date of birth, citizenship) completely matches the data in your
            account, the document is valid and contains a photo. We accept:
          </Typography>
          <Hr />
          <Box className="flex flex-col gap-[6px]">
            <Typography>Passport</Typography>
            <Typography>Driver's license</Typography>
            <Typography>National ID</Typography>
          </Box>
        </button>
        <button
          className="p-[12px] flex flex-col gap-[12px] shadow-md hover:shadow-lg hover:bg-[#00000030] rounded-[12px] max-w-[420px] text-left"
          onClick={() => setType("address")}
        >
          <Typography>Proof of address</Typography>
          <Hr />
          <Typography className="text-[12px]">
            A photo or a screenshot of a document confirming the current address of residence. The document must not be
            older than 90 days (3 months). We accept:
          </Typography>
          <Hr />
          <Box className="flex flex-col gap-[6px]">
            <Typography>Bank statement</Typography>
            <Typography>Utility bill</Typography>
            <Typography>Payment for mobile service</Typography>
          </Box>
        </button>
        <button
          className="p-[12px] flex flex-col gap-[12px] shadow-md hover:shadow-lg hover:bg-[#00000030] rounded-[12px] max-w-[420px] text-left"
          onClick={() => setType("deposit")}
        >
          <Typography>Proof of deposit</Typography>
          <Hr />
          <Typography className="text-[12px]">
            Screenshot or photo from online bank, bank statement, e-Wallet page, etc. showing the deposit. The document:
          </Typography>
          <Hr />
          <Box className="flex flex-col gap-[6px]">
            <Typography>Must display the company name</Typography>
            <Typography>Must display the amount paid</Typography>
          </Box>
        </button>
      </Box>
    </Box>
  );
};
