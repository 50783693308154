import { Box, Divider, MenuItem, Select, Stack } from "@mui/material"
import { Cashback } from "./Cashback"
import { useState } from "react"
import { BonusTable } from "./BonusTable"

const selectItems = [
  { label: "Active Bonus", value: "active_bonus" },
  { label: "Bonus History", value: "bonus_history" },
]

export const Bonus = () => {
  const [selection, setSelection] = useState(selectItems[0].value);

  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
  }
  
  const handleCasinoCashbackClick = () => {

  }

  const handleSportCashbackClick = () => {

  }
  
  return (
    <Box aria-label="Bonus">
      <Stack
        direction={{ md: "row" }}
        borderRadius="24px"
        p="24px"
        bgcolor="#2c374f"
      >
        <Cashback
          title="Casino cashback"
          amount="$0.00"
          onClaim={handleCasinoCashbackClick}
        />
        <Box px="24px">
          <Divider
            orientation="vertical"
          />
        </Box>
        <Cashback
          title="Sport cashback"
          amount="$0.00"
          onClaim={handleSportCashbackClick}
        />
      </Stack>
      <Box marginTop="24px">
        <Select
          value={selection}
          onChange={handleSelectionChange}
        >
          {selectItems.map(item => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box mt="16px">
        <BonusTable />
      </Box>
    </Box>
  )
}