import i18n from 'i18next';
import jwtServiceConfig from "./app/auth/services/jwtService/jwtServiceConfig";
import FSBackend from 'i18next-fs-backend';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
// import settingsConfig from './app/configs/settingsConfig';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            'Welcome to React': 'Welcome to React and react-i18next',
        },
    },
};

const api_domain = jwtServiceConfig.apiUrl;

/*const preload = settingsConfig.languages.filter(lang => lang.id)
console.log('preload', preload)*/


const config = {
    // initImmediate: true,
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    preload: [ // Need to change this to settingsConfig.languages now getting error
        'en',
        'tr',
        // 'ar',
        // 'es',
        // 'he',
        // 'pt',
        // 'fr',
        // 'ja',
        // 'ko',
        // 'zh',
        // 'th',
        // 'vi',
        // 'hi',
        // 'de',
        // 'it',
        // 'no',
        // 'sv',
        // 'fi',
        // 'da',
        // 'ptbr',
        // 'mn',
    ],
    ns: ['translation'],
    defaultNS: 'translation',
    keySeparator: false,
    backend: {
        backends: [
            HttpBackend,
            FSBackend
        ],
        backendOptions: [{
            loadPath: api_domain + 'dictionary/languageId/{{lng}}'
        }]
    },
    react: {
        useSuspense: false,
    }
}

i18n
    .use(ChainedBackend)
    .init(config, (err, t) => {
        if (err) return console.error(err)

    });

export default i18n;
