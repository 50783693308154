import { Box, Typography, useTheme } from "@mui/material"
import { HorizontalScroll } from "app/shared-components/HorizontalScroll";
import { LoadingBox } from "app/shared-components/LoadingBox";
import SportsOddsHeader from "../../sports/components/SportsOddsHeader";
import SportEvent from "../../sports/components/SportEvent";
import { selectSportsList, selectTournamentStatus, getDictionaryBySport } from "../../store/tournamentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { LeagueButton } from "../Dashboard";
import { getTopLeagues } from "../../store/topLeaguesSlice";
import { useEffect } from "react";
import { useRef } from "react";
import { getPrematchesByLeague, saveMarketDic } from "../../store/prematchSlice";
import { useIsLive } from "src/app/hooks/useIsLive";
import { sendLiveOdds } from "../../sports/grpcClient";
import { updateSingleTicket, updateTickets } from "../../store/ticketSlice";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { selectCurrentLanguage } from "app/store/i18nSlice";

const WhiteBoard = ({ title, children, onMore }) => {
  const theme = useTheme();
  
  return (
    <Box
      className="p-[16px] md:p-[24px] flex flex-col rounded-[8px] shadow-md"
      aria-label="WhiteBoard"
      bgcolor={theme.palette.background.paper}
    >
      <Box className="flex justify-between items-center">
        <Typography className="text-[20px] font-bold">{title}</Typography>
        <button className="text-[#ec622b] uppercase p-[4px] rounded-md font-500 text-[14px] hover:bg-[#eee] active:bg-[#ddd]" onClick={onMore}>
          {i18next.t("MORE")}
        </button>
      </Box>
      <Box className="mt-[20px]">{children}</Box>
    </Box>
  );
};

const football_id = 79;

export const BettingsBox = ({ title, betType, onMore }) => {
  const sportList = useSelector(selectSportsList);
  const tournamentStatus = useSelector(selectTournamentStatus);
  const [events, setEvents] = useState([]);
  const eventsRef = useRef([]);
  const [selectedSport, setSelectedSport] = useState({ id: football_id });
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLive } = useIsLive();
  const grpcClientStream = useRef(null); // Store the stream reference
  const currentLanguage = useSelector(selectCurrentLanguage);
 
  useEffect(() => {
    setEvents([]);
    for (const league of (selectedLeagues || [])) {
      const { sport_id, country_id, id } = league;
      dispatch(getPrematchesByLeague({ betType, sport_id, country_id, tournament_id: id })).then(res => {
        setEvents(e => [...e, ...(res.payload || [])]);
        eventsRef.current = res.payload;
      })
    }
  }, [selectedLeagues])

  useEffect(() => {
    // dispatch(getPrematch({ sport_id: selectedSport.id, type: isLive ? "Live" : "unplayed" })).then((res) => {});

    // Cleanup the previous stream if it exists
    if (grpcClientStream.current && grpcClientStream.current.cancel) {
      grpcClientStream.current.cancel();
      grpcClientStream.current = null;
    }

    grpcClientStream.current = sendLiveOdds(
      (data) => {
        if (!eventsRef.current || eventsRef.current.some(prevEvent => !prevEvent)) return;
        if(data.odds == undefined || data.odds.oddsList == undefined || data.odds.oddsList.length == 0) {
          if(data.match == undefined || data.match.matchInfo == undefined) return;
          setEvents((prevEvents) => {
            const updatedEvents = prevEvents.map((prevEvent) => {
              if (!prevEvent) return prevEvent;
              if (prevEvent.sportEvent.reference_id == data.match.id) {
                let {home_score, away_score, round_info} = prevEvent.sportEvent;
                if(data.match.matchInfo && data.match.matchInfo != "") {
                  const decodedBytes = atob(data.match.matchInfo);
                  const decodedStr = decodeURIComponent(escape(decodedBytes));
                  const decodedJson = JSON.parse(decodedStr);
                  if(decodedJson.awayScore) away_score = decodedJson.awayScore
                  if(decodedJson.homeScore) home_score = decodedJson.homeScore
                  if(decodedJson.scoreInfo) round_info = decodedJson.scoreInfo
                  // console.log("{home_score, away_score, round_info}",data.odds.gameInfo, {home_score, away_score, round_info})
                  // console.log('grpc', decodedJson);
                }
                return {
                  ...prevEvent,
                  sportEvent: {
                    ...prevEvent.sportEvent,
                    home_score, away_score, round_info,
                  },
                }
              }
              return prevEvent;
            })
            if (JSON.stringify(prevEvents) === JSON.stringify(updatedEvents)) {
              return prevEvents; // No changes, so return the previous state
            }
            return updatedEvents;
          })
          return;
        }

        if (data.odds.oddsList.length == 0 && data.odds.groupId != undefined && data.odds.groupId != 0) {

          setEvents((prevEvents) => {
            if (!prevEvents) return prevEvents;
            const updatedEvents = prevEvents.map((prevEvent) => {
              if (!prevEvent) return prevEvent;
              if (prevEvent.sportEvent.reference_id === data.odds.matchId) {
                let {tmr = false, tmr_running, tmr_update, tmr_second} = prevEvent.sportEvent;
                if(data.odds.gameInfo && data.odds.gameInfo != "") {
                  const decodedBytes = atob(data.odds.gameInfo);
                  const decodedStr = decodeURIComponent(escape(decodedBytes));
                  const decodedJson = JSON.parse(decodedStr);
                  if (decodedJson.tmr) tmr = decodedJson.tmr;
                  if (decodedJson.tmrRunning) tmr_running = decodedJson.tmrRunning;
                  // if (decodedJson.tmrUpdate) tmr_update = decodedJson.tmrUpdate;
                  if (decodedJson.tmrSecond) tmr_second = decodedJson.tmrSecond;
                  // console.log("{home_score, away_score, round_info}",data.odds.gameInfo, {home_score, away_score, round_info})
                  // console.log('decoded', decodedJson)
                }

                let ticketDataArray = [];
                let newOutcome = prevEvent.outcome.map((outcome) => {
                  if (outcome.group_id === data.odds.groupId) {
                    let ticketData = {
                      event_reference_id: data.odds.matchId,
                      outcome_reference_id: outcome.outcome_id,
                      odds: outcome.odds,
                      active: false,
                    };
                    // dispatch(updateSingleTicket(ticketData));
                    ticketDataArray.push(ticketData)
                    // console.log("updated", odd)
                    return {
                      ...outcome,
                      active: false,
                    };
                  }
                  return outcome;
                })
                dispatch(updateTickets(ticketDataArray)); 
                
                return {
                  ...prevEvent,
                  sportEvent: {
                    ...prevEvent.sportEvent,
                    tmr, tmr_running, /* tmr_update, */ tmr_second,
                  },
                  outcome: newOutcome,
                };
              }
              // setEvent(prevEvent);
              return prevEvent;
            })
            if (JSON.stringify(prevEvents) === JSON.stringify(updatedEvents)) {
              return prevEvents; // No changes, so return the previous state
            }
            return updatedEvents;
          })
        }

        setEvents((prevEvents) => {
          if (!prevEvents) return prevEvents;
          const updatedEvents = prevEvents.map((prevEvent) => {
            if (!prevEvent) return prevEvent;
            if (prevEvent.sportEvent.reference_id === data.odds.matchId) {
              let {tmr = false, tmr_running, tmr_update, tmr_second} = prevEvent.sportEvent;
              if(data.odds.gameInfo && data.odds.gameInfo != "") {
                const decodedBytes = atob(data.odds.gameInfo);
                const decodedStr = decodeURIComponent(escape(decodedBytes));
                const decodedJson = JSON.parse(decodedStr);
                if (decodedJson.tmr) tmr = decodedJson.tmr;
                if (decodedJson.tmrRunning) tmr_running = decodedJson.tmrRunning;
                // if (decodedJson.tmrUpdate) tmr_update = decodedJson.tmrUpdate;
                if (decodedJson.tmrSecond) tmr_second = decodedJson.tmrSecond;
                // console.log("{home_score, away_score, round_info}",data.odds.gameInfo, {home_score, away_score, round_info})
                // console.log('decoded', decodedJson)
              }
              let ticketDataArray = [];
              let newOutcome = prevEvent.outcome.map((outcome) => {
                if (outcome.group_id === data.odds.groupId) {
                  let ticketData = {
                    event_reference_id: data.odds.matchId,
                    outcome_reference_id: outcome.outcome_id,
                    odds: outcome.odds,
                    active: false,
                  };
                  // dispatch(updateSingleTicket(ticketData));
                  ticketDataArray.push(ticketData)
                  // console.log("updated", odd)
                  return {
                    ...outcome,
                    active: false,
                  };
                }
                return outcome;
              })

              newOutcome = newOutcome.map((outcome) => {
                const odd = data.odds.oddsList.find( odd => outcome.reference_id === odd.referenceId)
                if (odd) {
                  let ticketData = {
                    event_reference_id: odd.gameId,
                    outcome_reference_id: odd.referenceId,
                    odds: odd.odds,
                    active: odd.active,
                  };
                  // dispatch(updateSingleTicket(ticketData));
                  ticketDataArray.push(ticketData)
                  // console.log("updated", odd)
                  return {
                    ...outcome,
                    odds: odd.odds,
                    trend: outcome.odds > odd.odds ? "down" : "up",
                    active: odd.active,
                  };
                }
                return outcome;
              })
              dispatch(updateTickets(ticketDataArray)); 
              data.odds.oddsList.forEach(odd => {
                if (!prevEvent.outcome.find( outcome => outcome.reference_id == odd.referenceId )) {
                  newOutcome.push(
                    {
                      "reference_id": odd.referenceId,
                      "event_id": odd.gameId,
                      "market_id": odd.marketId,
                      "outcome_id": odd.outcomeId,
                      "outcome_order": odd.outcomeOrder,
                      "group_id": odd.groupId,
                      "collection_info_id": odd.collectionInfoId,
                      "name": odd.name,
                      "odds": odd.odds,
                      "active": odd.active
                    },
                  )
                  // console.log("new odd added", odd)
                } 
              })

              console.log("newOutcome", newOutcome)

              return {
                ...prevEvent,
                sportEvent: {
                  ...prevEvent.sportEvent,
                  tmr, tmr_running, /* tmr_update, */ tmr_second,
                },
                outcome: newOutcome,
              };
            }
            // setEvent(prevEvent);
            return prevEvent;
          })
          if (JSON.stringify(prevEvents) === JSON.stringify(updatedEvents)) {
            return prevEvents; // No changes, so return the previous state
          }
          return updatedEvents;
        })
      },
      (status, statusMessage) => {
        console.log("Stream ended with status:", status, "and message:", statusMessage);
      }
    );

    // Cleanup the stream when the component is unmounted
    return () => {
      if (grpcClientStream.current && grpcClientStream.current.cancel) {
        grpcClientStream.current.cancel();
        grpcClientStream.current = null;
      }
    };
  }, [selectedSport, isLive])

  const handleEventClick = (event) => {
    navigate(`/sports/detail/${event.sportEvent.reference_id}${isLive ? "?live=true" : ""}`);
  };

  useEffect(() => {
    dispatch(getTopLeagues({ languageId: 'en', sport_id: selectedSport.id, betType })).then(res => {
      setSelectedLeagues(res.payload)
    })
  }, [selectedSport.id, betType])

  const handleSportButtonClick = (sport) => {
    setSelectedSport(sport);
  }

  useEffect(() => {
    let data = {
      type: "market",
      langId: currentLanguage.id,
      sport: "football",
    };
    dispatch(getDictionaryBySport(data)).then((res) => {
      const marketDicMapping = res.payload ? res.payload.reduce((acc, item) => {
        acc[item.id] = item.translation;
        return acc;
      }, {}) : {};
      dispatch(saveMarketDic(marketDicMapping));
    });
  }, [currentLanguage])

  return (
    <WhiteBoard title={title} onMore={onMore}>
      <Box className="flex flex-col gap-[16px]">
        <HorizontalScroll>
          <Box className="flex gap-[8px] md:gap-[12px]">
            {sportList.map((sport) => (
              <LeagueButton
                aria-label="leagueButton"
                key={sport.id}
                selected={selectedSport.id === sport.id}
                label={sport.name}
                icon={sport.icon}
                onClick={() => handleSportButtonClick(sport)}
              />
            ))}
          </Box>
        </HorizontalScroll>

        {tournamentStatus !== "fulfilled" && (
          <Box className="w-full min-h-[150px] text-center mt-[50px]">
            <LoadingBox />
          </Box>
        )}

        {tournamentStatus === "fulfilled" && (
          <Box aria-label="events-box" className="mt-[8px] w-full">
            {selectedLeagues.slice(0, 2).map((league) => {
              const leagueEvents = events.filter((e) => e.sportEvent.tournament_id == league.id);
              return leagueEvents.length > 0 ? (
                <div key={league.id} className="mb-[8px]">
                  <SportsOddsHeader league={league} sport_name={leagueEvents[0].sportEvent.sport_name} />
                  <Box className="events-list">
                    {leagueEvents.slice(0, 3).map((event) => (
                      <SportEvent
                        key={event.sportEvent.id}
                        event={event}
                        onClick={() => handleEventClick(event)}
                        sport_id={selectedSport}
                        sport_name={league.sport_name}
                      />
                    ))}
                  </Box>
                </div>
              ) : null;
            })}
          </Box>
        )}
      </Box>
    </WhiteBoard>
  )
}