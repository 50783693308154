import "@mock-api";
import i18next from "i18next";
import BrowserRouter from "@fuse/core/BrowserRouter";
import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import themeLayouts from "app/theme-layouts/themeLayouts";
import FuseAuthorization from "@fuse/core/FuseAuthorization";
import settingsConfig from "app/configs/settingsConfig";
import withAppProviders from "./withAppProviders";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen/FuseSplashScreen";
import { selectMainTheme } from "app/store/fuse/settingsSlice";
import { SnackbarProvider } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { CacheProvider } from "@emotion/react";
import { selectCurrentLanguageDirection } from "app/store/i18nSlice";
import { selectUser } from "app/store/userSlice";
import { AuthProvider } from "./auth/AuthContext";
import { selectNavigationAll } from "./store/fuse/navigationSlice";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { useActivityCheck } from "./hooks/useActivityCheck";
import { setInactive, getLogoAndSocialMedia } from "./main/store/uiSlice";
import "./utils/stringExtended";
import { ThemeSelector } from "./shared-components/ThemeSelector";
/**
 * Axios HTTP Request defaults
 */
// Test
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const emotionCacheOptions = {
  rtl: {
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
  ltr: {
    key: "muiltr",
    stylisPlugins: [],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
};

function App() {
  const navigation = useSelector(selectNavigationAll);
  const user = useSelector(selectUser);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const dispatch = useDispatch();

  const { inactive } = useActivityCheck();

  useEffect(() => {
    dispatch(setInactive(inactive));
    dispatch(getLogoAndSocialMedia());
  }, [inactive]);

  i18next
    .loadNamespaces("translation", (err) => {
      if (!translationsLoaded) {
        setTranslationsLoaded(true);
      }
    })
    .then((r) => {});

  const appHeight = () => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
  }

  useEffect(() => {
    appHeight();
    window.addEventListener('resize', appHeight);
    return () => window.removeEventListener('resize', appHeight);
  }, []);

  return translationsLoaded ? (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <FuseTheme theme={mainTheme} direction={langDirection}>
        <AuthProvider>
          <BrowserRouter>
            <FuseAuthorization
              userRole={user.role}
              loginRedirectUrl={settingsConfig.loginRedirectUrl}
              navigation={navigation}
              userPermissions={user.permissions}
            >
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                classes={{
                  containerRoot: "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
                }}
              >
                <FuseLayout layouts={themeLayouts} />
                <Toaster position="top-center" />
                {/* <ThemeSelector /> */}
              </SnackbarProvider>
            </FuseAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
  ) : (
    <FuseSplashScreen />
  );
}

export default withAppProviders(App)();
