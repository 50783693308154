const createHeadCell = (id, label, align, sort, disablePadding) => {
  return { id, label, align, sort, disablePadding };
};

export const headCells = [
  createHeadCell("gameName", "Game Name", "left", true, false),
  createHeadCell("vendorName", "Vendor Name", "left", true, true),
  createHeadCell("previousBalance", "Previous Balance", "left", true, false),
  createHeadCell("totalCredit", "Total Credit", "left", true, false),
  createHeadCell("totalDebit", "Total Debit", "left", true, false),
  createHeadCell("newBalance", "New Balance", "left", true, false),
  createHeadCell("date", "Date", "left", true, false),
  createHeadCell("detail", "Deatil", "left", true, false),
];

export const couponDatas = [
  {
    id: "493190c9-5b61-4912-afe5-78c21f1044d7",
    Username: "John Doe",
    Betslip_Id: "12",
    Date: "2022-05-09T10:32:42.703Z",
    Coupon_type: "Single",
    Game_type: "Live",
    Stake: 437,
    Currency: "Usd",
    Total_odds: 12.63,
    Total_won: 79,
    Cancel: "",
    Status: "Won",
    Details: {
      BestSlip: "2323669",
      BestslipType: "Combi",
      Live: "Yes",
      "Total Bets": 5,
      Date: "15/06/2023 00:15",
      TotalOdds: "18:25",
      PossibleComb: 1,
      TotalStake: 10,
      ClosingDate: "15/06/2023  00:15",
      BonusStake: "",
      BonusType: "",
      BonusWon: "",
      Status: "Cashed Out",
      PossibleWinnings: 182.36,
      Winnings: 16.87,
      CashedoutWin: 16.87,
      FinalScoreTable: [
        {
          Date: "16/09/2023 14:30",
          Event: "Football-EnglandWolverhampton-Liverpool",
          BetMarket: "Winner",
          Selection: "Home Team",
          Odds: "1.58",
          Live: "1:1",
          FinalScore: "--",
          Status: "Waiting",
        },
      ],
      WinningRecord: {
        SystemColumns: "1 - 3/4",
        BestslipType: "System",
        ClosingDate: "15/06/2023 00:15",
        TotalOdds: "5,47",
        Status: "Waiting",
        BonusStake: "10.10",
        Winning: "54.71",
        PossibleWinnings: "182.36",
        BetMarketTable: [
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Waiting",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Won",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Won",
          },
        ],
      },
    },
  },
  {
    id: "b91ccb58-b06c-413b-b389-87010e03a120",
    Username: "Smith",
    Betslip_Id: "13",
    Date: "2022-05-09T10:32:42.703Z",
    Coupon_type: "System",
    Game_type: "PreMatch",
    Stake: 121,
    Currency: "Usd",
    Total_odds: 16,
    Total_won: 432,
    Cancel: "",
    Status: "Lost",
    Details: {
      BestSlip: "2323670",
      BestslipType: "Combi",
      Live: "Yes",
      "Total Bets": 5,
      Date: "15/06/2023 00:15",
      TotalOdds: "18:25",
      PossibleComb: 1,
      TotalStake: 10,
      ClosingDate: "15/06/2023  00:15",
      BonusStake: "",
      BonusType: "",
      BonusWon: "",
      Status: "Cashed Out",
      PossibleWinnings: 182.36,
      Winnings: 16.87,
      CashedoutWin: 16.87,
      FinalScoreTable: [
        {
          Date: "16/09/2023 14:30",
          Event: "Football-EnglandWolverhampton-Liverpool",
          BetMarket: "Winner",
          Selection: "Home Team",
          Odds: "1.58",
          Live: "1:1",
          FinalScore: "--",
          Status: "Waiting",
        },
      ],
      WinningRecord: "",
    },
  },
  {
    id: "541416c9-84a7-408a-8d74-27a43c38d797",
    Username: "Harry",
    Betslip_Id: "14",
    Date: "2022-05-09T10:32:42.703Z",
    Coupon_type: "Combo",
    Game_type: "Live",
    Stake: 236,
    Currency: "Usd",
    Total_odds: 11.06,
    Total_won: 420,
    Cancel: "",
    Status: "Refunded",
    Details: {
      BestSlip: "2323671",
      BestslipType: "Combi",
      Live: "Yes",
      "Total Bets": 5,
      Date: "15/06/2023 00:15",
      TotalOdds: "18:25",
      PossibleComb: 1,
      TotalStake: 10,
      ClosingDate: "15/06/2023  00:15",
      BonusStake: "",
      BonusType: "",
      BonusWon: "",
      Status: "Cashed Out",
      PossibleWinnings: 182.36,
      Winnings: 16.87,
      CashedoutWin: 16.87,
      FinalScoreTable: [
        {
          Date: "16/09/2023 14:30",
          Event: "Football-EnglandWolverhampton-Liverpool",
          BetMarket: "Winner",
          Selection: "Home Team",
          Odds: "1.58",
          Live: "1:1",
          FinalScore: "--",
          Status: "Waiting",
        },
      ],
      WinningRecord: {
        SystemColumns: "1 - 3/4",
        BestslipType: "System",
        ClosingDate: "15/06/2023 00:15",
        TotalOdds: "5,47",
        Status: "Waiting",
        BonusStake: "10.10",
        Winning: "54.71",
        PossibleWinnings: "182.36",
        BetMarketTable: [
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Won",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Won",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Waiting",
          },
        ],
      },
    },
  },
  {
    id: "ef7b95a7-8e8b-4616-9619-130d9533add9",
    Username: "Peeter",
    Betslip_Id: "15",
    Date: "2022-05-09T10:32:42.703Z",
    Coupon_type: "Combo",
    Game_type: "PreMatch",
    Stake: 24,
    Currency: "Try",
    Total_odds: 10,
    Total_won: 270,
    Cancel: "",
    Status: "Cancel",
    Details: {
      BestSlip: "2323672",
      BestslipType: "Combi",
      Live: "Yes",
      "Total Bets": 5,
      Date: "15/06/2023 00:15",
      TotalOdds: "18:25",
      PossibleComb: 1,
      TotalStake: 10,
      ClosingDate: "15/06/2023  00:15",
      BonusStake: "",
      BonusType: "",
      BonusWon: "",
      Status: "Cashed Out",
      PossibleWinnings: 182.36,
      Winnings: 16.87,
      CashedoutWin: 16.87,
      FinalScoreTable: [
        {
          Date: "16/09/2023 14:30",
          Event: "Football-EnglandWolverhampton-Liverpool",
          BetMarket: "Winner",
          Selection: "Home Team",
          Odds: "1.58",
          Live: "1:1",
          FinalScore: "--",
          Status: "Waiting",
        },
      ],
      WinningRecord: {
        SystemColumns: "1 - 3/4",
        BestslipType: "System",
        ClosingDate: "15/06/2023 00:15",
        TotalOdds: "5,47",
        Status: "Waiting",
        BonusStake: "10.10",
        Winning: "54.71",
        PossibleWinnings: "182.36",
        BetMarketTable: [
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Waiting",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Won",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Waiting",
          },
        ],
      },
    },
  },
  {
    id: "eb8aa470-635e-461d-88e1-23d9ea2a5665",
    Username: "Bruce",
    Betslip_Id: "16",
    Date: "2022-05-09T10:32:42.703Z",
    Coupon_type: "Single",
    Game_type: "PreMatch",
    Stake: 78,
    Currency: "Try",
    Total_odds: 7.6,
    Total_won: 156,
    Cancel: "",
    Status: "Open",
    Details: {
      BestSlip: "2323673",
      BestslipType: "Combi",
      Live: "Yes",
      "Total Bets": 5,
      Date: "15/06/2023 00:15",
      TotalOdds: "18:25",
      PossibleComb: 1,
      TotalStake: 10,
      ClosingDate: "15/06/2023  00:15",
      BonusStake: "",
      BonusType: "",
      BonusWon: "",
      Status: "Cashed Out",
      PossibleWinnings: 182.36,
      Winnings: 16.87,
      CashedoutWin: 16.87,
      FinalScoreTable: [
        {
          Date: "16/09/2023 14:30",
          Event: "Football-EnglandWolverhampton-Liverpool",
          BetMarket: "Winner",
          Selection: "Home Team",
          Odds: "1.58",
          Live: "1:1",
          FinalScore: "--",
          Status: "Waiting",
        },
      ],
      WinningRecord: {
        SystemColumns: "1 - 3/4",
        BestslipType: "System",
        ClosingDate: "15/06/2023 00:15",
        TotalOdds: "5,47",
        Status: "Waiting",
        BonusStake: "10.10",
        Winning: "54.71",
        PossibleWinnings: "182.36",
        BetMarketTable: [
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Waiting",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Won",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Won",
          },
        ],
      },
    },
  },
  {
    id: "b85c2338-cc98-4140-bbf8-c226ce4e395e",
    Username: "Johny",
    Betslip_Id: "17",
    Date: "2022-05-09T10:32:42.703Z",
    Coupon_type: "System",
    Game_type: "Live",
    Stake: 54,
    Currency: "Usd",
    Total_odds: 17.55,
    Total_won: 45,
    Cancel: "",
    Status: "Won",
    Details: {
      BestSlip: "2323674",
      BestslipType: "Combi",
      Live: "Yes",
      "Total Bets": 5,
      Date: "15/06/2023 00:15",
      TotalOdds: "18:25",
      PossibleComb: 1,
      TotalStake: 10,
      ClosingDate: "15/06/2023  00:15",
      BonusStake: "",
      BonusType: "",
      BonusWon: "",
      Status: "Cashed Out",
      PossibleWinnings: 182.36,
      Winnings: 16.87,
      CashedoutWin: 16.87,
      FinalScoreTable: [
        {
          Date: "16/09/2023 14:30",
          Event: "Football-EnglandWolverhampton-Liverpool",
          BetMarket: "Winner",
          Selection: "Home Team",
          Odds: "1.58",
          Live: "1:1",
          FinalScore: "--",
          Status: "Waiting",
        },
      ],
      WinningRecord: {
        SystemColumns: "1 - 3/4",
        BestslipType: "System",
        ClosingDate: "15/06/2023 00:15",
        TotalOdds: "5,47",
        Status: "Waiting",
        BonusStake: "10.10",
        Winning: "54.71",
        PossibleWinnings: "182.36",
        BetMarketTable: [
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Waiting",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Waiting",
          },
          {
            Date: "16/09/2023 14:30",
            Event: "Football-EnglandWolverhampton -Liverpool",
            BetMarket: "Selection",
            Selection: "Home Team",
            Odds: "1.58",
            Live: "1:1",
            FinalScore: "--",
            Status: "Won",
          },
        ],
      },
    },
  },
];
