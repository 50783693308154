import { Box, Typography } from "@mui/material";

import { BalanceDropdown } from "./BalanceDropdown";
import { useDispatch, useSelector } from "react-redux";
import { setCashierModalTab, setCashierModalVisible } from "src/app/main/store/uiSlice";
import { selectUser } from "app/store/userSlice";
import { moneyText } from "src/app/utils";
import { useSystemType } from "src/app/hooks/useSystemType";
import { ONLINE_SYSTEM } from "src/app/constants";
import UserDropdown from "./UserDropdown";
import i18n from "src/i18n";
import {selectCurrentLanguageId} from "app/store/i18nSlice";

export const UserBox = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const systemType = useSystemType();
    const currentLanguageId = useSelector(selectCurrentLanguageId);

  const handleDepositClick = () => {
    dispatch(setCashierModalVisible(true));
    dispatch(setCashierModalTab("deposit"));
  };

  const handleWithdrawClick = () => {
    dispatch(setCashierModalVisible(true));
    dispatch(setCashierModalTab("withdrawal"));
  }

  return (
    <Box className="flex items-center" aria-label="userBox">
      <BalanceDropdown balance={user.data.accountBalance} sportsBonus={user.data.sportsBonus} casinoBonus={user.data.casinoBonus} currency={user.data.currency} />
      <Box className="ml-[12px] hidden md:flex items-baseline">
        <Typography className="text-white">{i18n.t("Welcome")},</Typography>
        <Typography className="ml-[0.5em] text-[#43ad47] whitespace-nowrap font-bold">
          {user.data.displayName || user.data.userName}
        </Typography>
      </Box>
      <button
        className="relative sm:hidden rounded-[12px] px-[8px] h-[36px] items-center bg-[#FA652B] hover:bg-[#e0541a] ml-[12px] text-white text-left whitespace-nowrap"
        onClick={handleDepositClick}
      >
        {moneyText(user.data.accountBalance, user.data.currency)}
      </button>
      <UserDropdown handleWithdraw={handleWithdrawClick} />
      {systemType === ONLINE_SYSTEM && (
        <button
          className="relative rounded-[12px] px-[12px] h-[40px] hidden md:flex items-center bg-[#FA652B] hover:bg-[#e0541a] ml-[12px] text-white text-left"
          onClick={handleDepositClick}
        >
            {i18n.t("Deposit")}
        </button>
      )}
    </Box>
  );
};
