import { Box } from "@mui/material";
import { BalanceCard } from "./component/BalanceCard";
import { CardBox } from "./component/CardBox";
import { Outlet } from "react-router-dom";
import { useScreenMd } from "src/app/hooks/useScreens";
import { selectUser } from "app/store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import FooterLayout1 from "app/theme-layouts/layout1/components/FooterLayout1";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { BETSHOP_SYSTEM } from "src/app/constants";
import { useSystemType } from "src/app/hooks/useSystemType";

const balanceItems = [
  {
    id: "balance",
    title: "Balance",
    balance: 0,
  },
  // {
  //   id: "withdrawable",
  //   title: "Withdrawable",
  //   balance: 0,
  // },
  {
    id: "casino",
    title: "Casino_bonus",
    balance: 0,
  },
  {
    id: "sports",
    title: "Sports_bonus",
    balance: 0,
  },
];

const userItems = [
  {
    id: "profile",
    title: "edit_profile",
    icon: "assets/images/pages/settings/profile.svg",
    path: "/settings/profile",
  },
  // {
  //   id: "password",
  //   title: "ChangePassword",
  //   icon: "assets/images/pages/settings/password.svg",
  //   path: "/settings/password",
  // },
  {
    id: "preferences",
    title: "Preferences",
    icon: "assets/images/pages/settings/profile.svg",
    path: "/settings/preferences",
  },
  {
    id: "account-verification",
    title: "Account_Verification",
    icon: "assets/images/pages/settings/verification.svg",
    path: "/settings/verification",
  },
  // {
  //   id: "notification",
  //   title: "Notifications",
  //   icon: "assets/images/pages/settings/notifications.svg",
  //   path: "/settings/notifications",
  // },
];

const historyItems = [
  // {
  //   id: "password",
  //   title: "ChangePassword",
  //   icon: "assets/images/pages/settings/password.svg",
  //   path: "/settings/password",
  // },
  {
    id: "bet-history",
    title: "Bet_History",
    icon: "assets/images/pages/settings/history.svg",
    path: "/settings/game-history",
  },
  {
    id: "casino-history",
    title: "Casino_History",
    icon: "assets/images/pages/settings/history.svg",
    path: "/settings/casino-history",
  },
  // {
  //   id: "notification",
  //   title: "Notifications",
  //   icon: "assets/images/pages/settings/notifications.svg",
  //   path: "/settings/notifications",
  // },
];

const paymentItems = [
  {
    id: "deposit",
    title: "Deposit",
    icon: "assets/images/pages/settings/deposit.svg",
    path: "/settings/deposit",
  },
  {
    id: "withdrawal",
    title: "Withdrawal",
    icon: "assets/images/pages/settings/withdrawal.svg",
    path: "/settings/withdrawal",
  },
  {
    id: "transaction-history",
    title: "Transaction_History",
    icon: "assets/images/pages/settings/history.svg",
    path: "/settings/transaction-history",
  },
];

const promotionItems = [
  {
    id: "bonus",
    title: "Bonus",
    icon: "assets/images/pages/settings/history.svg",
    path: "/settings/bonus",
  },
];

export const Settings = () => {
  const currency = "BDT";
  const isMd = useScreenMd();
  const user = useSelector(selectUser);
  const [balances, setBalances] = useState(balanceItems);
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const systemType = useSystemType();

  useEffect(() => {
    if (!user) return;

    let new_balances = [...balances];
    new_balances[0].balance = parseFloat(user.data.accountBalance);
    setBalances(new_balances);
  }, [user]);

  return (
    <div className="overflow-auto">
      <Box
        className={isMd ? "flex pt-[16px]" : "pt-[16px]"}
        aria-label="setting"
      >
        <Box className="px-[8px] hidden lg:flex flex-col gap-[8px]">
          <BalanceCard
            title={i18n.t("Balance")}
            itemList={balances}
            currency={user.data.currency}
          />
          <CardBox title={i18n.t("USER")} itemList={userItems} />
          <CardBox title={i18n.t("Finance")} itemList={paymentItems} />
          <CardBox title={i18n.t("My_Bets")} itemList={historyItems} />
          {systemType !== BETSHOP_SYSTEM && (
            <CardBox title={i18n.t("Promotions")} itemList={promotionItems} />
          )}
        </Box>
        <Box className="w-full px-[12px] pt-[10px] md:pt-0">
          <Outlet />
        </Box>
      </Box>
      <FooterLayout1 />
    </div>
  );
};
