import { Box } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getDemoGame, getGameWithPlayerCheck } from "../store/gameSlice";

export const CasinoGame = ({ demo }) => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [gameUrl, setGameUrl] = useState('');
  const dispatch = useDispatch();
  
  const showAlert = (type) => {
    toast.error(`${type} not supported`);
  }

  useEffect(() => {
    setGameUrl("");
    if (demo) {
      dispatch(getDemoGame({ lang: "en", gameId, currency: "USD" })).then((res) => {
        const data = res.payload;
        setGameUrl(data.response);
        if (data.response === "") showAlert("Demo");
      })
    } else {
      dispatch(getGameWithPlayerCheck({ lang: "en", gameId })).then(res => {
        const data = res.payload;
        setGameUrl(data.response);
        if (data.response === "") showAlert("Game");
      })
    }
  }, [gameId, demo]);

  return (
    <Box>
      <Box className="fixed left-0 top-0 h-[calc(var(--app-height)-65px)] z-[2000] md:left-auto md:top-auto md:flex md:h-[calc(var(--app-height)-134px)] w-full flex justify-center items-center">
        <Box className="w-full h-full md:w-auto md:h-auto md:p-[16px] md:rounded-[12px] rounded-tr-0 relative bg-white" style={{ boxShadow: "0 0 5px rgba(0,0,0,.3)" }}>
          <button
            onClick={() => navigate(-1)}
            className="hidden md:block p-[4px] absolute right-0 top-0 rounded-r-[12px] translate-x-full bg-white"
            style={{ boxShadow: "0 0 5px rgba(0,0,0,.3)" }}
          >
            <Box className="absolute left-0 top-0 w-[12px] -translate-x-full h-full bg-white z-[10]"></Box>
            <CloseIcon />
          </button>
          <Box className="w-full md:w-[1200px] h-full md:h-auto md:aspect-video">
            {gameUrl !== "" && <iframe width="100%" height="100%" src={gameUrl}></iframe>}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}