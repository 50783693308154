import { Box, Button, Popover, Typography, useTheme } from "@mui/material";
import { CashierModal } from "app/shared-components/CashierModal";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getCashierModalVisible, setCashierModalVisible, getCasinoTypes } from "src/app/main/store/uiSlice";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useSystemType } from "src/app/hooks/useSystemType";
import { ONLINE_SYSTEM } from "src/app/constants";

export const desktopNavItems = [
  {
    id: "home",
    path: "/dashboard",
    selectingExp: "^/dashboard",
    title: "Home",
    translate: "Home",
    icon: "home.svg",
    minWidth: 0,
  },
  {
    id: "sports-betting",
    path: "/sports/top",
    title: "Sports Betting",
    translate: "Sports_Betting",
    icon: "sports.svg",
    minWidth: 0,
  },
  {
    id: "live",
    path: "/sports/top?live=true",
    title: "Live",
    translate: "Live",
    icon: "live.svg",
    minWidth: 300,
  },
  {
    id: "casino",
    path: "/casino/all",
    selectingExp: "^/casino",
    title: "Casino",
    translate: "Casino",
    icon: "casino.svg",
    minWidth: 0,
  },
  {
    id: "live-casino",
    path: "/live-casino/all",
    selectingExp: "^/live-casino",
    title: "Live Casino",
    translate: "Live_Casino",
    icon: "live-casino.svg",
    minWidth: 0,
  },
  {
    id: "virtual-games",
    path: "/virtual-games/all",
    selectingExp: "^/virtual-games",
    title: "Virtual",
    translate: "Virtual",
    icon: "incoming.svg",
    minWidth: 1150,
  },
  {
    id: "racing",
    path: "https://www.nsoft.com/",
    selectingExp: "^/racing",
    title: "Racing",
    translate: "Racing",
    icon: "racing.svg",
    minWidth: 1290,
  },
  // {
  //   id: "poker-network",
  //   path: "/poker-network",
  //   selectingExp: "^/poker-network",
  //   title: "Poker Network",
  //   translate: "Poker_Network",
  //   icon: "auction.svg",
  //   minWidth: 1465,
  // },
  {
    id: "promotion",
    path: "/promotions",
    selectingExp: "^/promotions",
    title: "Promotions",
    translate: "Promotions",
    icon: "promotion.svg",
    minWidth: 1623,
  },
  {
    id: "affiliate",
    path: "/become-affiliate",
    selectingExp: "^/become-affiliate",
    translate: "Become_Affiliate",
    title: "Become Affiliate",
    icon: "promotion.svg",
    minWidth: "2000",
  },
];

export const mobileNavItems = [
  {
    id: "home",
    path: "/dashboard",
    title: "Home",
    translate: "Home",
    active: "^/dashboard",
    icon: "home.svg",
    minWidth: 0,
  },
  {
    id: "sports-betting",
    path: "/sports/all",
    title: "Sports Betting",
    translate: "Sports_Betting",
    active: "^/sports",
    icon: "sports.svg",
    minWidth: 0,
  },
  {
    id: "live-sports",
    path: "/sports/all?live=true",
    title: "Live",
    translate: "Live",
    active: "^/sports",
    icon: "live.svg",
    minWidth: 300,
  },
  {
    id: "casino",
    path: "/casino/all",
    title: "Slots",
    translate: "Casino",
    active: "^/casino",
    icon: "casino.svg",
    minWidth: 0,
  },
  {
    id: "live-casino",
    path: "/live-casino/all",
    title: "Live Casino",
    translate: "Live_Casino",
    active: "^/live-casino",
    icon: "live-casino.svg",
    minWidth: 0,
  },
  {
    id: "virtual-games",
    path: "/virtual-games/all",
    title: "Virtual",
    translate: "Virtual",
    active: "^/virtual-games",
    icon: "incoming.svg",
    minWidth: 1150,
  },
  {
    id: "racing",
    path: "https://www.nsoft.com/",
    title: "Racing",
    translate: "Racing",
    active: "^/racing",
    icon: "racing.svg",
    minWidth: 1290,
  },
  // {
  //   id: "poker-network",
  //   path: "/poker-network",
  //   title: "Poker Network",
  //   translate: "Poker_Network",
  //   active: "^/poker-network",
  //   icon: "auction.svg",
  //   minWidth: 1465,
  // },
  {
    id: "promotion",
    path: "/promotions",
    title: "Promotions",
    active: "^/promotions",
    translate: "Promotions",
    icon: "promotion.svg",
    minWidth: 1623,
  },
  {
    id: "affiliate",
    path: "/become-affiliate",
    active: "^/become-affiliate",
    translate: "Become_Affiliate",
    title: "Become Affiliate",
    icon: "promotion.svg",
    minWidth: "2000",
  },
];

export const NavigationBar = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [windowWidth, setWindowWidth] = useState(9999);
  const [navItems, setNavItems] = useState(desktopNavItems);
  const cashierModalVisible = useSelector(getCashierModalVisible);
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const dispatch = useDispatch();
  const systemType = useSystemType();
  const theme = useTheme();

  const onResize = () => {
    const windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, []);

  // useEffect(() => {
  //   dispatch(getCasinoTypes()).then((res) => {
  //     const casinoTypes = res.payload;
  //     const newItems = casinoTypes.map((type) => ({
  //       id: `casino-${type.id}`,
  //       path: `/casino/${type.id}`,
  //       title: type.name,
  //       translate: type.name,
  //       icon: "casino.svg",
  //       minWidth: 2000,
  //     }));
  //     setNavItems([...desktopNavItems, ...newItems]);
  //   });
  // }, [dispatch, currentLanguageId]);

  const filteredItems = navItems.filter(item => systemType === ONLINE_SYSTEM || (item.id !== "promotion" && item.id !== "affiliate"))

  const overItemsCount = filteredItems
    .filter((item) => item.minWidth > windowWidth).length;

  return (
    <>
      <Box aria-label="navigationBarOutter" className="hidden md:block w-full pr-[20px]">
        <Box
          aria-label="navigationBar"
          className="w-full pl-[89px] pr-[54px] flex justify-between items-center rounded-br-[12px]"
          bgcolor={theme.palette.background.navigationBar || "#2C374F"}
        >
          <Box className="flex gap-[24px]">
            {filteredItems
              .filter((item) => item.minWidth < windowWidth)
              .map((item) => {
                let selected = false;
                const pathname = location.pathname;
                const search = location.search;
                if (
                  (pathname.startsWith("/sports") && item.path.includes("/sports")) ||
                  (pathname.startsWith("/casino") && item.path.includes("/casino"))
                ) {
                  selected =
                    (item.path.includes("live") && search.includes("live")) ||
                    (!item.path.includes("live") && !search.includes("live"));
                } else {
                  selected = item.selectingExp && RegExp(item.selectingExp).test(pathname);
                }
                return (
                  <Link
                    key={item.id}
                    className={`flex gap-[8px] items-center h-[65px] px-[12px] hover:bg-[#1C273F] ease-out`}
                    to={item.path}
                    style={selected ? { backgroundColor: theme.palette.background.navigationSelected || "#1c273f" } : undefined}
                  >
                    <img src={`assets/images/navItems/${item.icon}`} alt="nav-item" />
                    <Typography
                      className="text-[14px] text-[#C1C1C1] font-500 uppercase"
                      sx={selected ? { color: "white !important" } : undefined}
                    >
                      {i18n.t(item.translate)}
                    </Typography>
                  </Link>
                );
              })}
          </Box>
          <Button
            className="flex gap-[8px] items-center h-[65px] px-[12px] hover:bg-[#1C273F] transition-all duration-150 ease-out disabled:opacity-30"
            variant="text"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            disabled={overItemsCount === 0}
          >
            <img src="assets/images/navItems/more.svg" />
            <Typography className="text-[14px] text-[#C1C1C1] font-500">More</Typography>
          </Button>

          {overItemsCount > 0 && (
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              className="mt-[8px] rounded-0"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Box className="flex flex-col gap-[4px] bg-[#2C374F]">
                {filteredItems
                  .filter((item) => item.minWidth > windowWidth)
                  .map((item) => {
                    const selected = RegExp(item.selectingExp).test(location.pathname);
                    return (
                      <Link
                        key={item.id}
                        className="px-[16px] py-[8px] flex gap-[4px] hover:bg-[#1C273F] ease-out"
                        to={item.path}
                        onClick={() => setAnchorEl(null)}
                      >
                        <img src={`assets/images/navItems/${item.icon}`} alt="nav-item" />
                        <Typography
                          className="text-[14px] text-[#C1C1C1] font-500"
                          sx={selected ? { color: "white !important" } : undefined}
                        >
                          {i18n.t(item.translate)}
                        </Typography>
                      </Link>
                    );
                  })}
              </Box>
            </Popover>
          )}
        </Box>
      </Box>

      <CashierModal visible={cashierModalVisible} onClose={() => dispatch(setCashierModalVisible(false))} />
    </>
  );
};
