import React from "react";
import { Box, Typography } from "@mui/material";
import { SearchBox } from "../../../shared-components/SearchBox";
import { Select } from "../../../shared-components/Select";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import i18next from "i18next";

export const CasinoSearchBox = ({ item, rootPath, onRemove }) => {
  return (
    <Box
      className="rounded-[12px] bg-[#021C26] p-[16px] mt-[32px]"
      sx={{ boxShadow: "0px 4px 5.1px 0px #00000040" }}
      aria-label="casinoSearchBox"
    >
      <Box className="flex flex-col md:flex-row items-center gap-[16px]">
        <Box className="flex w-full md:w-auto md:flex-grow gap-[16px]">
          {item && (
            <button
              className="flex items-center bg-[#172642] rounded-[8px] py-[4px] px-[16px] hover:bg-[#151632]"
              onClick={onRemove}
            >
              <Typography className="text-white text-[16px] capitalize whitespace-nowrap">
                {(item.name || item.vendor_name).replace(/-/g, " ")}
              </Typography>
              {item.id !== "all" && <CloseIcon className="text-white" />}
            </button>
          )}
          <Box className="w-full md:w-[70%]">
            <SearchBox inputProps={{ placeholder: i18next.t("Search_games_providers") }} />
          </Box>
        </Box>
        <Box className="w-full md:w-[30%]">
          <Select typeId={item.id} rootPath={rootPath} title={(item.vendor_name || "").replace(/-/g, " ")} />
        </Box>
      </Box>
      <Typography className="text-[#E3E3E3] text-[16px] mt-[10px]">{i18next.t("Search_requires_at_least_3_characters")}</Typography>
    </Box>
  );
};
