import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import format from "date-fns/format";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import { SortTableHead } from "app/shared-components/SortTableHead/SortTableHead";
import {
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import { Chip } from "./Chip";
import { useState } from "react";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useDispatch } from "react-redux";
import { getRoundHistory } from "../../store/historySlice";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const tableHeaders = [
  { id: "id", title: "Betslip ID" },
  {
    id: "created_at",
    title: "Date",
    wrapper: (value) => format(new Date(value), "yyyy MMM dd HH:mm"),
  },
  { id: "coupon_type", title: "Slip Type" },
  { id: "game_type", title: "Game Type" },
  { id: "original_stake", title: "" },
  { id: "currency", title: "Currency" },
  {
    id: "total_odds",
    title: "Total Odds",
    wrapper: (value) => value.toFixed(2),
  },
  { id: "total_won", title: "Total Won", wrapper: (value) => value.toFixed(2) },
  {
    id: "cancel",
    title: "Cancel",
    wrapper: () => (
      <Tooltip title="Cancel the betting" placement="left">
        <button className="hover:text-[red]">
          <DoNotDisturbOnOutlinedIcon />
        </button>
      </Tooltip>
    ),
  },
  {
    id: "slip_status",
    title: "Status",
    wrapper: (value) => <Chip status={value} />,
  },
  {
    id: "details",
    title: "Details",
    wrapper: () => (
      <IconButton>
        <RemoveRedEyeIcon className="text-[#4f46e5]" />
      </IconButton>
    ),
  },
];

export default function HistoryTable(props) {
  const { rows, headCells } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [open, setOpen] = React.useState(false);
  const [couponUserDetails, setCouponUserDetails] = useState();
  const [winningRecord, setWinningRecord] = useState(null);
  const [winningRecordsVisible, setWinningRecordsVisible] = useState(false);
  const [roundHistoryURL, setRoundHistoryURL] = useState("");
  const dispatch = useDispatch();

  const md = useScreenMd();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEye = (event, row) => {
    setOpen(true);
    dispatch(
      getRoundHistory({ roundId: row.round_id, gameId: row.game_id })
    ).then((res) => {
      const data = res.payload;
      setRoundHistoryURL(data.response);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => {
    if (!rows) return [];
    return stableSort(rows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [order, orderBy, page, rowsPerPage, rows]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper className="w-full mb-[24px] overflow-hidden">
        {md && (
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <SortTableHead
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className="cursor-pointer"
                    >
                      <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                        {row.game_name}
                      </TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px", px: 0 }}>
                        {row.vendor_name}
                      </TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                        {row.previous_balance.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                        {row.total_credit.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                        {row.total_debit.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ py: "4px", fontSize: "12px" }}>
                        {row.new_balance.toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{ py: "4px", fontSize: "12px", textAlign: "right" }}
                      >
                        {row?.created_at}
                      </TableCell>
                      <TableCell
                        onClick={(event) => handleEye(event, row)}
                        sx={{ py: "4px", fontSize: "12px" }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!md && (
          <Box className="py-[12px]">
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <Box
                  key={index}
                  className="px-[12px] border-b last:border-b-0 py-[8px]"
                >
                  {tableHeaders.map(({ id, title, wrapper }) => (
                    <Box key={id} className="flex items-center w-full py-[4px]">
                      <Box className="w-1/3">{title}</Box>
                      <Box className="w-2/3">
                        {wrapper ? wrapper(row[id]) : row[id]}
                      </Box>
                    </Box>
                  ))}
                </Box>
              );
            })}
          </Box>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ ".MuiPaper-root": { overflow: "visible", height: "100%" } }}
      >
        <Box className="relative" sx={{ width: "900px", height: "100%" }}>
          <IconButton
            className="absolute -left-[40px] top-0 text-white"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Box
            aria-label="slipHeader"
            className="bg-[#2C374F] text-white py-[24px]"
            sx={{ height: "100%" }}
          >
            {roundHistoryURL !== "" ? (
              <iframe width="100%" height="100%" src={roundHistoryURL}></iframe>
            ) : (
              "Loading..."
            )}
            <Divider />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
