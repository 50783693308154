import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { ContentCard } from "../component/ContentCard";
import Select from "react-select";
import { useState, useEffect } from "react";
import { getUserPreference, updateUserPreference } from "../store/profileSlice";
import { setOddsSetting } from "src/app/main/store/ticketSlice";
import {
  ODD_SETTING_ACCEPT_ANY_ODDS,
  ODD_SETTING_ACCEPT_HIGHER_ODDS,
  ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE,
  PopularPrinters
} from "src/app/constants";
import { selectUser } from 'app/store/userSlice';
import { useDispatch, useSelector } from "react-redux";

const Hr = () => <Box className="w-full h-[1.5px] border-t-[1.5px] border-t-[#D9E1E5]"></Box>;

const oddsSettingMapping = {
  "Accept any odds": ODD_SETTING_ACCEPT_ANY_ODDS,
  "Accept higher odds": ODD_SETTING_ACCEPT_HIGHER_ODDS,
  "Don't accept odds change": ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE,
};

export const Preferences = () => {
  const dispatch = useDispatch();
  const [defaultSetting, SetDefaultSetting] = useState("Accept any odds");
  const [marketingPref, SetMarketingPref] = useState(0);
  const [bonus, SetBonus] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState(PopularPrinters[0]);
  const [autoPrint, setAutoPrint] = useState(false);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(getUserPreference()).then((res) => {
      switch (res.payload.slip_setting) {
        case ODD_SETTING_ACCEPT_ANY_ODDS:
          SetDefaultSetting("Accept any odds");
          break;
        case ODD_SETTING_ACCEPT_HIGHER_ODDS:
          SetDefaultSetting("Accept higher odds");
          break;
        case ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE:
          SetDefaultSetting("Don't accept odds change");
          break;
        default:
          SetDefaultSetting("Accept any odds");
      }
      dispatch(setOddsSetting(res.payload.slip_setting));
      SetMarketingPref(res.payload.marketing_preferences);
      SetBonus(res.payload.bonus_issuance);
      setAutoPrint(res.payload.auto_print); 
      setSelectedPrinter(PopularPrinters.find(p => p.value === res.payload.printer_type) || PopularPrinters[0]);
    });
  }, [dispatch]);

  const handleOddsSettingChange = (event) => {
    const newSetting = event.target.value;
    SetDefaultSetting(newSetting);
  };

  const handleMarketingPrefChange = (event) => {
    const { name, checked } = event.target;
    if (name === "email") {
      SetMarketingPref((prev) => (checked ? (prev === 1 ? 2 : 0) : prev === 2 ? 1 : 0));
    } else if (name === "phone") {
      SetMarketingPref((prev) => (checked ? (prev === 0 ? 2 : 1) : prev === 2 ? 0 : 1));
    }
  };

  const handlePrinterChange = (selectedOption) => {
    setSelectedPrinter(selectedOption);
  };

  const handleAutoPrintChange = (event) => {
    setAutoPrint(event.target.checked);
  };

  const handleBonusChange = (event) => {
    SetBonus(event.target.checked);
  };

  const handleSave = () => {
    const updatedPreferences = {
      slip_setting: oddsSettingMapping[defaultSetting],
      marketing_preferences: marketingPref,
      bonus_issuance: bonus,
      auto_print: autoPrint,
      printer_type: selectedPrinter.value,
    };
    dispatch(updateUserPreference(updatedPreferences));
  };

  return (
    <Box aria-label="Preferences">
      <ContentCard title="Preferences" icon="assets/images/pages/settings/history.svg">
        <Box className="bg-white rounded-b-[12px] py-[24px] px-[12px] md:px-0">
          <Box className="flex flex-col gap-[12px] max-w-[1000px] mx-auto min-h-[600px]">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Odds Setting</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={defaultSetting}
                onChange={handleOddsSettingChange}
              >
                <FormControlLabel
                  value="Accept any odds"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                          color: "yellowgreen",
                        },
                        "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                          color: "yellowgreen",
                        },
                      }}
                    />
                  }
                  label="Accept any odds"
                />
                <FormControlLabel
                  value="Don't accept odds change"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                          color: "yellowgreen",
                        },
                        "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                          color: "yellowgreen",
                        },
                      }}
                    />
                  }
                  label="Don't accept odds change"
                />
                <FormControlLabel
                  value="Accept higher odds"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                          color: "yellowgreen",
                        },
                        "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                          color: "yellowgreen",
                        },
                      }}
                    />
                  }
                  label="Accept higher odds"
                />
              </RadioGroup>
            </FormControl>
            <Hr />
            <Typography className="uppercase text-[12px]">MARKETING PREFERENCES</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketingPref === 0 || marketingPref === 2}
                    onChange={handleMarketingPrefChange}
                    name="email"
                    sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }}
                  />
                }
                label="Email"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketingPref === 1 || marketingPref === 2}
                    onChange={handleMarketingPrefChange}
                    name="phone"
                    sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }}
                  />
                }
                label="Phone"
              />
            </FormGroup>
            <Hr />
            <FormControlLabel
              control={
                <Switch
                  checked={bonus}
                  onChange={handleBonusChange}
                  sx={{
                    ".Mui-checked+.MuiSwitch-track": {
                      backgroundColor: "green !important",
                    },
                  }}
                />
              }
              label="BONUS ISSUANCE"
            />
            <Typography className="text-[12px]">
              When bonuses issuance is turned off, you will not receive any bonuses, including tournament bonuses and
              VIP program participation
            </Typography>
            <Hr />
            {user.role && user.role.includes("betshop_user") ? <><Typography className="uppercase text-[12px]">PRINT SETTINGS</Typography>
              <Select
                value={selectedPrinter}
                onChange={handlePrinterChange}
                options={PopularPrinters}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={autoPrint}
                    onChange={handleAutoPrintChange}
                    sx={{
                      ".Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "green !important",
                      },
                    }}
                  />
                }
                label="Auto Print"
              />
              <Typography className="text-[12px]">
                Set for Auto-Print
              </Typography>
              </> : null }
            
            <button
              className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] mt-[24px]"
              onClick={handleSave}
            >
              Save
            </button>
          </Box>
        </Box>
      </ContentCard>
    </Box>
  );
};
