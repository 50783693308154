import { Box } from "@mui/material";
import { convertOdds, flagPath } from "src/app/utils";
import { useDispatch, useSelector } from "react-redux";
import { addTicket, removeTicket } from "../../store/ticketSlice";
import { selectUserOddsFormat } from "../../store/uiSlice";
import { useNavigate } from "react-router-dom";

export const SliderCard = ({ event }) => {
  const tickets = useSelector((state) => state.sportsApp.tickets.selectedOdds);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const oddsFormat = useSelector(selectUserOddsFormat);

  const getButtonStyle = (selected) => ({
    backgroundColor: selected ? "rgb(255 255 255 / 60%)" : "rgb(255 255 255 / 20%)",
    height: "35px",
    width: "87px",
    borderRadius: "5px",
  });

  if (!event?.sportEvent?.id) {
    return ;
  }

  const {
    tournament_name: league,
    status,
    start_at,
    home_logo,
    away_logo,
    name,
  } = event.sportEvent;

  const teams = {
    home: {
      name: name.split(" vs ")[0],
      img: home_logo,
      odds: event.outcome.find((outcome) => outcome.reference_id === "1X2;1")?.odds,
      outcome: event.outcome.find((outcome) => outcome.reference_id === "1X2;1"),
    },
    draw: {
      odds: event.outcome.find((outcome) => outcome.reference_id === "1X2;X")?.odds,
      outcome: event.outcome.find((outcome) => outcome.reference_id === "1X2;X"),
    },
    away: {
      name: name.split(" vs ")[1],
      img: away_logo,
      odds: event.outcome.find((outcome) => outcome.reference_id === "1X2;2")?.odds,
      outcome: event.outcome.find((outcome) => outcome.reference_id === "1X2;2"),
    },
  };

  const handleOddClick = (outcome) => {
    const existingTickets = tickets.filter(
      (ticket) => ticket.id === event.sportEvent.id && ticket.outcome_reference_id === outcome.reference_id
    );
    if (existingTickets.length != 0) {
      existingTickets.forEach((existingTicket) => {
        const data = {
          id: existingTicket.id,
          outcome_reference_id: existingTicket.outcome_reference_id,
        };
        dispatch(removeTicket(data));
      });
    }

    // showCirleAnimation(e);

    if (existingTickets.length == 0) {
      const data = {
        id: event.sportEvent.id,
        sport_name: event.sportEvent.sport_name,
        event_reference_id: event.sportEvent.reference_id,
        outcome_reference_id: outcome.reference_id,
        outcome_id: outcome.id,
        market_id: outcome.market_id,
        active: outcome.active,
        date: event.sportEvent.start_at,
        homeTeam: teams.home.name,
        awayTeam: teams.away.name,
        odds: outcome.odds,
        detail: outcome.reference_id,
        state_amout: 0,
        game_type: event.sportEvent.status,
        tmr_update: event.sportEvent.tmr_update,
        period: event.sportEvent.period,
        outcome_id_dic: outcome.outcome_id,
      };
      dispatch(addTicket(data));
    }
  };

  const handleCardClick = () => {
    navigate(`/sports/detail/${event.sportEvent.reference_id}${event.sportEvent.status === "Live" ? "?live=true" : ""}`);
  };

  const date = new Date(start_at).toLocaleDateString();
  const time = new Date(start_at).toLocaleTimeString('en', { timeStyle: "short" });

  const buttonSelected = (reference_id) => {
    const existingTickets = tickets.filter(
      (ticket) => ticket.id === event.sportEvent.id && ticket.outcome_reference_id === reference_id
    );
    return existingTickets.length > 0;
  }

  return (
    <Box
      className="p-[4px] flex flex-col text-[12px] !text-white bg-no-repeat h-[172px] w-[306px] select-non"
      onClick={handleCardClick}
      aria-label="SliderCard"
      style={{
        backgroundImage: `url("${flagPath(event.sportEvent.sport_background)}")`,
        backgroundSize: "cover",
        backgroundPosition: 'center',
        borderRadius: "6px",
      }}
    >
      <Box
        className="featured-event-title gap-10 flex w-[290px] min-w-[290px] min-h-[41px]"
        sx={{ position: "relative", alignItems: "center" }}
      >
        <span className="sport-icon sport-football"></span>
        <span className="featured-event-league">{league}</span>
        <Box className="align-right flex" sx={{ marginLeft: "auto" }}>
          <Box className="fx-button">
            {/* <span>{status === "unplayed" ? "prematch" : "live"}</span> */}
          </Box>
        </Box>
      </Box>

      <Box
        aria-label="teams"
        className="flex justify-between my-4 w-[290px] min-w-[290px] min-h-[76px]"
        sx={{ alignSelf: "stretch", justifyContent: "space-around" }}
      >
        <Box className="vertical flex flex-col items-center justify-start max-w-[100px] min-w-[100px] px-[10px]">
          <div className="crest flex cr-medium">
            <img src={teams.home.img} alt="" className="w-auto h-[36px] object-cover" />
          </div>
          <span>{teams.home.name}</span>
        </Box>

        <Box className="vertical gap-5 flex flex-col items-center justify-center w-[90px] px-[10px]">
          <span className="event-date whitespace-nowrap">{date}</span>
          <span>{time}</span>
        </Box>

        <Box className="vertical flex flex-col items-center justify-start max-w-[100px] min-w-[100px] px-[10px]">
          <div className="crest flex cr-medium">
            <img src={teams.away.img} alt="" className="w-auto h-[36px] object-cover" />
          </div>
          <span>{teams.away.name}</span>
        </Box>
      </Box>

      <Box className="featured-event-markets flex justify-around w-full min-h-[40px]">
        <button
          className="flux-market-price px-2 py-1"
          style={getButtonStyle(buttonSelected("1X2;1"))}
          onClick={(e) => {
            e.stopPropagation();
            handleOddClick(teams.home.outcome);
          }}
        >
          <span className="selection-price">{convertOdds(teams.home.odds, oddsFormat)}</span>
        </button>

        <button
          className="flux-market-price px-2 py-1"
          style={getButtonStyle(buttonSelected("1X2;X"))}
          onClick={(e) => {
            e.stopPropagation();
            handleOddClick(teams.draw.outcome);
          }}
        >
          <span className="selection-price">{convertOdds(teams.draw.odds, oddsFormat)}</span>
        </button>

        <button
          className="flux-market-price px-2 py-1"
          style={getButtonStyle(buttonSelected("1X2;2"))}
          onClick={(e) => {
            e.stopPropagation();
            handleOddClick(teams.away.outcome);
          }}
        >
          <span className="selection-price">{convertOdds(teams.away.odds, oddsFormat)}</span>
        </button>
      </Box>
    </Box>
  );
};
